define('ln-ember-data-models/models/vdc/fragments/image-dimension', ['exports', 'ember-data', 'ember-data-model-fragments/fragment'], function (exports, _emberData, _fragment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr;
  exports.default = _fragment.default.extend({

    /**
     * @memberof Vdc/fragments/ImageDimensionFragment
     * @instance
     *
     * @type {Number}
     */
    width: attr('number'),

    /**
     * @memberof Vdc/fragments/ImageDimensionFragment
     * @instance
     *
     * @type {Number}
     */
    height: attr('number'),

    /**
     * @memberof Vdc/fragments/ImageDimensionFragment
     * @instance
     *
     * @type {Number}
     */
    ratio: attr('number')
  });
});