define('ln-ember-data-models/models/vdc/article-category', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr;
  exports.default = Model.extend({

    /**
     * @memberof Vdc/ArticleCategoryModel
     * @instance
     *
     * @type {Number}
     */
    version: attr('number'),

    /**
     * @memberof Vdc/ArticleCategoryModel
     * @instance
     *
     * @type {String}
     */
    name: attr('nullable-string'),

    /**
     * @memberof Vdc/ArticleCategoryModel
     * @instance
     *
     * @type {String}
     */
    description: attr('nullable-string'),

    /**
     * @memberof Vdc/ArticleCategoryModel
     * @instance
     *
     * @type {String}
     */
    full_path: attr('nullable-string'),

    /**
     * @memberof Vdc/ArticleCategoryModel
     * @instance
     *
     * @type {Boolean}
     */
    text_modules_only_for_ordered_articles: attr('boolean', { defaultValue: function defaultValue() {
        return false;
      } }),

    /**
     * @memberof Vdc/ArticleCategoryModel
     * @instance
     *
     * @type {String}
     */
    position: attr('number', {
      defaultValue: 0
    }),

    /**
     * @memberof Vdc/ArticleCategoryModel
     * @instance
     *
     * @type {Number}
     */
    client_id: attr('id'),

    /**
     * @memberof Vdc/ArticleCategoryModel
     * @instance
     *
     * @type {Number}
     */
    parent_id: attr('id')
  });
});