define("ln-ember-form-elements/components/form-elements/date-input-base", ["exports", "moment", "ln-ember-form-elements/components/form-elements/base"], function (_exports, _moment, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Abstract base class for all date inputs.
   *
   * @class formElements/DateInputBaseComponent
   * @extends formElements/BaseComponent
   * @abstract
   */
  var _default = _base.default.extend({
    /**
     * @memberof formElements/DateInputBaseComponent
     * @instance
     *
     * @type {any}
     * @default null
     */
    value: null,

    /**
     * ```Handlebars
     * {{form-elements/date-input-component}}
     * ```
     *
     * @memberof formElements/DateInputBaseComponent
     * @instance
     *
     * @type {string}
     * @default null
     */
    showCalendarWeek: false,

    /**
     * @memberof formElements/DateInputComponent
     * @instance
     *
     * @type {string} - Format YYYY-MM-DD
     */
    visibleDate: Ember.computed({
      get: function get() {
        return this.getVisibleDate();
      },
      set: function set(key, value) {
        return value;
      }
    }),
    focusedElements: 0,
    classNames: ['form-elements--date-input-component'],
    classNameBindings: ['themeClass'],
    displayValue: Ember.computed('value', 'showDropDown', {
      get: function get() {
        return this.formatDate(this.get('value'));
      },
      set: function set(key, value) {
        return value;
      }
    }),
    showDropDown: Ember.computed('disabled', 'readonly', 'focusedElements', {
      get: function get() {
        return !(this.get('disabled') || this.get('readonly')) && this.get('focusedElements') > 0;
      },
      set: function set(key, value) {
        return value;
      }
    }),
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      var value = this.get('value');
      (false && !(Ember.isNone(value) || this.isValidInput(value)) && Ember.assert("Value should be valid (".concat(JSON.stringify(value), ")"), Ember.isNone(value) || this.isValidInput(value)));
    },
    getVisibleDate: function getVisibleDate()
    /* value */
    {
      throw Error('getVisibleDate() needs to be implemented');
    },
    triggerChange: function triggerChange()
    /* value */
    {
      throw Error('triggerChange() needs to be implemented');
    },
    formatDate: function formatDate()
    /* date */
    {
      throw Error('formatDate() needs to be implemented');
    },
    normalizeDate: function normalizeDate(date) {
      return (0, _moment.default)(date, this.get('formElements.defaults.formatDate')).format('YYYY-MM-DD');
    },
    applyTime: function applyTime(value, time) {
      if (value && (0, _moment.default)(value).isValid() && /^\d?\d+:\d+\d+$/.test(time)) {
        return (0, _moment.default)((0, _moment.default)(value).format("YYYY-MM-DD [".concat(time, "] Z")), 'YYYY-MM-DD HH:mm Z').toISOString();
      }

      return value;
    },
    isValidInput: function isValidInput(value) {
      return this.isValid(value);
    },
    isValid: function isValid() {
      throw Error('isValid() needs to be implemented');
    },
    actions: {
      onCalendarChange: function onCalendarChange() {
        throw Error('actions.onCalendarChange() needs to be implemented');
      },
      onInputChange: function onInputChange() {
        throw Error('actions.onInputChange() needs to be implemented');
      },
      onIncrementFocusedElements: function onIncrementFocusedElements() {
        this.incrementProperty('focusedElements');
      },
      onCalendarClick: function onCalendarClick() {
        if (!this.showDropDown) {
          this.element.querySelector('input').focus();
        }
      },
      onDecrementFocusedElements: function onDecrementFocusedElements() {
        var _this = this;

        Ember.run.later(function () {
          if (_this.get('isDestroyed')) {
            return;
          }

          _this.decrementProperty('focusedElements');
        }, 100);
      }
    }
  });

  _exports.default = _default;
});