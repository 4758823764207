define('ln-ember-data-models/serializers/application', ['exports', 'ember-data', 'ember-inflector'], function (exports, _emberData, _emberInflector) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  function _defineProperty(obj, key, value) {
    if (key in obj) {
      Object.defineProperty(obj, key, {
        value: value,
        enumerable: true,
        configurable: true,
        writable: true
      });
    } else {
      obj[key] = value;
    }

    return obj;
  }

  var RESTSerializer = _emberData.default.RESTSerializer,
      EmbeddedRecordsMixin = _emberData.default.EmbeddedRecordsMixin;
  exports.default = RESTSerializer.extend(EmbeddedRecordsMixin, {

    config: Ember.computed(function () {
      var config = Ember.getOwner(this).resolveRegistration('config:environment');
      return config.APP.config;
    }),

    isNewSerializerAPI: true,

    normalizeArrayResponse: function normalizeArrayResponse(store, typeClass, payload) {
      var meta = Ember.assign(payload.meta, {
        summary: payload.summary || {}
      });

      if (typeof meta.count !== 'undefined') {
        meta.result_count = meta.count;
      } else if (typeof meta.result_count !== 'undefined') {
        meta.count = meta.result_count;
      }

      var normalized = _defineProperty({
        meta: meta
      }, this.typeKey(typeClass), payload.result);

      return this._super(store, typeClass, normalized);
    },
    normalizeSingleResponse: function normalizeSingleResponse(store, typeClass, payload, id, requestType) {
      payload = _defineProperty({
        meta: payload.meta || {}
      }, this.typeKey(typeClass), payload);

      return this._super(store, typeClass, payload, id, requestType);
    },
    normalizeDeleteRecordResponse: function normalizeDeleteRecordResponse() /* store, primaryModelClass, payload, id, requestType */{
      return { meta: [] };
    },
    typeKey: function typeKey(typeClass) {
      return (0, _emberInflector.pluralize)(typeClass.modelName);
    },
    serializeIntoHash: function serializeIntoHash(hash, typeClass, snapshot, options) {
      var serialized = this.serialize(snapshot, options);

      Object.keys(serialized).forEach(function (key) {
        if (Ember.get(typeClass, 'relationshipNames.hasMany').includes(key)) {
          hash[key] = null;
          return;
        }
        if (Ember.get(typeClass, 'relationshipNames.belongsTo').includes(key)) {
          hash[key] = null;
          return;
        }
        hash[key] = serialized[key];
      });
    },
    serialize: function serialize(snapshot, options) {
      var json = this._super(snapshot, options);
      json[Ember.get(this, 'primaryKey')] = Number(snapshot.id);

      return json;
    },
    extractErrors: function extractErrors(store, typeClass, payload) {
      return (payload.errors || []).filterBy('reference').reduce(function (out, _ref) {
        var reference = _ref.reference,
            message = _ref.message;

        out[reference] = message;
        return out;
      }, {});
    },
    extractRelationships: function extractRelationships(modelClass, resourceHash) {

      var relationships = {};

      modelClass.eachRelationship(function (key, relationshipMeta) {

        var relationship = null;
        if (relationshipMeta.kind === 'hasMany' && !resourceHash[key]) {
          var related = this.buildRelationshipLink(key, relationshipMeta, modelClass, resourceHash);
          if (related) {
            relationship = { links: { related: related } };
          }
        } else if (relationshipMeta.kind === 'hasMany') {
          relationship = { data: resourceHash[key] };
        } else if (relationshipMeta.kind === 'belongsTo') {
          relationship = { data: resourceHash[key] };
        }

        if (relationship) {
          relationships[key] = relationship;
        }
      }, this);

      return relationships;
    },
    buildRelationshipLink: function buildRelationshipLink(key, relationshipMeta, modelClass, resourceHash) {
      var modelName = modelClass.modelName;

      var baseUrl = this.baseUrlFor(modelName);
      var routelink = Ember.get(resourceHash, 'links.' + key);
      if (routelink) {
        return baseUrl + routelink;
      }
      var linkKey = this.keyForLink(key, relationshipMeta.kind);
      var contextType = Ember.get(relationshipMeta, 'options.contextType');

      if (contextType) {
        return baseUrl + '/' + linkKey + '?context_type=' + contextType + '&context=' + resourceHash.id;
      }

      if (key === 'files' || key === 'participants' || key === 'likes' || key === 'contracts') {
        var resource = this.pathForType(modelName);

        return baseUrl + '/' + resource + '/' + resourceHash.id + '/' + linkKey;
      }

      var _modelClass$modelName = modelClass.modelName.split('/'),
          _modelClass$modelName2 = _slicedToArray(_modelClass$modelName, 2),
          actualModelName = _modelClass$modelName2[1];

      return baseUrl + '/' + linkKey + '?' + actualModelName + '=' + resourceHash.id;
    },
    pathForType: function pathForType(type) {
      (false && !(type.match('^[^/]+/[^/]+$')) && Ember.assert('All models must have a valid namespace: <api>/<resource>', type.match('^[^/]+/[^/]+$')));

      var _type$split = type.split('/'),
          _type$split2 = _slicedToArray(_type$split, 2),
          path = _type$split2[1];

      return (0, _emberInflector.pluralize)(Ember.String.underscore(path));
    },
    baseUrlFor: function baseUrlFor() {
      var modelName = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
      (false && !(modelName.match('^[^/]+/[^/]+$')) && Ember.assert('All models must have a valid namespace: <api>/<resource>', modelName.match('^[^/]+/[^/]+$')));

      var _modelName$split = modelName.split('/'),
          _modelName$split2 = _slicedToArray(_modelName$split, 1),
          api = _modelName$split2[0];

      var _get = this.get('config.api-' + api),
          url = _get.url,
          version = _get.version;

      (false && !(url && version) && Ember.assert('There is no valid config for "api-' + api + '" for model "' + modelName + '"', url && version));


      return url + '/' + version;
    },


    /**
     * If an attribute is readonly, it won't be serialized.
     * Usage: In the model set for example:
     * ```
     *   name: attr('string', { readOnly: true })
     * ```
     *
     * @param {DS.Snapshot} snapshot
     * @param {Object} json
     * @param {String} key
     * @param {Object} attribute
     */
    serializeAttribute: function serializeAttribute(snapshot, json, key, attribute) {
      var isReadOnly = Ember.getWithDefault(attribute, 'options.readOnly', false);
      if (isReadOnly) {
        json[key] = null;
        return;
      }
      this._super.apply(this, arguments);
    },


    /**
     * If an hasMany attribute is readonly, it won't be serialized.
     * Usage: In the model set for example:
     * ```
     *   name: hasMany('className', { readOnly: true })
     * ```
     *
     * @param {DS.Snapshot} snapshot
     * @param {Object} json
     * @param {Object} relationship
     */
    serializeHasMany: function serializeHasMany(snapshot, json, relationship) {
      var isReadOnly = Ember.getWithDefault(relationship, 'options.readOnly', false);
      if (isReadOnly) {
        json[relationship.key] = null;
        return;
      }

      this._super.apply(this, arguments);
    },


    /**
     * If an belongsTo attribute is readonly, it won't be serialized.
     * Usage: In the model set for example:
     * ```
     *   name: belongsTo('className', { readOnly: true })
     * ```
     *
     * @param {DS.Snapshot} snapshot
     * @param {Object} json
     * @param {Object} relationship
     */
    serializeBelongsTo: function serializeBelongsTo(snapshot, json, relationship) {
      var isReadOnly = Ember.getWithDefault(relationship, 'options.readOnly', true);
      if (isReadOnly) {
        json[relationship.key] = null;
        return;
      }

      this._super.apply(this, arguments);
    },


    /**
     * Overwrites the requirement for the relationship to be explicitly stated.
     * https://github.com/emberjs/data/blob/v2.15.3/addon/serializers/embedded-records-mixin.js#L527
     * @param {*} attr
     * @returns {Object}
     */
    attrsOption: function attrsOption(attr) {
      return this._super(attr) || { serialize: 'records', deserialize: 'records' };
    }
  });
});