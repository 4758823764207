define("ln-ember-form-elements/templates/components/form-elements/styled-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+MFoQ7Z9",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\"],[4,\"if\",[[24,[\"label\"]]],null,{\"statements\":[[7,\"label\",true],[11,\"for\",[28,\"concat\",[[24,[\"elementId\"]],\"-select\"],null]],[8],[1,[22,\"label\"],false],[9]],\"parameters\":[]},null],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"multiple\"]]],null,{\"statements\":[[0,\"  \"],[7,\"span\",true],[11,\"class\",[29,[\"multiple-label \",[28,\"if\",[[24,[\"hasMultipleSelected\"]],\"selection\",\"placeholder\"],null],\" \",[28,\"if\",[[24,[\"showCount\"]],\"padded\",\"\"],null]]]],[8],[1,[22,\"labelMultipleSelected\"],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"select\",true],[11,\"id\",[28,\"concat\",[[24,[\"elementId\"]],\"-select\"],null]],[11,\"tabindex\",[22,\"tabindex\"]],[11,\"multiple\",[22,\"multiple\"]],[10,\"style\",\"width:100%\"],[8],[9],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"showCount\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"selected-count\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"removeAll\"],null]],[8],[0,\"\\n    \"],[1,[22,\"selectedCount\"],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[24,[\"removeIsAllowed\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"form-elements/styled-button\",null,[[\"tabindex\",\"action\",\"icon\",\"mouse-enter\",\"mouse-leave\",\"theme\"],[\"-1\",\"removeAll\",\"remove\",\"removeMouseEnter\",\"removeMouseLeave\",[24,[\"theme\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ln-ember-form-elements/templates/components/form-elements/styled-select.hbs"
    }
  });

  _exports.default = _default;
});