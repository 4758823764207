define('ln-ember-data-models/adapters/vdc/contract', ['exports', 'ln-ember-data-models/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    buildURL: function buildURL(modelName, id, snapshot) {

      var storeId = Ember.get(snapshot.record, 'store_id');

      var url = [this.baseUrlFor(modelName)];
      var path = void 0;

      if (modelName) {
        path = 'stores/' + storeId + '/contracts';
        url.push(path);
      }

      if (id) {
        url.push(encodeURIComponent(id));
      }

      url = url.join('/');

      return url;
    }
  });
});