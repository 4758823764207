define('ln-ember-data-models/models/vdc/assembly-position', ['exports', 'ember-data', 'ember-data-model-fragments/attributes'], function (exports, _emberData, _attributes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr,
      Model = _emberData.default.Model,
      belongsTo = _emberData.default.belongsTo;
  exports.default = Model.extend({

    /**
     * @memberof Vdc/AssemblyPositionModel
     * @instance
     *
     * @type {Number}
     */
    version: attr('number'),

    /**
     * @memberof Vdc/AssemblyPositionModel
     * @instance
     *
     * @type {Number}
     */
    amount: attr('number'),

    /**
     * @memberof Vdc/AssemblyPositionModel
     * @instance
     *
     * @type {Number}
     */
    product_amount: attr('number'),

    /**
     * @memberof Vdc/AssemblyPositionModel
     * @instance
     *
     * @type {Number}
     */
    assembly_id: attr('id'),

    /**
     * @memberof Vdc/AssemblyPositionModel
     * @instance
     *
     * @type {Number}
     */
    article_id: attr('id'),

    /**
     * @memberof Vdc/AssemblyPositionModel
     * @instance
     *
     * @type {String}
     */
    article_number: attr('nullable-string'),

    /**
     * @memberof Vdc/AssemblyPositionModel
     * @instance
     *
     * @type {String}
     */
    article_name: attr('nullable-string'),

    /**
     * @memberof Vdc/AssemblyPositionModel
     * @instance
     *
     * @type {Number}
     */
    article_height: attr('number'),

    /**
     * @memberof Vdc/AssemblyPositionModel
     * @instance
     *
     * @type {Boolean}
     */
    article_scalable: attr('boolean', { defaultValue: function defaultValue() {
        return false;
      } }),

    /**
     * @memberof Vdc/AssemblyPositionModel
     * @instance
     *
     * @type {Boolean}
     */
    article_alpha: attr('boolean', { defaultValue: function defaultValue() {
        return false;
      } }),

    /**
     * @memberof Vdc/AssemblyPositionModel
     * @instance
     *
     * @type {String}
     */
    assembly_entity_name: attr('nullable-string'),

    /**
     * @memberof Vdc/AssemblyPositionModel
     * @instance
     *
     * @type {Number}
     */
    window_id: attr('id'),

    /**
     * @memberof Vdc/AssemblyPositionModel
     * @instance
     *
     * @type {Number}
     */
    instore_area_id: attr('id'),

    /**
     * @memberof Vdc/AssemblyPositionModel
     * @instance
     *
     * @type {Number}
     */
    store_id: attr('id'),

    /**
     * @memberof Vdc/AssemblyPositionModel
     * @instance
     *
     * @type {Number}
     */
    fixture_id: attr('id'),

    /**
     * @memberof Vdc/AssemblyPositionModel
     * @instance
     *
     * @type {Array}
     */
    sub_positions: attr(),

    /**
     * @memberof Vdc/AssemblyPositionModel
     * @instance
     *
     * @type {Number}
     */
    sales_price: attr('number'),

    /**
     * @memberof Vdc/AssemblyPositionModel
     * @instance
     *
     * @type {Number}
     */
    purchase_price: attr('id'),

    /**
     * @memberof Vdc/AssemblyPositionModel
     * @instance
     *
     * @type {String}
     */
    crop_position: attr('nullable-string'),

    /**
     * @memberof Vdc/AssemblyPositionModel
     * @instance
     *
     * @type {Object}
     */
    motif_cropping_picture: attr(),

    /**
     * @memberof Vdc/AssemblyPositionModel
     * @instance
     *
     * @type {Array}
     */
    multi_motif_values: attr({ defaultValue: function defaultValue() {
        return [];
      } }),

    /**
     * @memberof Vdc/AssemblyPositionModel
     * @instance
     *
     * @type {Vdc/ArticleCategoryModel}
     */
    article_category: belongsTo('vdc/article-category'),

    /**
     * @memberof Vdc/AssemblyPositionModel
     * @instance
     *
     * @type {Vdc/MaterialModel}
     */
    article_material: belongsTo('vdc/material'),

    /**
     * @memberof Vdc/AssemblyPositionModel
     * @instance
     *
     * @type {Vdc/PictureModel}
     */
    article_picture: belongsTo('vdc/picture'),

    /**
     * @memberof Vdc/AssemblyPositionModel
     * @instance
     *
     * @type {Vdc/fragments/ObjectAreaFragment}
     */
    area: (0, _attributes.fragment)('vdc/fragments/object-area'),

    /**
     * @memberof Vdc/AssemblyPositionModel
     * @instance
     *
     * @type {Vdc/fragments/AttributeValueFragment[]}
     */
    attribute_values: (0, _attributes.fragmentArray)('vdc/fragments/attribute-value')

  });
});