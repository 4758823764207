define('ln-ember-data-models/models/vdc/vendor', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      belongsTo = _emberData.default.belongsTo,
      attr = _emberData.default.attr;
  exports.default = Model.extend({

    /**
     * @memberof Vdc/StoreModel
     * @instance
     *
     * @type {Number}
     */
    version: attr('number'),

    /**
     * @memberof Vdc/StoreModel
     * @instance
     *
     * @type {Number}
     */
    client_id: attr('id'),

    /**
     * @memberof Vdc/StoreModel
     * @instance
     *
     * @type {String}
     */
    company_name: attr('nullable-string'),

    /**
     * @memberof Vdc/StoreModel
     * @instance
     *
     * @type {Number}
     */
    address_id: attr('id'),

    /**
     * @memberof Vdc/StoreModel
     * @instance
     *
     * @type {String}
     */
    trades: attr('nullable-string'),

    /**
     * @memberof Vdc/StoreModel
     * @instance
     *
     * @type {String}
     */
    comments: attr('nullable-string'),

    /**
     * @memberof Vdc/StoreModel
     * @instance
     *
     * @type {Vdc/AddressModel}
     */
    address: belongsTo('vdc/address', { async: false })

  });
});