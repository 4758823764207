define('ln-ember-data-models/adapters/abstract/myliga/sub-resource', ['exports', 'ln-ember-data-models/adapters/application', 'ember-inflector', 'ember-data'], function (exports, _application, _emberInflector) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({

    resourcePath: null,

    parentResource: null,

    api: Ember.inject.service(),

    buildURL: function buildURL(modelName, id, snapshot, requestType, query) {
      var pathOnly = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : false;


      var resource = this.get('resourcePath') || this.pathForType(modelName);
      var parentResource = this.get('parentResource');
      var parentIdProp = parentResource + '_id';

      (false && !(parentResource) && Ember.assert('[abstract/SubResourceAdapter] parentResource needs to be set in subclass.', parentResource));


      var url = [];

      var prefix = this.urlPrefix();
      if (prefix) {
        url.push(prefix);
      }

      if (snapshot) {
        url.push((0, _emberInflector.pluralize)(parentResource));
        url.push(snapshot.attr(parentIdProp));
      }

      url.push((0, _emberInflector.pluralize)(resource));

      if (id) {
        url.push(encodeURIComponent(id));
      }

      var urlString = url.join('/').replace(/^\/*/, '/');

      if (pathOnly) {
        return urlString;
      }

      return '' + this.baseUrlFor(modelName) + urlString;
    }
  });
});