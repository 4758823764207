define("ln-ember-toolkit/mixins/scroll-events", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var timeoutFn = setTimeout;

  if ('requestIdleCallback' in window) {
    timeoutFn = function timeoutFn() {
      var _window;

      for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }

      return (_window = window).requestIdleCallback.apply(_window, args.concat([{
        timeout: 0
      }]));
    };
  }

  var _default = Ember.Mixin.create({
    lastScrollOffset: 0,
    lastScrollEventAt: 0,
    scrollSpeed: 0,
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.bindScrolling();
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this.unbindScrolling();
    },
    bindScrolling: function bindScrolling() {
      var _this = this;

      var elId = this.elementId;
      (0, _jquery.default)('.main-scroll-container').on("scroll.scroll-event-".concat(elId), function (evt) {
        timeoutFn(function () {
          Ember.run.throttle(_this, _this.triggerScroll, evt, 100);
          Ember.run.throttle(_this, _this.updateScrollSpeed, evt, 200);
          Ember.run.debounce(_this, _this.triggerDidScroll, evt, 250);
        });
      });
    },
    unbindScrolling: function unbindScrolling() {
      var elId = this.elementId;
      (0, _jquery.default)('.main-scroll-container').off("scroll.scroll-event-".concat(elId));
    },
    updateScrollSpeed: function updateScrollSpeed(e) {
      var delayInMs = e.timeStamp - this.lastScrollEventAt;
      var offset = e.target.scrollTop - this.lastScrollOffset;
      var speedInpxPerMs = Math.abs(offset / delayInMs);
      this.set('scrollSpeed', speedInpxPerMs);
      this.lastScrollEventAt = e.timeStamp;
      this.lastScrollOffset = e.target.scrollTop;
    },
    triggerDidScroll: function triggerDidScroll(e) {
      this.set('isScrolling', false);
      this.set('scrollSpeed', 0);
      this.trigger('didScroll', e);
    },
    triggerScroll: function triggerScroll(e) {
      this.set('isScrolling', true);
      this.trigger('scroll', e);
    }
  });

  _exports.default = _default;
});