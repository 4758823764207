define('ln-ember-data-models/models/vdc/fragments/object-area', ['exports', 'ember-data', 'ember-data-model-fragments/fragment'], function (exports, _emberData, _fragment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr;
  exports.default = _fragment.default.extend({

    /**
     * @memberof Vdc/fragments/ObjectAreaFragment
     * @instance
     *
     * @type {Number}
     * @default 50
     */
    width: attr('number', { defaultValue: 50 }),

    /**
     * @memberof Vdc/fragments/ObjectAreaFragment
     * @instance
     *
     * @type {Number}
     * @default 50
     */
    height: attr('number', { defaultValue: 50 }),

    /**
     * @memberof Vdc/fragments/ObjectAreaFragment
     * @instance
     *
     * @type {Boolean}
     * @default true
     *
     */
    enabled: attr('boolean', { defaultValue: true }),

    /**
     * @memberof Vdc/fragments/ObjectAreaFragment
     * @instance
     *
     * @type {Number}
     * @default 1
     *
     */
    order: attr('number', { defaultValue: 1 }),

    /**
     * @memberof Vdc/fragments/ObjectAreaFragment
     * @instance
     *
     * @type {Number}
     * @default 0
     *
     */
    x: attr('number', { defaultValue: 0 }),

    /**
     * @memberof Vdc/fragments/ObjectAreaFragment
     * @instance
     *
     * @type {Number}
     * @default 0
     *
     */
    y: attr('number', { defaultValue: 0 })
  });
});