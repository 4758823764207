define('ln-ember-data-models/models/vdc/fragments/text-module-group-comment-link', ['exports', 'ember-data', 'ember-data-model-fragments/fragment'], function (exports, _emberData, _fragment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr;


  /**
   * @class Vdc/fragments/TextModuleGroupCommentLinkFragment
   * @extends Fragment
   */
  var TextModuleGroupCommentLinkFragment = _fragment.default.extend({

    /**
     * @memberof Vdc/fragments/TextModuleGroupCommentLinkFragment
     * @instance
     *
     * @type {Number}
     */
    language_id: attr('id'),

    /**
     * @memberof Vdc/fragments/TextModuleGroupCommentLinkFragment
     * @instance
     *
     * @type {Number}
     */
    country_id: attr('id'),

    /**
     * @memberof Vdc/fragments/TextModuleGroupCommentLinkFragment
     * @instance
     *
     * @type {Number}
     */
    text_module_group_id: attr('id'),

    /**
     * `ember-data-model-fragments` do not support internal relationships
     * so this cannot be `hasMany` unfortunately
     * https://github.com/lytics/ember-data-model-fragments/issues/18
     *
     * @memberof Vdc/fragments/TextModuleGroupCommentLinkFragment
     * @instance
     *
     * @type {Vdc/CommentModel[]}
     */
    comments: attr({ defaultValue: function defaultValue() {
        return [];
      } })

  });

  exports.default = TextModuleGroupCommentLinkFragment;
});