define('ln-ember-data-models/models/vdc/work-package', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr,
      hasMany = _emberData.default.hasMany,
      Model = _emberData.default.Model;
  exports.default = Model.extend({

    /**
     * @memberof Vdc/WorkPackageModel
     * @instance
     *
     * @type {Number}
     */
    version: attr('number'),

    /**
     * @memberof Vdc/WorkPackageModel
     * @instance
     *
     * @type {Number}
     */
    position: attr('number'),

    /**
     * @memberof Vdc/WorkPackageModel
     * @instance
     *
     * @type {String}
     */
    name: attr('nullable-string'),

    /**
     * @memberof Vdc/WorkPackageModel
     * @instance
     *
     * @type {Number}
     */
    plan_id: attr('id'),

    /**
     * @memberof Vdc/WorkPackageModel
     * @instance
     *
     * @type {Number}
     */
    calculated_effort: attr('number', { defaultValue: 0 }),

    /**
     * @memberof Vdc/WorkPackageModel
     * @instance
     *
     * @type {Number}
     */
    estimated_effort: attr('number', { defaultValue: 0 }),

    /**
     * @memberof Vdc/WorkPackageModel
     * @instance
     *
     * @type {String}
     */
    start_date: attr('date-string'),

    /**
     * @memberof Vdc/WorkPackageModel
     * @instance
     *
     * @type {String}
     */
    end_date: attr('date-string'),

    /**
     * @memberof Vdc/WorkPackageModel
     * @instance
     *
     * @type {Vdc/TaskModel}
     */
    tasks: hasMany('vdc/task')

  });
});