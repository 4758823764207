define("ln-ember-toolkit/templates/components/ln-tooltip", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/0Qv5fKi",
    "block": "{\"symbols\":[\"@content\",\"&default\",\"@isShown\",\"@showOn\"],\"statements\":[[5,\"attach-tooltip\",[],[[\"@class\",\"@isShown\",\"@showOn\",\"@hideOn\"],[\"LnTooltip\",[23,3,[]],[23,4,[]],[22,\"hideOn\"]]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[25,2]],null,{\"statements\":[[0,\"    \"],[14,2],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[23,1,[]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ln-ember-toolkit/templates/components/ln-tooltip.hbs"
    }
  });

  _exports.default = _default;
});