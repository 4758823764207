define("ln-ember-toolkit/utils/liga-os", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.PREFIX_QUERY_PARAM_NAME = void 0;
  var PREFIX_QUERY_PARAM_NAME = 'ligaOsPrefix';
  _exports.PREFIX_QUERY_PARAM_NAME = PREFIX_QUERY_PARAM_NAME;
  var LigaOS = {
    lastIdx: 0,
    resolvers: {},
    eventHandlers: {},
    _parentWindow: null,
    window: window,

    get parentWindow() {
      if (this._parentWindow) {
        return this._parentWindow;
      }

      var parentWindow = window.parent.window;
      return parentWindow !== window ? parentWindow : null;
    },

    set parentWindow(value) {
      this._parentWindow = value;
    },

    /**
     * @memberof LigaOs
     * @instance
     *
     * @method isLigaOsPresent
     * @return {boolean}
     */
    isLigaOsPresent: function isLigaOsPresent() {
      return !!this.parentWindow;
    },

    /**
     * @memberof LigaOs
     * @instance
     *
     * @method openUrl
     * @param  {String} url
     * @return {Promise}
     */
    openUrl: function openUrl(url) {
      return this.call('openUrl', [url]);
    },

    /**
     * @memberof LigaOs
     * @instance
     *
     * @method openResource
     * @param  {String} resource
     * @param  {Number} resourceId
     * @return {Promise}
     */
    openResource: function openResource(resource, resourceId) {
      return this.call('openResource', [resource, resourceId]);
    },

    /**
     * @memberof LigaOs
     * @instance
     *
     * @method setUrl
     * @param  {String} url
     * @return {Promise}
     */
    setUrl: function setUrl(url) {
      return this.call('setUrl', [url]);
    },

    /**
     * @memberof LigaOs
     * @instance
     *
     * @method getConfig
     * @return {Promise}
     */
    getConfig: function getConfig() {
      return this.call('getConfig');
    },

    /**
     * @memberof LigaOs
     * @instance
     *
     * @method getSession
     * @return {Promise}
     */
    getSession: function getSession() {
      return this.call('getSession');
    },

    /**
     * @memberof LigaOs
     * @instance
     *
     * @method validateSession
     * @return {Promise}
     */
    validateSession: function validateSession() {
      return this.call('validateSession');
    },

    /**
     * @memberof LigaOs
     * @instance
     *
     * @method logout
     * @return {Promise}
     */
    logout: function logout() {
      return this.call('logout');
    },

    /**
     * @method getLanguage
     * @return {Promise}
     */
    getLanguage: function getLanguage() {
      return this.call('getLanguage');
    },

    /**
     * @memberof LigaOs
     * @instance
     *
     * @method setLanguage
     * @param {String} languageCode
     * @return {Promise}
     */
    setLanguage: function setLanguage(languageCode) {
      return this.call('setLanguage', [languageCode]);
    },

    /**
     * @memberof LigaOs
     * @instance
     *
     * @method setTitle
     * @param {String} title
     * @return {Promise}
     */
    setTitle: function setTitle(title) {
      return this.call('setTitle', [title]);
    },

    /**
     * @memberof LigaOs
     * @instance
     *
     * @method setTitleCount
     * @param {Number} count
     * @return {Promise}
     */
    setTitleCount: function setTitleCount() {
      var count = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
      return this.call('setTitleCount', [count]);
    },

    /**
     * @memberof LigaOs
     * @instance
     *
     * @method hideCommunicator
     * @return {Promise}
     */
    hideCommunicator: function hideCommunicator() {
      return this.call('hideCommunicator');
    },

    /**
     * @memberof LigaOs
     * @instance
     *
     * @method showCommunicator
     * @return {Promise}
     */
    showCommunicator: function showCommunicator() {
      return this.call('showCommunicator');
    },

    /**
     * @memberof LigaOs
     * @instance
     *
     * @method notifyAppLoaded
     * @return {Promise}
     */
    notifyAppLoaded: function notifyAppLoaded() {
      return this.call('notifyAppLoaded');
    },

    /**
     * @memberof LigaOs
     * @instance
     *
     * @method notifyInvalidSession
     * @return {Promise}
     */
    notifyInvalidSession: function notifyInvalidSession() {
      return this.call('notifyInvalidSession');
    },

    /**
     * @memberof LigaOs
     * @instance
     *
     * @method notifyAccessDenied
     * @param {object} options
     * @return {Promise}
     */
    notifyAccessDenied: function notifyAccessDenied() {
      var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return this.call('notifyAccessDenied', [options]);
    },

    /**
     * @memberof LigaOs
     * @instance
     *
     * @method setBlur
     * @param {Boolean} state
     * @return {Promise}
     */
    setBlur: function setBlur(state) {
      return this.call('setBlur', [state]);
    },

    /**
     * @memberof LigaOs
     * @instance
     *
     * @method checkBrowserSupport
     * @param {Array} supportedBrowsers
     * @return {Promise}
     */
    checkBrowserSupport: function checkBrowserSupport(supportedBrowsers) {
      return this.call('checkBrowserSupport', [supportedBrowsers]);
    },

    /**
     * @memberof LigaOsApi
     * @instance
     *
     * @method getTheme
     * @param {Object} theme
     * @return {Promise<Object>}
     */
    getTheme: function getTheme() {
      return this.call('getTheme', []);
    },

    /**
     * @memberof LigaOsApi
     * @instance
     *
     * @method getSetting
     * @param {string} key
     * @param {any} defaultValue
     * @return {Promise<any>} value
     */
    getSetting: function getSetting(key, defaultValue) {
      return this.call('getSetting', [key, defaultValue]);
    },

    /**
     * @memberof LigaOsApi
     * @instance
     *
     * @method getTheme
     * @param {String} key
     * @param {any} value
     * @return {Promise<any>} value
     */
    setSetting: function setSetting(key, value) {
      return this.call('setSetting', [key, value]);
    },

    /**
     * @memberof LigaOs
     * @instance
     *
     * @private
     * @method call
     * @param {String} method
     * @param {Array} args
     * @return {Promise}
     */
    call: function call(method, args) {
      var _this = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        var parentWindow = _this.parentWindow;

        if (!parentWindow) {
          reject(new Error('Liga OS not reachable'));
        }

        _this.lastIdx = _this.lastIdx + 1;
        parentWindow.postMessage({
          idx: _this.lastIdx,
          method: method,
          args: args
        }, '*');
        _this.resolvers[_this.lastIdx] = {
          resolve: resolve,
          reject: reject
        };
      });
    },
    splitEventName: function splitEventName(event) {
      var eventArray = event.split('.');
      var eventName = eventArray.shift();
      var namespace = eventArray.join('-') || 'no-namespace';
      return {
        eventName: eventName,
        namespace: namespace
      };
    },
    register: function register(event, listener) {
      var handler = this.eventHandlers || {};

      var _this$splitEventName = this.splitEventName(event),
          eventName = _this$splitEventName.eventName,
          namespace = _this$splitEventName.namespace;

      if (!handler[eventName]) {
        handler[eventName] = {};
      }

      if (!Array.isArray(handler[eventName][namespace])) {
        handler[eventName][namespace] = [];
      }

      handler[eventName][namespace].push(listener);
      this.eventHandlers = handler;
    },
    unregister: function unregister(event) {
      var handler = this.eventHandlers || {};

      var _this$splitEventName2 = this.splitEventName(event),
          eventName = _this$splitEventName2.eventName,
          namespace = _this$splitEventName2.namespace;

      if (!handler[eventName]) {
        return;
      }

      if (handler[eventName][namespace]) {
        delete handler[eventName][namespace];
      }

      this.eventHandlers = handler;
    },
    onMessageEvent: function onMessageEvent(_ref) {
      var _ref$data = _ref.data,
          idx = _ref$data.idx,
          response = _ref$data.response,
          eventName = _ref$data.event;
      this.handleResponse(idx, response);
      this.handleEvent(eventName);
    },
    handleResponse: function handleResponse(idx, response) {
      var resolvers = this.resolvers;

      if (idx && resolvers[idx]) {
        resolvers[idx].resolve(response);
        delete resolvers[idx];
      }
    },
    handleEvent: function handleEvent(eventName) {
      if (eventName && this.eventHandlers && this.eventHandlers[eventName]) {
        var eventHandlers = this.eventHandlers[eventName];
        Object.keys(eventHandlers).forEach(function (namespace) {
          eventHandlers[namespace].forEach(function (listener) {
            return listener();
          });
        });
      }
    }
  };
  LigaOS.window.addEventListener('message', function (evt) {
    LigaOS.onMessageEvent(evt);
  }, false);
  var _default = LigaOS;
  _exports.default = _default;
});