define('ln-ember-api-service/utils/normalize-error', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = normalizeError;
  function normalizeError(status) {
    var payload = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'unknown error';

    // ⚠️ Error 500s in the myliga api are plain text (Content-Type:text/plain)
    if (typeof payload === 'string') {
      return { status_code: status, message: payload, details: [] };
    }

    // ⚠️ Error responses look diffrent in myliga and vdc
    // - vdc:    `{ status_code, error_code, message, details }`
    // - myliga: `{ status_code, error_message, details }`

    var status_code = payload.status_code,
        error_message = payload.error_message,
        message = payload.message,
        details = payload.details,
        error_code = payload.error_code;


    // (1) Normalize all detailts to { reference, message }
    details = details.map(function (_ref) {
      var reference = _ref.reference,
          message = _ref.message,
          error_message = _ref.error_message;
      return {
        reference: reference && reference.replace(/^obj\./, ''),
        message: message || error_message
      };
    });

    // (2) Normalize the message and add details without a reference to it
    var unreferencedDetails = details.filter(function (_ref2) {
      var reference = _ref2.reference;
      return !reference;
    }).map(function (_ref3) {
      var message = _ref3.message;
      return message;
    });

    message = [message || error_message].concat(unreferencedDetails).join('\n');

    // (3) Remove details without a refernce
    details = details.filter(function (_ref4) {
      var reference = _ref4.reference;
      return reference;
    });

    error_code = error_code ? error_code : null;

    return { status_code: status_code, message: message, details: details, error_code: error_code };
  }
});