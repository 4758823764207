define('ln-ember-data-models/models/vdc/address', ['exports', 'ember-data', 'ln-ember-data-models/utils/enum-option'], function (exports, _emberData, _enumOption) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.personalTitleOptions = undefined;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;


  /**
   * @name Vdc/AddressModel/personalTitleOptions
   * @type {EnumOption[]}
   */
  var personalTitleOptions = exports.personalTitleOptions = [_enumOption.default.create({ id: 'Mr' }), _enumOption.default.create({ id: 'Mrs' })];

  /**
   * @class Vdc/Address
   * @extends DS.Model
   */
  var AddressModel = Model.extend({

    /**
     * @memberof Vdc/Address
     * @instance
     *
     * @type {Number}
     */
    version: attr('number'),

    /**
     * @memberof Vdc/Address
     * @instance
     *
     * @type {String}
     */
    street1: attr('nullable-string'),

    /**
     * @memberof Vdc/Address
     * @instance
     *
     * @type {String}
     */
    street2: attr('nullable-string'),

    /**
     * @memberof Vdc/Address
     * @instance
     *
     * @type {String}
     */
    zip: attr('nullable-string'),

    /**
     * @memberof Vdc/Address
     * @instance
     *
     * @type {String}
     */
    city: attr('nullable-string'),

    /**
     * @memberof Vdc/Address
     * @instance
     *
     * @type {Number}
     */
    country_id: attr('id'),

    /**
     * @memberof Vdc/Address
     * @instance
     *
     * @type {Boolean}
     */
    shared: attr('boolean', { defaultValue: false }),

    /**
     * @memberof Vdc/Address
     * @instance
     *
     * @type {Number}
     */
    client_id: attr('id'),

    /**
     * @memberof Vdc/Address
     * @instance
     *
     * @type {String}
     */
    owner_kind: attr('nullable-string'),

    /**
     * @memberof Vdc/Address
     * @instance
     *
     * @type {Array}
     */
    owners: attr({ defaultValue: function defaultValue() {
        return [];
      } }),

    /**
     * @memberof Vdc/Address
     * @instance
     *
     * @type {String}
     */
    type: attr('nullable-string'),

    /**
     * @memberof Vdc/Address
     * @instance
     *
     * @type {String}
     */
    contact_personal_title: attr('enum-string', { options: personalTitleOptions }),

    /**
     * @memberof Vdc/Address
     * @instance
     *
     * @type {String}
     */
    contact_forename: attr('nullable-string'),

    /**
     * @memberof Vdc/Address
     * @instance
     *
     * @type {String}
     */
    contact_name: attr('nullable-string'),

    /**
     * @memberof Vdc/Address
     * @instance
     *
     * @type {String}
     */
    contact_phone: attr('nullable-string'),

    /**
     * @memberof Vdc/Address
     * @instance
     *
     * @type {String}
     */
    contact_email: attr('nullable-string'),

    /**
     * @memberof Vdc/Address
     * @instance
     *
     * @type {String}
     */
    legal_name: attr('nullable-string'),

    /**
     * @memberof Vdc/Address
     * @instance
     *
     * @type {String}
     */
    vat_number: attr('nullable-string'),

    /**
     * @memberof Vdc/Address
     * @instance
     *
     * @type {String}
     */
    delivery_number: attr('nullable-string'),

    /**
     * @memberof Vdc/Address
     * @instance
     *
     * @type {String}
     */
    contact_fax: attr('nullable-string'),

    /**
     * @memberof Vdc/Address
     * @instance
     *
     * @type {String}
     */
    contact_homepage: attr('nullable-string'),

    /**
     * @memberof Vdc/Address
     * @instance
     *
     * @type {Number}
     */
    latitude: attr('number'),

    /**
     * @memberof Vdc/Address
     * @instance
     *
     * @type {Number}
     */
    longitude: attr('number'),

    /**
     * @memberof Vdc/Address
     * @instance
     *
     * @type {Number}
     */
    country: belongsTo('vdc/country')
  });

  AddressModel.reopenClass({

    /**
     * @memberof Vdc/AddressModel
     * @static
     *
     * @type {EnumOption[]}
     */
    personalTitleOptions: personalTitleOptions

  });

  exports.default = AddressModel;
});