define('ln-ember-data-models/adapters/application', ['exports', 'ember-data', 'ember-inflector'], function (exports, _emberData, _emberInflector) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var RESTAdapter = _emberData.default.RESTAdapter,
      InvalidError = _emberData.default.InvalidError,
      AdapterError = _emberData.default.AdapterError;
  exports.default = RESTAdapter.extend({

    config: Ember.computed(function () {
      var config = Ember.getOwner(this).resolveRegistration('config:environment');
      return config.APP.config;
    }),

    headers: Ember.computed('session.token', 'language', 'host.clientId', function () {
      var headers = {
        Authorization: 'Bearer ' + this.get('session.token'),
        'Accept-Language': this.get('language')
      };

      if (this.get('host.clientId')) {
        headers['Client-Id'] = this.get('host.clientId');
      }

      return headers;
    }),

    apiEvents: Ember.inject.service(),

    host: Ember.inject.service(),

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      var apiEvents = this.get('apiEvents');

      this.set('session', apiEvents.getSession());
      apiEvents.on('session-change', function (session) {
        _this.set('session', session);
      });

      this.set('language', apiEvents.getLanguage());
      apiEvents.on('language-change', function (language) {
        _this.set('language', language);
      });
    },


    // Modeltypes have the form of 'vdc/comment' or 'myliga/comment', but the vdc or myliga part is not included in the api path.
    // So we take out the namespace in here and format the actual modelname for the api
    pathForType: function pathForType(type) {
      (false && !(type.match('^[^/]+/[^/]+$')) && Ember.assert('All models must have a valid namespace: <api>/<resource>', type.match('^[^/]+/[^/]+$')));

      var _type$split = type.split('/'),
          _type$split2 = _slicedToArray(_type$split, 2),
          path = _type$split2[1];

      return (0, _emberInflector.pluralize)(Ember.String.underscore(path));
    },
    handleResponse: function handleResponse(status, headers, payload) {
      if (this.isSuccess(status, headers, payload)) {
        return payload;
      }

      var _normalizeErrorRespon = this.normalizeErrorResponse(status, payload),
          status_code = _normalizeErrorRespon.status_code,
          message = _normalizeErrorRespon.message,
          details = _normalizeErrorRespon.details;

      this.get('apiEvents').triggerErrorResponse({ status_code: status_code, message: message, details: details });

      if (status_code === 400) {
        return this._getErrorCodeWithStatus(new InvalidError(details, message), status_code);
      }

      return this._getErrorCodeWithStatus(new AdapterError(details, message), status_code);
    },
    normalizeErrorResponse: function normalizeErrorResponse(status, payload) {

      payload = payload || { status_code: status, message: 'Unknow error', details: [] };

      // ⚠️ Error 500s in the myliga api are plain text (Content-Type:text/plain)
      if (typeof payload === 'string') {
        return { status_code: status, message: payload, details: [] };
      }

      // ⚠️ Error responses look diffrent in myliga and vdc
      // - vdc:    `{ status_code, error_code, message, details }`
      // - myliga: `{ status_code, error_message, details }`

      var _payload = payload,
          status_code = _payload.status_code,
          error_message = _payload.error_message,
          message = _payload.message,
          details = _payload.details;

      // (1) Normalize all detailts to { reference, message }

      details = details.map(function (_ref) {
        var reference = _ref.reference,
            message = _ref.message,
            error_message = _ref.error_message;
        return {
          reference: reference && reference.replace(/^obj\./, ''),
          message: message || error_message
        };
      });

      // (2) Normalize the message and add details without a reference to it
      var unreferencedDetails = details.filter(function (_ref2) {
        var reference = _ref2.reference;
        return !reference;
      }).map(function (_ref3) {
        var message = _ref3.message;
        return message;
      });

      message = [message || error_message].concat(unreferencedDetails).join('\n');

      // (3) Remove details without a refernce
      details = details.filter(function (_ref4) {
        var reference = _ref4.reference;
        return reference;
      });

      return { status_code: status_code, message: message, details: details };
    },
    buildURL: function buildURL(modelName, id, snapshot, requestType, query) {

      switch (requestType) {
        case 'findRecord':
          return this.urlForFindRecord(id, modelName, snapshot);
        case 'findAll':
          return this.urlForFindAll(modelName, snapshot);
        case 'query':
          return this.urlForQuery(query, modelName, snapshot);
        case 'queryRecord':
          return this.urlForQueryRecord(query, modelName, snapshot);
        case 'findMany':
          return this.urlForFindMany(id, modelName, snapshot);
        case 'findHasMany':
          return this.urlForFindHasMany(id, modelName, snapshot);
        case 'findBelongsTo':
          return this.urlForFindBelongsTo(id, modelName, snapshot);
        case 'createRecord':
          return this.urlForCreateRecord(modelName, snapshot);
        case 'updateRecord':
          return this.urlForUpdateRecord(id, modelName, snapshot);
        case 'deleteRecord':
          return this.urlForDeleteRecord(id, modelName, snapshot);
        default:
          return this._buildURL(modelName, id, null, snapshot);
      }
    },
    urlForFindRecord: function urlForFindRecord(id, modelName, snapshot) {
      return this._buildURL(modelName, id, null, snapshot);
    },
    urlForFindAll: function urlForFindAll(modelName, snapshot) {
      return this._buildURL(modelName, null, null, snapshot);
    },
    urlForQuery: function urlForQuery(query, modelName, snapshot) {
      return this._buildURL(modelName, null, null, snapshot);
    },
    urlForQueryRecord: function urlForQueryRecord(query, modelName, snapshot) {
      return this._buildURL(modelName, null, null, snapshot);
    },
    urlForFindMany: function urlForFindMany(id, modelName, snapshot) {
      return this._buildURL(modelName, id, null, snapshot);
    },
    urlForFindHasMany: function urlForFindHasMany(id, modelName, snapshot) {
      return this._buildURL(modelName, id, null, snapshot);
    },
    urlForFindBelongsTo: function urlForFindBelongsTo(id, modelName, snapshot) {
      return this._buildURL(modelName, id, null, snapshot);
    },
    urlForCreateRecord: function urlForCreateRecord(modelName, snapshot) {
      var transactionId = null;
      if (Ember.get(snapshot, 'adapterOptions.transaction')) {
        transactionId = snapshot.adapterOptions.transaction.get('id');
      }
      return this._buildURL(modelName, null, transactionId, snapshot, false);
    },
    urlForUpdateRecord: function urlForUpdateRecord(id, modelName, snapshot) {
      return this._buildURL(modelName, id, null, snapshot, false);
    },
    urlForDeleteRecord: function urlForDeleteRecord(id, modelName, snapshot) {
      return this._buildURL(modelName, id, null, snapshot, false);
    },
    _buildURL: function _buildURL(modelName, id, transactionId, snapshot) {
      var addDefaultEmbeds = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : true;

      if (modelName) {

        var path = this.pathForType(modelName);

        if (path) {
          var defaultEmbeds = null;
          if (id) {
            path += '/' + encodeURIComponent(id);
          }
          if (snapshot && addDefaultEmbeds) {
            if (snapshot.record) {
              defaultEmbeds = snapshot.record.get('defaultEmbeds');
            } else if (snapshot.snapshots().length > 0) {
              var snapshots = snapshot.snapshots();
              defaultEmbeds = snapshots[0].record.get('defaultEmbeds');
            }
          }
          return this.buildURLFor(path, transactionId, modelName, defaultEmbeds);
        }
      }
    },
    _getErrorCodeWithStatus: function _getErrorCodeWithStatus(error, statusCode) {
      return Ember.assign(error, { statusCode: statusCode });
    },
    buildURLFor: function buildURLFor(path, transactionId, modelName, defaultEmbeds) {
      var paramString = '';

      if (transactionId || defaultEmbeds) {
        paramString += '?';
      }

      if (transactionId) {
        paramString += 'transaction=' + transactionId;
      }

      if (defaultEmbeds) {
        defaultEmbeds.forEach(function (item, index) {
          if (index === 0 && !transactionId) {
            paramString += 'embed%5B%5D=' + item;
          } else {
            paramString += '&embed%5B%5D=' + item;
          }
        });
      }

      if (path.indexOf('http') === 0) {
        return path += paramString;
      }

      path = path.replace(/^\//, '');

      var url = [];

      url.push(this.baseUrlFor(modelName));
      url.push(path);

      var urlString = url.join('/');
      urlString += paramString;

      return urlString;
    },
    findHasMany: function findHasMany(store, snapshot, path) {
      var defaultEmbeds = snapshot.record.get('defaultEmbeds');

      return this.ajax(this.buildURLFor(path, null, null, defaultEmbeds), 'GET');
    },


    // Ember Data 2.0 Reload behavior
    shouldReloadRecord: function shouldReloadRecord() {
      return true;
    },
    shouldReloadAll: function shouldReloadAll() {
      return true;
    },
    shouldBackgroundReloadRecord: function shouldBackgroundReloadRecord() {
      return true;
    },
    shouldBackgroundReloadAll: function shouldBackgroundReloadAll() {
      return true;
    },
    baseUrlFor: function baseUrlFor() {
      var modelName = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
      (false && !(modelName.match('^[^/]+/[^/]+$')) && Ember.assert('All models must have a valid namespace: <api>/<resource>', modelName.match('^[^/]+/[^/]+$')));

      var _modelName$split = modelName.split('/'),
          _modelName$split2 = _slicedToArray(_modelName$split, 1),
          api = _modelName$split2[0];

      var _getWithDefault = this.getWithDefault('config.api-' + api, {}),
          url = _getWithDefault.url,
          version = _getWithDefault.version;

      (false && !(url && version) && Ember.assert('There is no valid config for "api-' + api + '" for model "' + modelName + '"', url && version));


      return url + '/' + version;
    },
    ajaxOptions: function ajaxOptions(url, type, options) {
      this.modifyAjaxOptions(options);
      return this._super(url, type, options);
    },
    modifyAjaxOptions: function modifyAjaxOptions(options) {
      if (!options) {
        return;
      }

      var data = options.data;


      if (data && (typeof data === 'undefined' ? 'undefined' : _typeof(data)) === 'object') {
        Object.keys(data).forEach(function (key) {
          if (Array.isArray(data[key])) {
            data[key] = Ember.A(data[key]).uniq();
          }
        });
      }

      return { data: data };
    }
  });
});