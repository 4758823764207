define("ln-ember-form-elements/utils/moment-helper", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.roundToQuarterHours = roundToQuarterHours;
  _exports.isBetweenOrSame = isBetweenOrSame;
  _exports.range = range;
  _exports.dateToWeekData = dateToWeekData;
  _exports.weekDataToDate = weekDataToDate;
  _exports.weekKey = weekKey;
  _exports.weekKeyToDate = weekKeyToDate;
  _exports.weekStart = weekStart;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  /**
   * @module utils/moment-helper
   */

  /**
   * @param  {MomentObject} date
   * @return {MomentObject}
   */
  function roundToQuarterHours(date) {
    var minutes = Math.round(date.minutes() / 15) * 15;
    date.minutes(minutes).seconds(0).milliseconds(0);
    return date;
  }
  /**
   * Return `true` if `date` is between `startDay` and `endDay`, including start
   * and end day.
   *
   * Example:
   *
   * ```JavaScript
   * isBetweenOrSame(moment('2016-12-24'), '2016-12-24', '2016-12-26', 'day') // => `true`
   * isBetweenOrSame(moment('2016-12-23'), '2016-12-24', '2016-12-26', 'day') // => `false`
   * ```
   *
   * @param  {moment-like} date
   * @param  {moment-like} start
   * @param  {moment-like} end
   * @param  {object} options
   * @param  {string} options.type='day'
   * @return {boolean}
   */


  function isBetweenOrSame(date, start, end) {
    var _ref = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {},
        _ref$type = _ref.type,
        type = _ref$type === void 0 ? 'day' : _ref$type;

    date = (0, _moment.default)(date);
    return date.isSameOrAfter(start, type) && date.isSameOrBefore(end, type);
  }
  /**
   * @param  {moment-like} start
   * @param  {moment-like} end
   * @param  {object} options
   * @param  {string} options.type="day"
   * @param  {number} options.step=1
   * @return {MomentObject[]}
   */


  function range(start, end) {
    var _ref2 = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {},
        _ref2$type = _ref2.type,
        type = _ref2$type === void 0 ? 'day' : _ref2$type,
        _ref2$step = _ref2.step,
        step = _ref2$step === void 0 ? 1 : _ref2$step;

    var range = [];
    var date = (0, _moment.default)(start);

    while (date.isSameOrBefore(end)) {
      range.push((0, _moment.default)(date));
      date.add(step, type);
    }

    return range;
  }
  /**
   * @param  {moment-like} date
   * @return {Object} weekData
   */


  function dateToWeekData(date) {
    // Thursday determs, the iso week (ISO-8601)
    // See: https://en.wikipedia.org/wiki/Week#Week_numbering
    var weekDay = (0, _moment.default)(date).isoWeekday(4);
    return {
      year: weekDay.year(),
      week: weekDay.isoWeek()
    };
  }
  /**
   * @param  {Object} options
   * @param  {Number} options.year
   * @param  {Number} options.week
   * @return {MomentObject}
   */


  function weekDataToDate(_ref3) {
    var year = _ref3.year,
        week = _ref3.week;
    return (0, _moment.default)("".concat(year, "-").concat(week, "-1"), 'YYYY-W-E');
  }
  /**
   * @param  {moment-like} date
   * @return {String}
   */


  function weekKey(date) {
    var _dateToWeekData = dateToWeekData(date),
        year = _dateToWeekData.year,
        week = _dateToWeekData.week;

    return "".concat(year, "-").concat(week);
  }
  /**
   * @param  {String} key
   * @return {String}
   */


  function weekKeyToDate(key) {
    var _key$match = key.match(/^(\d+)-(\d+)$/),
        _key$match2 = _slicedToArray(_key$match, 3),
        year = _key$match2[1],
        week = _key$match2[2];

    return weekDataToDate({
      year: year,
      week: week
    });
  }
  /**
   * @param  {moment-like} date
   * @return {MomentObject}
   */


  function weekStart(date) {
    return weekDataToDate(dateToWeekData(date));
  }
});