define("ln-ember-toolkit/services/configuration", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @deprecated
   * @class Configuration
   * @extends Ember.Service
   */
  var _default = Ember.Service.extend({
    /**
     * @memberof Configuration
     * @instance
     *
     * @private
     * @property {Object}
     */
    data: Ember.computed(function () {
      var configMain = Ember.getOwner(this).resolveRegistration('config:main');

      if (configMain) {
        return configMain;
      }

      var _Ember$getOwner$resol = Ember.getOwner(this).resolveRegistration('config:environment'),
          config = _Ember$getOwner$resol.APP.config;

      return Ember.Object.create(config);
    }).readOnly(),

    /**
     * @memberof Configuration
     * @instance
     *
     * @param  {String} key
     * @param  {*} defaultValue
     * @return {*}
     */
    getConfig: function getConfig(key) {
      var defaultValue = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
      var value = this.get("data.".concat(key));

      if (!Ember.isNone(value)) {
        return value;
      }

      return defaultValue;
    }
  });

  _exports.default = _default;
});