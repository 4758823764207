define("ln-ember-form-elements/components/form-elements/radio-group", ["exports", "ln-ember-form-elements/templates/components/form-elements/radio-group"], function (_exports, _radioGroup) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  var RadioOption = Ember.Object.extend({
    id: null,
    text: null,
    readonly: Ember.computed('value', 'group.mandatory', function () {
      return Boolean(this.get('value') && this.get('group.mandatory'));
    }),
    value: Ember.computed('id', 'group.inputValue', function () {
      return this.get('id') === this.get('group.inputValue');
    })
  });
  /**
   * @class formElements/RadioGroupComponent
   * @extends @ember/component
   */

  var _default = Ember.Component.extend({
    layout: _radioGroup.default,
    classNames: [// Deprecated: { id: ln-ember-form-elements-class-names, until: 3.0.0 }
    'radio-group-component', 'form-elements--radio-group-component'],
    formElements: Ember.inject.service(),

    /**
     * Defines which attribute of an options object should be used as the value of
     * the option.<br>
     * For example if the value is the id of the object the `optionValuePath`
     * should be set to `id`
     *
     * @memberof formElements/RadioGroupComponent
     * @instance
     *
     * @type String
     * @default "value"
     */
    optionValuePath: 'value',

    /**
     * Defines which attribute of an options object should be used as the label of
     * the option.<br>
     * For example if the attribute `name` should be used as the label
     * `optionLabelPath` should be set to `name`
     *
     * @memberof formElements/RadioGroupComponent
     * @instance
     *
     * @type String
     * @default "label"
     */
    optionLabelPath: 'label',

    /**
     * An array of all options that are listed in the select field.
     *
     * @memberof formElements/RadioGroupComponent
     * @instance
     *
     * @type Array
     * @default null
     */
    options: null,

    /**
     * Fired when the input value changes.
     *
     * **If the `onChange` handler is set, the value does not get updated by the
     * StyledInputComponent anymore.**
     *
     * @memberof formElements/RadioGroupComponent
     * @instance
     *
     * @type {function}
     */
    onChange: function onChange() {},

    /**
     * If the radio group is mandatory, the value cannot be unset.
     *
     * @memberof formElements/RadioGroupComponent
     * @instance
     *
     * @type Boolean
     * @default false
     */
    mandatory: false,
    formatedOptions: Ember.computed('options.[]', 'optionValuePath', 'optionLabelPath', function () {
      var _this = this;

      var valuePath = this.get('optionValuePath');
      var labelPath = this.get('optionLabelPath');
      return (this.get('options') || []).map(function (item) {
        var option = _typeof(item) !== 'object' ? {
          group: _this,
          id: item,
          text: item
        } : {
          group: _this,
          id: Ember.get(item, valuePath),
          text: Ember.get(item, labelPath),
          model: item
        };
        return RadioOption.create(option);
      });
    }),
    inputValue: Ember.computed('value', {
      get: function get() {
        return this.get('value');
      },
      set: function set(k, value) {
        this.get('onChange')(value);
        return value;
      }
    }),
    checkRemove: function checkRemove() {
      if (this.$() && this.$('input:checked').length === 0) {
        this.set('inputValue', null);
      }
    },
    actions: {
      onOptionChange: function onOptionChange(value, checked) {
        if (checked) {
          this.set('inputValue', value);
        } else if (!this.get('mandatory')) {
          Ember.run.debounce(this, this.checkRemove, 10);
        }
      }
    }
  });

  _exports.default = _default;
});