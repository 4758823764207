define('ln-ember-data-models/transforms/number-array', ['exports', 'ember-data/transform'], function (exports, _transform) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _transform.default.extend({
    deserialize: function deserialize(serialized) {
      return serialized;
    },
    serialize: function serialize(deserialized) {
      if (Ember.isNone(deserialized)) {
        return null;
      }
      return deserialized.map(function (item) {
        return parseInt(item);
      });
    }
  });
});