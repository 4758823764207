define('ln-ember-data-models/models/myliga/user', ['exports', 'ember-data', 'ember-data-model-fragments/attributes'], function (exports, _emberData, _attributes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      Model = _emberData.default.Model;
  exports.default = Model.extend({

    /**
     * @memberof Myliga/UserModel
     * @instance
     *
     * @type {Number}
     */
    version: attr('number'),

    /**
     * @memberof Myliga/UserModel
     * @instance
     *
     * @type {Boolean}
     */
    active: attr('boolean'),

    /**
     * @memberof Myliga/UserModel
     * @instance
     *
     * @type {String}
     */
    forename: attr('nullable-string'),

    /**
     * @memberof Myliga/UserModel
     * @instance
     *
     * @type {String}
     */
    name: attr('nullable-string'),

    /**
     * @memberof Myliga/UserModel
     * @instance
     *
     * @type {Number}
     */
    username: attr('nullable-string'),

    /**
     * @memberof Myliga/UserModel
     * @instance
     *
     * @type {String}
     */
    email: attr('nullable-string'),

    /**
     * @memberof Myliga/UserModel
     * @instance
     *
     * @type {String}
     */
    phone: attr('nullable-string'),

    /**
     * @memberof Myliga/UserModel
     * @instance
     *
     * @type {String}
     */
    phone_mobile: attr('nullable-string'),

    /**
     * @memberof Myliga/UserModel
     * @instance
     *
     * @type {String}
     */
    position: attr('nullable-string'),

    /**
     * @memberof Myliga/UserModel
     * @instance
     *
     * @type {Number}
     */
    client_id: attr('id'),

    /**
     * @memberof Myliga/UserModel
     * @instance
     *
     * @type {String}
     */
    language_id: attr('id'),

    /**
     * @memberof Myliga/UserModel
     * @instance
     *
     * @type {Number}
     */
    user_type_id: attr('id'),

    /**
     * @memberof Myliga/UserModel
     * @instance
     *
     * @type {Number}
     */
    tegroup_fid: attr('id'),

    /**
     * @memberof Myliga/UserModel
     * @instance
     *
     * @type {String}
     */
    entry_date: attr('date-string', { timeOption: 'start' }),

    /**
     * @memberof Myliga/UserModel
     * @instance
     *
     * @type {String}
     */
    exit_date: attr('date-string', { timeOption: 'end' }),

    /**
     * @memberof Myliga/UserModel
     * @instance
     *
     * @type {String}
     */
    client_name: attr('nullable-string'),

    /**
     * @memberof Myliga/UserModel
     * @instance
     *
     * @type {Number}
     */
    picture_id: attr('id'),

    /**
     * @memberof Myliga/UserModel
     * @instance
     *
     * @type {String}
     */
    user_type_name: attr('nullable-string'),

    /**
     * @memberof Myliga/UserModel
     * @instance
     *
     * @type {Vdc/PictureModel}
     */
    picture: belongsTo('vdc/picture'),

    /**
     * @memberof Myliga/UserModel
     * @instance
     *
     * @type {Vdc/AttributeSetModel}
     */
    attribute_set: belongsTo('vdc/attribute-set'),

    /**
     * @memberof Myliga/UserModel
     * @instance
     *
     * @type {Vdc/fragments/AttributeValueFragment[]}
     */
    attribute_values: (0, _attributes.fragmentArray)('vdc/fragments/attribute-value'),

    /**
     * @memberof Myliga/UserModel
     * @instance
     *
     * @type {String}
     * @readonly
     */
    fullName: Ember.computed('name', 'forename', function () {
      var name = this.get('name');
      var forename = this.get('forename');

      return forename + ' ' + name;
    }).readOnly()
  });
});