define('ln-ember-data-models/models/vdc/delivery-type', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr,
      Model = _emberData.default.Model;
  exports.default = Model.extend({

    /**
     * @memberof Vdc/DeliveryTypeModel
     * @instance
     *
     * @type {Number}
     */
    version: attr('number'),

    /**
     * @memberof Vdc/DeliveryTypeModel
     * @instance
     *
     * @type {Boolean}
     */
    address_required: attr('boolean', { defaultValue: function defaultValue() {
        return false;
      } }),

    /**
     * @memberof Vdc/DeliveryTypeModel
     * @instance
     *
     * @type {Number}
     */
    client_id: attr('id'),

    /**
     * @memberof Vdc/DeliveryTypeModel
     * @instance
     *
     * @type {String}
     */
    name: attr('string'),

    /**
     * @memberof Vdc/DeliveryTypeModel
     * @instance
     *
     * @type {String}
     */
    type: attr('nullable-string')
  });
});