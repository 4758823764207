define('ln-ember-contact-menu/components/contact-menu', ['exports', 'ln-ember-toolkit/utils/liga-os', 'ln-ember-contact-menu/templates/components/contact-menu', 'ln-ember-toolkit/utils/media-matcher'], function (exports, _ligaOs, _contactMenu, _mediaMatcher) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var PromiseObject = Ember.ObjectProxy.extend(Ember.PromiseProxyMixin);

  exports.default = Ember.Component.extend({
    api: Ember.inject.service(),

    store: Ember.inject.service(),

    config: Ember.computed(function () {
      var _Ember$getOwner$resol = Ember.getOwner(this).resolveRegistration('config:environment'),
          config = _Ember$getOwner$resol.APP.config;

      return Ember.Object.create(config);
    }),

    layout: _contactMenu.default,

    /**
     * Identifies user.
     * It's required if contactId is not passed.
     *
     * @type number
     */
    userId: null,

    /**
     * Identifies contact.
     * It's required if userId is not passed.
     *
     * @type number
     */
    contactId: null,

    /**
     * Action to be called on clicking a profile menu-item
     *
     * @type function
     */
    openProfile: null,

    /**
     * Holds active menu item name
     *
     * @private
     * @type string
     */
    activeItem: null,

    classNames: ['contact-menu-component'],

    classNameBindings: ['isPending', 'activeItem:has-active'],

    isPending: Ember.computed.alias('contact.isPending'),

    window: Ember.computed(function () {
      return window;
    }),

    contact: Ember.computed(function () {
      var user = this.get('userId');
      var contactId = this.get('contactId');

      return PromiseObject.create({
        promise: user ? this._loadContactByFilter(user) : this._loadContact(contactId)
      });
    }),

    title: Ember.computed('contact.{forename,name}', function () {
      return this.get('contact.forename') + ' ' + this.get('contact.name') + (this.get('userId') ? "'s Profile" : '');
    }),

    didDestroyElement: function didDestroyElement() {
      var _this = this;

      if (typeof this.onDestroyElement === 'function') {
        Ember.run.scheduleOnce('render', function () {
          return _this.onDestroyElement(_this.element);
        });
      }
    },
    noop: function noop() {
      return false;
    },
    processContact: function processContact(contact) {
      contact.forename = contact.first_name;
      contact.phone_mobile = contact.mobile_phone;
      return contact;
    },
    _loadContactByFilter: function _loadContactByFilter(userId) {
      return this.get('api').read('myliga', ['users', userId]).then(function (response) {
        return response;
      });
    },
    _loadContact: function _loadContact(id) {
      var _this2 = this;

      return this.get('api').read('myliga', ['liga3000_contacts', id]).then(function (response) {
        return _this2.processContact(response);
      });
    },


    actions: {
      openProfile: function openProfile() {
        _ligaOs.default.openResource('user', this.get('userId'));
      },
      openCall: function openCall(phoneNumber) {

        if (!phoneNumber) {
          return;
        }

        var desktopLinkPrefix = this.get('config.telephoneLinks') || 'callto';
        var proto = _mediaMatcher.default.getVersion() === 'Mobile' ? 'tel' : desktopLinkPrefix;
        var link = proto + ':' + phoneNumber.replace(/ /g, '');

        this.get('window').open(link, '_top');
      },
      activateItem: function activateItem(name) {
        this.set('activeItem', name);

        if (typeof this.didActivateItem === 'function') {
          this.didActivateItem(name);
        }

        return false;
      }
    }
  });
});