define('ln-ember-data-models/models/vdc/material-category', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr;
  exports.default = Model.extend({

    /**
     * @memberof Vdc/MaterialCategoryModel
     * @instance
     *
     * @type {Number}
     */
    version: attr('number'),

    /**
     * @memberof Vdc/MaterialCategoryModel
     * @instance
     *
     * @type {String}
     */
    name: attr('nullable-string'),

    /**
     * @memberof Vdc/MaterialCategoryModel
     * @instance
     *
     * @type {String}
     */
    description: attr('nullable-string'),

    /**
     * @memberof Vdc/MaterialCategoryModel
     * @instance
     *
     * @type {Number}
     */
    client_id: attr('id'),

    /**
     * @memberof Vdc/MaterialCategoryModel
     * @instance
     *
     * @type {Number}
     */
    parent_id: attr('id')

  });
});