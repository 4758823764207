define('ln-ember-api-service/utils/url', ['exports', 'ember-inflector'], function (exports, _emberInflector) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {

    /**
     * Example:
     *
     * ```
     * Url.hash('foo', 'bar', 1);           // => '#/foo/bar/1'
     * Url.hash(project, 'edit');           // => '#/project/13/edit'
     * Url.hash(project, article, 'edit');  // => '#/project/13/article/1/edit'
     * ```
     *
     * @memberof Url
     * @static
     *
     * @method hash
     * @return {string} url
     */
    hash: function hash() {
      var parts = this.append(['#'], arguments, false);
      return this.join(parts);
    },


    /**
     * Example:
     *
     * ```
     * Url.api('v1', 'foo', 'bar', 1);        // => '/v1/foo/bar/1'
     * Url.api('v1', project);                // => '/v1/projects/13'
     * Url.api('v1', project, 'pictures');    // => '/v1/projects/13/pictures'
     * ```
     *
     * @memberof Url
     * @static
     *
     * @method api
     * @return {string} url
     */
    api: function api() {
      var parts = this.append([''], arguments, true);
      return this.join(parts);
    },


    /**
     * Example:
     *
     * ```
     * Url.api('http://example.org', 'foo', 'bar', {v: 1});
     * // => 'http://example.org/foo/bar? v=1'
     * ```
     *
     * @memberof Url
     * @static
     * @method join
     * @param {string|Object} parts
     * @returns {string}
     */
    join: function join(parts) {

      if (Ember.typeOf(parts) !== 'array') {
        parts = Array.prototype.slice.call(arguments);
      }

      var params = void 0;

      if (Ember.typeOf(parts[parts.length - 1]) === 'object') {
        params = parts.pop();
        params = Object.keys(params).filter(function (key) {
          return !Ember.isNone(params[key]);
        }).reduce(function (data, key) {
          if (Array.isArray(params[key])) {
            data[key] = Ember.A(params[key]).uniq();
          } else {
            data[key] = params[key];
          }

          return data;
        }, {});
      }

      parts = parts.map(function (part) {
        return String(part).replace(/^\/+|\/+$/g, '');
      });

      var queryString = Ember.$.param(params || {});

      return this.replaceAllExceptFirst('' + parts.join('/') + (queryString ? '?' + queryString : ''), '?', '&');
    },


    /**
     *
     *  Example:
     *
     * ```
     * Url.replaceAllExceptFirst('url?q=q?s=s&a=a', '?', '&')
     * // =>  'url?q=q&s=s&a=a
     *
     * @memberof Url
     *
     * @param {string} input
     * @param {string} toReplace
     * @param {string} replacement
     *
     * @returns {string}
     */
    replaceAllExceptFirst: function replaceAllExceptFirst(input, toReplace, replacement) {
      var splitInput = input.split(toReplace);
      return splitInput.shift() + (splitInput.length ? toReplace + splitInput.join(replacement) : '');
    },


    /**
     * @memberof Url
     * @static
     *
     * @method append
     * @param  {Array} base
     * @param  {Array} parts
     * @param  {Boolean} shouldPluralize=false
     * @return {Array}
     */
    append: function append(base, parts) {
      var shouldPluralize = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;


      var url = [].concat(base);

      for (var i = 0, len = parts.length; i < len; i++) {
        var part = parts[i];

        if (Ember.typeOf(part) === 'instance' && (part.constructor.typeKey || part.typeKey)) {

          var key = part.constructor.typeKey || Ember.get(part, 'typeKey');

          key = Ember.String.decamelize(key);
          if (shouldPluralize) {
            key = (0, _emberInflector.pluralize)(key);
          }

          url.push(key);
          url.push(part.get('id'));
        } else {
          url.push(part);
        }
      }

      return url;
    }
  };
});