define('ln-ember-contact-menu/services/micro-menu', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    elementId: null,

    component: null,

    _isEnabled: false,

    hasActiveItem: false,

    isActive: Ember.computed('elementId', function () {
      return !!this.get('elementId');
    }),

    isEnabled: Ember.computed('_isEnabled', function () {
      return this._isEnabled;
    }),

    window: Ember.computed(function () {
      return window;
    }),

    init: function init() {
      this._super.apply(this, arguments);

      this._boundClose = this.close.bind(this);
    },
    close: function close() {
      this.set('hasActiveItem', false);
      this.set('elementId', null);
      this.set('component', null);

      this.get('window').removeEventListener('resize', this._boundClose);
    },
    open: function open(elementId, component) {
      if (this.get('_isEnabled')) {
        this.set('elementId', elementId);
        this.set('component', component);

        this.get('window').addEventListener('resize', this._boundClose);
      }
    },
    enable: function enable() {
      this.set('_isEnabled', true);
    }
  });
});