define('ln-ember-data-models/models/vdc/document', ['exports', 'ember-data', 'ember-data-model-fragments/attributes', 'ln-ember-data-models/utils/enum-option'], function (exports, _emberData, _attributes, _enumOption) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.eventGuidelineTypeOptions = exports.eventTypeOptions = exports.trainingTypeOptions = exports.kindOptions = exports.typeOptions = exports.stateOptions = undefined;
  var attr = _emberData.default.attr,
      hasMany = _emberData.default.hasMany,
      Model = _emberData.default.Model;


  /**
   * @name Vdc/DocumentModel/stateOptions
   * @type {EnumOption[]}
   */
  var stateOptions = exports.stateOptions = [_enumOption.default.create({ id: 'Proposed' }), _enumOption.default.create({ id: 'Approved' }), _enumOption.default.create({ id: 'Declined' })];

  /**
   * @name Vdc/DocumentModel/typeOptions
   * @type {EnumOption[]}
   */
  var typeOptions = exports.typeOptions = [_enumOption.default.create({ id: 'FloorPlan' }), _enumOption.default.create({ id: 'Offer' }), _enumOption.default.create({ id: 'Order' }), _enumOption.default.create({ id: 'Invoice' }), _enumOption.default.create({ id: 'Event' }), _enumOption.default.create({ id: 'Agenda' }), _enumOption.default.create({ id: 'EventGuideline' }), _enumOption.default.create({ id: 'ServiceAgreement' }), _enumOption.default.create({ id: 'Other' })];

  /**
   * @name Vdc/DocumentModel/kindOptions
   * @type {EnumOption[]}
   */
  var kindOptions = exports.kindOptions = [_enumOption.default.create({ id: 'Project' }), _enumOption.default.create({ id: 'Store' }), _enumOption.default.create({ id: 'Import' }), _enumOption.default.create({ id: 'Task' }), _enumOption.default.create({ id: 'Training' }), _enumOption.default.create({ id: 'Event' }), _enumOption.default.create({ id: 'StoreSubtype' }), _enumOption.default.create({ id: 'Client' }), _enumOption.default.create({ id: 'Article' })];

  /**
   * In case of documents related to Trainings, just these options should be available.
   *
   * @name Vdc/DocumentModel/trainingTypeOptions
   * @type {EnumOption[]}
   */
  var trainingTypeOptions = exports.trainingTypeOptions = [_enumOption.default.create({ id: 'Agenda' }), _enumOption.default.create({ id: 'Other' })];

  /**
   * In case of documents related to Events, just these options should be available.
   *
   * @name Vdc/DocumentModel/eventTypeOptions
   * @type {EnumOption[]}
   */
  var eventTypeOptions = exports.eventTypeOptions = [_enumOption.default.create({ id: 'ServiceAgreement' }), _enumOption.default.create({ id: 'Other' })];

  /**
   * In case of documents related to Events Guidelines, just this option should be available.
   *
   * @name Vdc/DocumentModel/eventGuidelineTypeOptions
   * @type {EnumOption[]}
   */
  var eventGuidelineTypeOptions = exports.eventGuidelineTypeOptions = [_enumOption.default.create({ id: 'EventGuideline' }), _enumOption.default.create({ id: 'Other' })];

  /**
   * @class Vdc/DocumentModel
   * @extends DS.Model
   */
  var DocumentModel = Model.extend({

    /**
     * @memberof Vdc/DocumentModel
     * @instance
     *
     * @type {Number}
     */
    version: attr('number'),

    /**
     * @memberof Vdc/DocumentModel
     * @instance
     *
     * @type {String}
     */
    document_name: attr('nullable-string'),

    /**
     * @memberof Vdc/DocumentModel
     * @instance
     *
     * @type {Number}
     */
    owner_id: attr('id'),

    /**
     * @memberof Vdc/DocumentModel
     * @instance
     *
     * @type {String}
     */
    kind: attr('enum-string', { options: kindOptions }),

    /**
     * @memberof Vdc/DocumentModel
     * @instance
     *
     * @type {String}
     */
    resource_state: attr('enum-string', { options: stateOptions }),

    /**
     * @memberof Vdc/DocumentModel
     * @instance
     *
     * @type {String}
     */
    language_id: attr('id'),

    /**
     * @memberof Vdc/DocumentModel
     * @instance
     *
     * @type {String}
     */
    resource_type: attr('enum-string', { options: typeOptions }),

    /**
     * @memberof Vdc/DocumentModel
     * @instance
     *
     * @type {Boolean}
     */
    post_process: attr('boolean', { defaultValue: function defaultValue() {
        return false;
      } }),
    /**
     * @memberof Vdc/DocumentModel
     * @instance
     *
     * @type {Vdc/RevisionModel[]}
     */
    revisions: hasMany('vdc/revision', { async: true }),

    /**
     * @memberof Vdc/DocumentModel
     * @instance
     *
     * @type {Vdc/fragments/AttributeValueFragment[]}
     */
    attribute_values: (0, _attributes.fragmentArray)('vdc/fragments/attribute-value')

  });

  DocumentModel.reopenClass({

    /**
     * @memberof Vdc/DocumentModel
     * @static
     *
     * @type {EnumOption[]}
     */
    kindOptions: kindOptions,

    /**
     * @memberof Vdc/DocumentModel
     * @static
     *
     * @type {EnumOption[]}
     */
    stateOptions: stateOptions,

    /**
     * @memberof Vdc/DocumentModel
     * @static
     *
     * @type {EnumOption[]}
     */
    typeOptions: typeOptions,

    /**
     * @memberof Vdc/DocumentModel
     * @static
     *
     * @type {EnumOption[]}
     */
    trainingTypeOptions: trainingTypeOptions,

    /**
     * @memberof Vdc/DocumentModel
     * @static
     *
     * @type {EnumOption[]}
     */
    eventTypeOptions: eventTypeOptions,

    /**
     * @memberof Vdc/DocumentModel
     * @static
     *
     * @type {EnumOption[]}
     */
    eventGuidelineTypeOptions: eventGuidelineTypeOptions

  });

  exports.default = DocumentModel;
});