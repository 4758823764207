define('ln-ember-data-models/models/vdc/cost-rule-condition', ['exports', 'ember-data', 'ln-ember-data-models/utils/enum-option'], function (exports, _emberData, _enumOption) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.typeOptions = undefined;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr;


  /**
   * @name Vdc/CostRuleConditionModel/typeOptions
   * @type {EnumOption[]}
   */
  var typeOptions = exports.typeOptions = [_enumOption.default.create({ id: 'Country' }), _enumOption.default.create({ id: 'Article' }), _enumOption.default.create({ id: 'EXW', name: 'Ex Works' }), _enumOption.default.create({ id: 'LocalProduction' })];

  /**
   * @class Vdc/CostRuleConditionModel
   * @extends DS.Model
   */
  var CostRuleConditionModel = Model.extend({

    /**
     * @memberof Vdc/CostRuleConditionModel
     * @instance
     *
     * @type {Number}
     */
    version: attr('number'),

    /**
     * @memberof Vdc/CostRuleConditionModel
     * @instance
     *
     * @type {String}
     */
    type: attr('enum-string', { options: typeOptions }),

    /**
     * @memberof Vdc/CostRuleConditionModel
     * @instance
     *
     * @type {transforms/number-array}
     */
    country_ids: attr('number-array', { defaultValue: function defaultValue() {
        return [];
      } }),

    /**
     * @memberof Vdc/CostRuleConditionModel
     * @instance
     *
     * @type {Array}
     */
    article_ids: attr('number-array', { defaultValue: function defaultValue() {
        return [];
      } }),

    /**
     * @memberof Vdc/CostRuleConditionModel
     * @instance
     *
     * @type {Boolean}
     */
    pickup: attr('boolean', { defaultValue: function defaultValue() {
        return false;
      } }),

    /**
     * @memberof Vdc/CostRuleConditionModel
     * @instance
     *
     * @type {Boolean}
     */
    local_production: attr('boolean', { defaultValue: function defaultValue() {
        return false;
      } }),

    /**
     * @memberof Vdc/CostRuleConditionModel
     * @instance
     *
     * @type {Number}
     */
    cost_rule_id: attr('id')

  });

  CostRuleConditionModel.reopenClass({

    /**
     * @memberof Vdc/CostRuleConditionModel
     * @static
     *
     * @type {EnumOption[]}
     */
    typeOptions: typeOptions

  });

  exports.default = CostRuleConditionModel;
});