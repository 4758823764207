define('ln-ember-data-models/models/vdc/article-attachment', ['exports', 'ember-data', 'ln-ember-data-models/utils/enum-option'], function (exports, _emberData, _enumOption) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.typeOptions = undefined;
  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      Model = _emberData.default.Model;


  /**
   * @name Vdc/ArticleAttachmentModel/typeOptions
   * @type {EnumOption[]}
   */
  var typeOptions = exports.typeOptions = [_enumOption.default.create({ id: 'FullFile' }), _enumOption.default.create({ id: 'PreviewFile' })];

  /**
   * @class Vdc/ArticleAttachmentModel
   * @extends DS.Model
   */
  var ArticleAttachmentModel = Model.extend({

    /**
     * @memberof Vdc/ArticleAttachmentModel
     * @instance
     *
     * @type {Number}
     */
    version: attr('number'),

    /**
     * @memberof Vdc/ArticleAttachmentModel
     * @instance
     *
     * @type {Number}
     */
    article_id: attr('id'),

    /**
     * @memberof Vdc/ArticleAttachmentModel
     * @instance
     *
     * @type {String}
     */
    type: attr('enum-string', { options: typeOptions }),

    /**
     * @memberof Vdc/ArticleAttachmentModel
     * @instance
     *
     * @type {Number}
     */
    resource_id: attr('id'),

    /**
     * @memberof Vdc/ArticleAttachmentModel
     * @instance
     *
     * @type {Vdc/Article}
     */
    article: belongsTo('vdc/country', { readOnly: true, async: false }),

    /**
     * @memberof Vdc/ArticleAttachmentModel
     * @instance
     *
     * @type {Vdc/Article}
     */
    resource: belongsTo('vdc/revision', { readOnly: true, async: false })

  });

  ArticleAttachmentModel.reopenClass({
    /**
     * @memberof Vdc/ArticleAttachmentModel
     * @static
     *
     * @type {EnumOption[]}
     */
    typeOptions: typeOptions
  });

  exports.default = ArticleAttachmentModel;
});