define('ln-ember-data-models/adapters/vdc/address', ['exports', 'ln-ember-data-models/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    urlForQuery: function urlForQuery(query, modelName) {
      if (query && query.shared === true) {
        return this.buildURLFor('stores/shared_addresses', null, modelName);
      }

      return this._super.apply(this, arguments);
    }
  });
});