define('ln-ember-data-models/models/vdc/rank', ['exports', 'ember-data', 'ln-ember-data-models/utils/enum-option'], function (exports, _emberData, _enumOption) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.linkTypeOptions = undefined;
  var attr = _emberData.default.attr,
      Model = _emberData.default.Model;


  /**
   * @name Vdc/RankModel/linkTypeOptions
   * @type {EnumOption[]}
   */
  var linkTypeOptions = exports.linkTypeOptions = [_enumOption.default.create({ id: 'Market' }), _enumOption.default.create({ id: 'Country' }), _enumOption.default.create({ id: 'POS' })];

  /**
   * @class Vdc/RankModel
   * @extends DS.Model
   */
  var RankModel = Model.extend({
    /**
     * @memberof Vdc/RankModel
     * @instance
     *
     * @type {Number}
     */
    version: attr('number'),

    /**
     * @memberof Vdc/RankModel
     * @instance
     *
     * @type {String}
     */
    date_created: attr('date-time-string'),

    /**
     * @memberof Vdc/RankModel
     * @instance
     *
     * @type {String}
     */
    date_changed: attr('date-time-string'),

    /**
     * @memberof Vdc/RankModel
     * @instance
     *
     * @type {String}
     */
    resource_link_type: attr('enum-string', {
      options: linkTypeOptions
    }),

    /**
     * @memberof Vdc/RankModel
     * @instance
     *
     * @type {Number}
     */
    resource_id: attr('id'),

    /**
     * @memberof Vdc/RankModel
     * @instance
     *
     * @type {Number}
     */
    rank_category_id: attr('id'),

    /**
     * @memberof Vdc/RankModel
     * @instance
     *
     * @type {String}
     */
    date_data: attr('date-time-string'),

    /**
     * @memberof Vdc/RankModel
     * @instance
     *
     * @type {Number}
     */
    rank: attr('number'),

    /**
     * @memberof Vdc/RankModel
     * @instance
     *
     * @type {Number}
     */
    kpi_value: attr('number'),

    /**
     * @memberof Vdc/RankModel
     * @instance
     *
     * @type {String}
     */
    rank_category_name: attr('nullable-string', { readOnly: true }),

    /**
     * @memberof Vdc/RankModel
     * @instance
     *
     * @type {String}
     */
    resource_name: attr('nullable-string', { readOnly: true })
  });

  RankModel.reopenClass({

    /**
     * @memberof Vdc/RankModel
     * @static
     *
     * @type {EnumOption[]}
     */
    linkTypeOptions: linkTypeOptions

  });

  exports.default = RankModel;
});