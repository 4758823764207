define('ln-ember-data-models/models/vdc/fragments/type-configuration', ['exports', 'ember-data', 'ember-data-model-fragments/fragment', 'ln-ember-data-models/utils/enum-option'], function (exports, _emberData, _fragment, _enumOption) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.subtypeOptions = exports.typeOptions = undefined;
  var attr = _emberData.default.attr;


  /**
   * @name Vdc/fragments/TypeConfigurationFragment/TypeOptions
   * @type {EnumOption[]}
   */
  var typeOptions = exports.typeOptions = [_enumOption.default.create({ id: 'StoreType' }), _enumOption.default.create({ id: 'StoreSubtype' }), _enumOption.default.create({ id: 'UserType' }), _enumOption.default.create({ id: 'TrainingType' }), _enumOption.default.create({ id: 'TrainingSubtype' }), _enumOption.default.create({ id: 'ProjectType' }), _enumOption.default.create({ id: 'EventType' })];

  /**
   * @name Vdc/fragments/TypeConfigurationFragment/SubtypeOptions
   * @type {EnumOption[]}
   */
  var subtypeOptions = exports.subtypeOptions = [_enumOption.default.create({ id: 'StoreSubtype' })];

  /**
   * @class Vdc/fragments/TypeConfigurationFragment
   * @extends Fragment
   */
  var TypeConfigurationFragment = _fragment.default.extend({

    /**
     * @memberof Vdc/fragments/TypeConfigurationFragment
     * @instance
     *
     * @type {Number}
     */
    kpi_id: attr('number'),

    /**
     * @memberof Vdc/fragments/TypeConfigurationFragment
     * @instance
     *
     * @type {String}
     */
    configuration_type: attr('enum-string', { options: typeOptions }),

    /**
     * @memberof Vdc/fragments/TypeConfigurationFragment
     * @instance
     *
     * @type {Number}
     */
    special_type_id: attr('number'),

    /**
     * @memberof Vdc/fragments/TypeConfigurationFragment
     * @instance
     *
     * @type {String}
     */
    configuration_subtype: attr('enum-string', { options: subtypeOptions }),

    /**
     * @memberof Vdc/fragments/TypeConfigurationFragment
     * @instance
     *
     * This one is a JSON Object with arbitrary keys (key is type ID, value is array of subtype ids)
     *
     * @type {String}
     */
    type_subtype_mappings: attr({ defaultValue: function defaultValue() {} }),

    /**
     * @memberof Vdc/fragments/TypeConfigurationFragment
     * @instance
     *
     * @type {Number[]}
     */
    special_subtype_ids: attr('number-array'),

    /**
     * @memberof Vdc/fragments/TypeConfigurationFragment
     * @instance
     *
     * @type {Number[]}
     */
    ids: attr('number-array')

  });

  TypeConfigurationFragment.reopenClass({

    /**
     * @memberof Vdc/fragments/TypeConfigurationFragment
     * @static
     *
     * @type {EnumOption[]}
     */
    typeOptions: typeOptions,

    /**
     * @memberof Vdc/fragments/TypeConfigurationFragment
     * @static
     *
     * @type {EnumOption[]}
     */
    subtypeOptions: subtypeOptions

  });

  exports.default = TypeConfigurationFragment;
});