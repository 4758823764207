define('ln-ember-data-models/models/vdc/fragments/additional-cost', ['exports', 'ember-data', 'ember-data-model-fragments/fragment'], function (exports, _emberData, _fragment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr;


  /**
   * @class Vdc/fragments/AdditionalCostFragment
   * @extends Fragment
   */
  var AdditionalCostFragment = _fragment.default.extend({

    /**
     * @memberof Vdc/fragments/AdditionalCostFragment
     * @instance
     *
     * @type {Number}
     */
    order_id: attr('id'),

    /**
     * @memberof Vdc/fragments/AdditionalCostFragment
     * @instance
     *
     * @type {String}
     */
    name: attr('nullable-string'),

    /**
     * @memberof Vdc/fragments/AdditionalCostFragment
     * @instance
     *
     * @type {Number}
     */
    sales_price: attr('number'),

    /**
     * @memberof Vdc/fragments/AdditionalCostFragment
     * @instance
     *
     * @type {Number}
     */
    purchase_price: attr('number')

  });

  exports.default = AdditionalCostFragment;
});