define('ln-ember-data-models/models/myliga/time-entry', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data-model-fragments/attributes', 'ln-ember-data-models/utils/enum-option'], function (exports, _model, _attr, _attributes, _enumOption) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.statusOptions = undefined;


  /**
   * @name Myliga/TimeEntryModel/statusOptions
   * @type {EnumOption[]}
   */
  var statusOptions = exports.statusOptions = [_enumOption.default.create({ id: 'Accepted' }), _enumOption.default.create({ id: 'Pending' }), _enumOption.default.create({ id: 'Declined' })];

  /**
   * @class Myliga/TimeEntryModel
   * @extends DS.Model
   */
  var TimeEntryModel = _model.default.extend({

    /**
     * @memberof Myliga/TimeEntryModel
     * @instance
     *
     * @type {Number}
     */
    calendar_week_id: (0, _attr.default)('id'),

    /**
     * @memberof Myliga/TimeEntryModel
     * @instance
     *
     * @type {String}
     */
    changed_at: (0, _attr.default)('date-time-string'),

    /**
     * @memberof Myliga/TimeEntryModel
     * @instance
     *
     * @type {Number}
     */
    changed_by_user_id: (0, _attr.default)('id'),

    /**
     * @memberof Myliga/TimeEntryModel
     * @instance
     *
     * @type {String}
     */
    comment: (0, _attr.default)('string', { defaultValue: '' }),

    /**
     * @memberof Myliga/TimeEntryModel
     * @instance
     *
     * @type {String}
     */
    created_at: (0, _attr.default)('date-time-string'),

    /**
     * @memberof Myliga/TimeEntryModel
     * @instance
     *
     * @type {Number}
     */
    created_by_user_id: (0, _attr.default)('id'),

    /**
     * @memberof Myliga/TimeEntryModel
     * @instance
     *
     * @type {Number}
     */
    department_id: (0, _attr.default)('id'),

    /**
     * @memberof Myliga/TimeEntryModel
     * @instance
     *
     * @type {String}
     */
    worklog_task_key: (0, _attr.default)('nullable-string', { defaultValue: '' }),

    /**
     * @memberof Myliga/TimeEntryModel
     * @instance
     *
     * @type {Boolean}
     */
    is_fixed_labor_cost: (0, _attr.default)('boolean'),

    /**
     * @memberof Myliga/TimeEntryModel
     * @instance
     *
     * @type {Boolean}
     */
    is_fixed_running_cost: (0, _attr.default)('boolean'),

    /**
     * @memberof Myliga/TimeEntryModel
     * @instance
     *
     * @type {Number}
     */
    labor_cost: (0, _attr.default)('number'),

    /**
     * @memberof Myliga/TimeEntryModel
     * @instance
     *
     * @type {Number}
     */
    liga3000_project_id: (0, _attr.default)('id'),

    /**
     * @memberof Myliga/TimeEntryModel
     * @instance
     *
     * @type {Number}
     */
    offered_service_id: (0, _attr.default)('id'),

    /**
     * @memberof Myliga/TimeEntryModel
     * @instance
     *
     * @type {Number}
     */
    running_cost: (0, _attr.default)('number'),

    /**
     * @memberof Myliga/TimeEntryModel
     * @instance
     *
     * @type {String}
     */
    time_from: (0, _attr.default)('date-time-string'),

    /**
     * @memberof Myliga/TimeEntryModel
     * @instance
     *
     * @type {String}
     */
    time_till: (0, _attr.default)('date-time-string'),

    /**
     * @memberof Myliga/TimeEntryModel
     * @instance
     *
     * @type {Number}
     */
    version: (0, _attr.default)('number'),

    /**
     * @memberof Myliga/TimeEntryModel
     * @instance
     *
     * @type {Array}
     */
    shared_user_ids: (0, _attr.default)('number-array', { defaultValue: function defaultValue() {
        return [];
      } }),

    /**
     * @memberof Myliga/TimeEntryModel
     * @instance
     *
     * @type {Object}
     */
    project_activation_request: (0, _attr.default)({ defaultValue: function defaultValue() {} }),

    /**
     * @memberof Myliga/TimeEntryModel
     * @instance
     *
     * @type {String}
     */
    status: (0, _attr.default)('enum-string', { options: statusOptions }),

    /**
     * @memberof Myliga/TimeEntryModel
     * @instance
     *
     * @type {Boolean}
     */
    visible: (0, _attr.default)('boolean', { defaultValue: true }),

    /**
     * @memberof Myliga/TimeEntryModel
     * @instance
     *
     * @type {Myliga/fragments/TimeEntryAssignmentOptionFragment}
     */
    assignment_option: (0, _attributes.fragment)('myliga/fragments/time-entry-assignment-option'),

    /**
     * @memberof Myliga/TimeEntryModel
     * @instance
     *
     * @type {Array}
     */
    validationErrors: Ember.computed('liga3000_project_id', 'department_id', 'offered_service_id', function () {
      var errors = [];

      if (!this.get('liga3000_project_id') && !this.get('department_id')) {
        errors.pushObject({ attribute: 'liga3000_project_id', details: Ember.String.loc('Project or department is missing.') });
      }

      if (!this.get('offered_service_id')) {
        errors.pushObject({ attribute: 'offered_service_id', details: Ember.String.loc('Job is missing.') });
      }

      return errors;
    })

  });

  TimeEntryModel.reopenClass({
    /**
     * @memberof Myliga/TimeEntryModel
     * @static
     *
     * @type {EnumOption[]}
     */
    statusOptions: statusOptions
  });

  exports.default = TimeEntryModel;
});