define("ln-ember-form-elements/templates/components/form-elements/radio-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PSBqSm8C",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"input\",true],[11,\"name\",[22,\"name\"]],[11,\"value\",[22,\"buttonValue\"]],[11,\"checked\",[22,\"checked\"]],[10,\"type\",\"radio\"],[8],[9],[0,\"\\n\"],[7,\"span\",true],[11,\"class\",[29,[\"radio-circle \",[28,\"if\",[[24,[\"checked\"]],\"selected\"],null]]]],[8],[9],[0,\"\\n\"],[7,\"span\",true],[10,\"class\",\"radio-label\"],[8],[1,[22,\"label\"],false],[9],[0,\"\\n\"],[14,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ln-ember-form-elements/templates/components/form-elements/radio-button.hbs"
    }
  });

  _exports.default = _default;
});