define('ln-ember-data-models/models/vdc/user-client-setting', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      Model = _emberData.default.Model;
  exports.default = Model.extend({

    /**
     * @memberof Vdc/UserClientSetting
     * @instance
     *
     * @type {Number}
     */
    version: attr('number'),

    /**
     * @memberof Vdc/UserClientSetting
     * @instance
     *
     * @type {Number}
     */
    user_id: attr('id'),

    /**
     * @memberof Vdc/UserClientSetting
     * @instance
     *
     * @type {Number}
     */
    client_id: attr('id'),

    /**
     * @memberof Vdc/UserClientSetting
     * @instance
     *
     * @type {Boolean}
     */
    motif_expiration: attr('boolean', { defaultValue: function defaultValue() {
        return false;
      } }),

    /**
     * @memberof Vdc/UserClientSetting
     * @instance
     *
     * @type {Boolean}
     */
    new_message: attr('boolean', { defaultValue: function defaultValue() {
        return false;
      } }),

    /**
     * @memberof Vdc/UserClientSetting
     * @instance
     *
     * @type {Boolean}
     */
    new_user: attr('boolean', { defaultValue: function defaultValue() {
        return false;
      } }),

    /**
     * @memberof Vdc/UserClientSetting
     * @instance
     *
     * @type {Boolean}
     */

    new_task: attr('boolean', { defaultValue: function defaultValue() {
        return false;
      } }),
    /**
     * @memberof Vdc/UserClientSetting
     * @instance
     *
     * @type {Boolean}
     */
    new_order: attr('boolean', { defaultValue: function defaultValue() {
        return false;
      } }),

    /**
     * @memberof Vdc/UserClientSetting
     * @instance
     *
     * @type {Boolean}
     */
    new_comment: attr('boolean', { defaultValue: function defaultValue() {
        return false;
      } }),

    /**
     * @memberof Vdc/UserClientSetting
     * @instance
     *
     * @type {Boolean}
     */
    new_store_address: attr('boolean', { defaultValue: function defaultValue() {
        return false;
      } }),

    /**
     * @memberof Vdc/UserClientSetting
     * @instance
     *
     * @type {Boolean}
     */
    ordering_deadline: attr('boolean', { defaultValue: function defaultValue() {
        return false;
      } }),

    /**
     * @memberof Vdc/UserClientSetting
     * @instance
     *
     * @type {Boolean}
     */
    update_store: attr('boolean', { defaultValue: function defaultValue() {
        return false;
      } }),

    /**
     * @memberof Vdc/UserClientSetting
     * @instance
     *
     * @type {Boolean}
     */
    update_store_address: attr('boolean', { defaultValue: function defaultValue() {
        return false;
      } }),

    /**
     * @memberof Vdc/UserClientSetting
     * @instance
     *
     * @type {Boolean}
     */
    update_task: attr('boolean', { defaultValue: function defaultValue() {
        return false;
      } }),

    /**
     * @memberof Vdc/UserClientSetting
     * @instance
     *
     * @type {Boolean}
     */
    start_task: attr('boolean', { defaultValue: function defaultValue() {
        return false;
      } }),

    /**
     * @memberof Vdc/UserClientSetting
     * @instance
     *
     * @type {Boolean}
     */
    update_assembly_comment: attr('boolean', { defaultValue: function defaultValue() {
        return false;
      } }),

    /**
     * @memberof Vdc/UserClientSetting
     * @instance
     *
     * @type {Boolean}
     */
    new_fixture: attr('boolean', { defaultValue: function defaultValue() {
        return false;
      } }),

    /**
     * @memberof Vdc/UserClientSetting
     * @instance
     *
     * @type {Boolean}
     */
    update_fixture: attr('boolean', { defaultValue: function defaultValue() {
        return false;
      } }),

    /**
     * @memberof Vdc/UserClientSetting
     * @instance
     *
     * @type {Boolean}
     */
    new_window: attr('boolean', { defaultValue: function defaultValue() {
        return false;
      } }),

    /**
     * @memberof Vdc/UserClientSetting
     * @instance
     *
     * @type {Boolean}
     */
    update_window: attr('boolean', { defaultValue: function defaultValue() {
        return false;
      } }),

    /**
     * @memberof Vdc/UserClientSetting
     * @instance
     *
     * @type {Boolean}
     */
    new_instore_area: attr('boolean', { defaultValue: function defaultValue() {
        return false;
      } }),

    /**
     * @memberof Vdc/UserClientSetting
     * @instance
     *
     * @type {Boolean}
     */
    update_instore_area: attr('boolean', { defaultValue: function defaultValue() {
        return false;
      } }),

    /**
     * @memberof Vdc/UserClientSetting
     * @instance
     *
     * @type {Boolean}
     */
    contract_end: attr('boolean', { defaultValue: function defaultValue() {
        return false;
      } }),

    /**
     * @memberof Vdc/UserClientSetting
     * @instance
     *
     * @type {Boolean}
     */
    send_email_for_communicator_notifications: attr('boolean', { defaultValue: function defaultValue() {
        return false;
      } }),

    /**
     * @memberof Vdc/UserClientSetting
     * @instance
     *
     * @type {Boolean}
     */
    batch_notifications: attr('boolean', { defaultValue: function defaultValue() {
        return false;
      } }),

    /**
     * @memberof Vdc/UserClientSetting
     * @instance
     *
     * @type {Boolean}
     */
    article_review_creation: attr('boolean', { defaultValue: function defaultValue() {
        return false;
      } }),

    /**
     * @memberof Vdc/UserClientSetting
     * @instance
     *
     * @type {Boolean}
     */
    translations_ready_for_article_review: attr('boolean', { defaultValue: function defaultValue() {
        return false;
      } }),

    /**
     * @memberof Vdc/UserClientSetting
     * @instance
     *
     * @type {Vdc/UserModel}
     */
    user: belongsTo('vdc/user'),

    /**
     * @memberof Vdc/UserClientSetting
     * @instance
     *
     * @type {Vdc/ClientModel}
     */
    client: belongsTo('vdc/client')
  });
});