define('ln-ember-contact-menu/components/ember-tether', ['exports', 'ember-tether/components/ember-tether'], function (exports, _emberTether) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberTether.default.extend({

    registerToParent: function registerToParent() {},

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.registerToParent(this);
    }
  });
});