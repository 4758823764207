define("ln-ember-toolkit/utils/cache-promise", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = cachePromise;
  var queryToPromiseMap = {};

  function cachePromise(identifier, handler) {
    if (queryToPromiseMap[identifier]) {
      return queryToPromiseMap[identifier];
    }

    var promise = handler();
    queryToPromiseMap[identifier] = promise;
    promise.finally(function () {
      return delete queryToPromiseMap[identifier];
    });
    return promise;
  }
});