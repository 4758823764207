define('ln-ember-data-models/models/vdc/contract', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr,
      Model = _emberData.default.Model;
  exports.default = Model.extend({

    /**
     * @memberof Vdc/Contract
     * @instance
     *
     * @type {Number}
     */
    version: attr('number'),

    /**
     * @memberof Vdc/Contract
     * @instance
     *
     * @type {String}
     */
    contact_person: attr('nullable-string'),

    /**
     * @memberof Vdc/Contract
     * @instance
     *
     * @type {String}
     */
    start_date: attr('date-string'),

    /**
     * @memberof Vdc/Contract
     * @instance
     *
     * @type {String}
     */
    end_date: attr('date-string'),

    /**
     * @memberof Vdc/Contract
     * @instance
     *
     * @type {Number}
     */
    store_id: attr('id')

  });
});