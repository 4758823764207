define('ln-ember-api-service/services/api', ['exports', 'ln-ember-api-service/utils/url', 'ln-ember-api-service/utils/normalize-error'], function (exports, _url, _normalizeError) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  exports.default = Ember.Service.extend({

    apiEvents: Ember.inject.service(),

    config: null,

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      var apiEvents = this.get('apiEvents');

      this.set('config', apiEvents.getConfig());
      apiEvents.on('config-change', function (config) {
        _this.set('config', config);
      });

      this.set('session', apiEvents.getSession());
      apiEvents.on('session-change', function (session) {
        _this.set('session', session);
      });

      this.set('language', apiEvents.getLanguage());
      apiEvents.on('language-change', function (language) {
        _this.set('language', language);
      });
    },


    /**
     * ```javascript
     * this.get('api').read('vdc', 'stores')
     *   .then(function() { console.log('\o/'); })
     *   .catch(function() { console.log('/o\'); });
     * ```
     * ```javascript
     * this.get('api').read('myliga', ['pins', id, 'likes'])
     *   .then(function() { console.log('\o/'); })
     *   .catch(function() { console.log('/o\'); });
     * ```
     *
     * @memberof ApiService
     * @instance
     *
     * @method read
     * @param  {String} namespace
     * @param  {String|String[]} uri
     * @return {Ember.RSVP.Promise}
     */
    read: function read(namespace, uri) {
      return this.request({
        url: this.buildUrl(namespace, uri),
        method: 'GET'
      });
    },


    /**
     * ```javascript
     * this.get('api').create('vdc', ['/stores', {name: 'test'}])
     *   .then(function() { console.log('\o/'); })
     *   .catch(function() { console.log('/o\'); });
     * ```
     *
     * @memberof ApiService
     * @instance
     *
     * @method create
     * @param  {String} namespace
     * @param  {String|String[]} uri
     * @param  {Object} data
     * @param  {Object} options
     * @returns {Ember.RSVP.Promise}
     */
    create: function create(namespace, uri, data) {
      var options = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
      (false && !(Ember.typeOf(namespace) === 'string') && Ember.assert('create(): namespace needs to be a string', Ember.typeOf(namespace) === 'string'));


      options = Ember.$.extend({
        // defaults (can be overridden)
        contentType: 'application/json'
      }, options);

      options.url = this.buildUrl(namespace, uri);
      options.method = 'POST';
      options.data = this.encodeData(data, options.contentType);

      return this.request(options);
    },


    /**
     * ```javascript
     * this.get('api').update('vdc', ['/stores', {name: 'test'}])
     *   .then(function() { console.log('\o/'); })
     *   .catch(function() { console.log('/o\'); });
     * ```
     *
     * @memberof ApiService
     * @instance
     *
     * @method put
     * @param  {String} namespace
     * @param  {String|String[]} uri
     * @param  {Object} data
     * @param  {Object} options
     * @return {Ember.RSVP.Promise}
     */
    update: function update(namespace, uri, data) {
      var options = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};

      options = Ember.$.extend({
        // defaults (can be overridden)
        contentType: 'application/json'
      }, options);

      options.url = this.buildUrl(namespace, uri);
      options.method = 'PUT';
      options.data = this.encodeData(data, options.contentType);

      return this.request(options);
    },


    /**
     * ```javascript
     * this.get('api').partialUpdate('vdc', ['/stores', { name: 'test' }])
     *   .then(() => console.log('Success'))
     *   .catch((error) => console.error(error.message));
     * ```
     *
     * @memberof ApiService
     * @instance
     *
     * @method patch
     * @param  {String} namespace
     * @param  {String|String[]} uri
     * @param  {Object} data
     * @param  {Object} options
     * @return {Ember.RSVP.Promise}
     */
    partialUpdate: function partialUpdate(namespace, uri, data) {
      var options = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};

      options = Ember.$.extend({
        // defaults (can be overridden)
        contentType: 'application/json'
      }, options);

      options.url = this.buildUrl(namespace, uri);
      options.method = 'PATCH';
      options.data = this.encodeData(data, options.contentType);

      return this.request(options);
    },


    /**
     * ```javascript
     * this.get('api').delete('vdc', ['stores', 1])
     *   .then(function() { console.log('\o/'); })
     *   .catch(function() { console.log('/o\'); });
     * ```
     *
     * @memberof ApiService
     * @instance
     *
     * @method delete
     * @param  {String} namespace
     * @param  {String|String[]} uri
     * @param  {Object} data
     * @param  {Object} options
     * @return {Ember.RSVP.Promise}
     */
    delete: function _delete(namespace, uri, data) {
      var options = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};

      var requestOptions = {
        url: this.buildUrl(namespace, uri),
        method: 'DELETE'
      };

      if ((typeof data === 'undefined' ? 'undefined' : _typeof(data)) === 'object') {
        requestOptions = Ember.$.extend({
          // defaults (can be overridden)
          contentType: 'application/json'
        }, requestOptions, options);
        requestOptions.data = this.encodeData(data, requestOptions.contentType);
      }

      return this.request(requestOptions);
    },


    /**
     * ```javascript
     * this.get('api').copy('vdc', ['stores'])
     *   .then(function() { console.log('\o/'); })
     *   .catch(function() { console.log('/o\'); });
     * ```
     *
     * @memberof ApiService
     * @instance
     *
     * @method copy
     * @param  {String} namespace
     * @param  {String|String[]} uri
     * @return {Ember.RSVP.Promise}
     */
    copy: function copy(namespace, uri) {
      return this.request({
        url: this.buildUrl(namespace, uri),
        method: 'COPY'
      });
    },


    /**
     * @memberof ApiService
     * @instance
     *
     * @private
     * @method encodeData
     * @param  {Object} data
     * @param  {String|String[]} contentType
     * @return {Object}
     */
    encodeData: function encodeData(data, contentType) {

      if (contentType === 'application/json') {
        return JSON.stringify(data);
      } else if (contentType === 'multipart/form-data') {
        var formData = new FormData();

        Object.keys(data).forEach(function (key) {
          var value = data[key];

          if (value instanceof File) {
            formData.append(key, value, value.name);
          } else {
            formData.append(key, value);
          }
        });

        return formData;
      }

      return data;
    },


    /**
     * @memberof ApiService
     * @instance
     *
     * @private
     * @method headers
     * @param  {String} contentType
     * @return {object}
     */
    headers: function headers() {
      var contentType = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'application/json';

      var headers = {
        'Accept': 'application/json',
        'Accept-Language': this.get('language')
      };

      var token = this.get('session.token');

      if (token) {
        headers.Authorization = 'Bearer ' + token;
      }

      if (contentType === 'application/json') {
        contentType += '; charset=UTF-8';
      }

      if (contentType) {
        headers['Content-Type'] = contentType;
      }

      return headers;
    },


    /**
     * @memberof ApiService
     * @instance
     *
     * @private
     * @method buildUrl
     * @param  {String} namespace
     * @param  {String|String[]} uri
     * @return {Array}
     */
    buildUrl: function buildUrl(namespace, uri) {
      if (Ember.typeOf(uri) === 'array') {
        uri = _url.default.api.apply(_url.default, _toConsumableArray(uri));
      }

      (false && !(Ember.typeOf(uri) === 'string') && Ember.assert('[ln-ember-api-service] buildUrl() uri needs to be a string', Ember.typeOf(uri) === 'string'));
      (false && !(Ember.typeOf(namespace) === 'string') && Ember.assert('[ln-ember-api-service] buildUrl() namespace needs to be a string', Ember.typeOf(namespace) === 'string'));
      (false && !(this.namespaceExists(namespace)) && Ember.assert('[ln-ember-api-service] buildUrl() namespace "' + namespace + '" does not exits for Url  "' + uri + '"', this.namespaceExists(namespace)));


      if (!uri.match(/^\//)) {
        uri = '/' + uri;
      }

      var baseUrl = this.baseUrlForNamespace(namespace);

      return '' + baseUrl + uri;
    },


    /**
     * @memberof ApiService
     * @instance
     *
     * @private
     * @method request
     * @param  {object} options
     * @return {Object|Ember.RSVP.Promise}
     */
    request: function request(options) {
      this.requestOptionsFor(options);

      var data = this.getRequestedData(options);

      return Ember.assign(data.promise, {
        abort: function abort() {
          return data.xhr.abort();
        }
      });
    },


    /**
     * @memberof ApiService
     * @instance
     *
     * @private
     * @method getRequestedData
     * @param  {object} options
     * @return {Object|Ember.RSVP.Promise}
     */
    getRequestedData: function getRequestedData(options) {
      var _this2 = this;

      var xhr = void 0;

      return {
        promise: new Ember.RSVP.Promise(function (resolve, reject) {
          xhr = Ember.$.ajax(Ember.$.extend({}, options, {
            success: function success(resp) {
              return resolve(resp);
            },
            error: function error(response) {
              if (xhr.statusText === 'abort') {
                // Resolve is here, because we don't need an error/failure message
                // and it's empty because we don't have something for aborted request
                resolve();

                return;
              }

              _this2.get('apiEvents').triggerErrorResponse(response);

              var payload = Ember.get(response, 'responseJSON') || Ember.get(response, 'responseText');
              reject((0, _normalizeError.default)(Ember.get(response, 'status'), payload));
            }
          }));

          return xhr;
        }),
        xhr: xhr
      };
    },


    /**
     * @memberof ApiService
     * @instance
     *
     * @private
     * @method requestOptionsFor
     * @param  {Object} options
     * @return {Object}
     */
    requestOptionsFor: function requestOptionsFor(options) {
      if (options.contentType === 'multipart/form-data') {
        // Content-Type Header including the boundary is set automatically by
        // jQuery / FormData.
        options.contentType = false;
      }

      Ember.$.extend(options, {
        dataType: 'json',
        headers: this.headers(options.contentType),
        processData: options.contentType === 'application/json'
      });

      if (options.progress && typeof options.progress === 'function') {
        Ember.$.extend(options, {
          xhr: function xhr() {
            var myXhr = Ember.$.ajaxSettings.xhr();
            if (myXhr.upload) {
              myXhr.upload.addEventListener('progress', function () {
                options.progress.apply(options, arguments);
              }, false);
            }

            return myXhr;
          }
        });
      }

      return options;
    },


    /**
     * @memberof ApiService
     * @instance
     *
     * @private
     * @method baseUrlForNamespace
     * @param  {String} namespace
     * @return {String}
     */
    baseUrlForNamespace: function baseUrlForNamespace(namespace) {
      var _get = this.get('config.api-' + namespace),
          url = _get.url,
          version = _get.version;

      return url + '/' + version;
    },
    namespaceExists: function namespaceExists(namespace) {
      var _ref = this.get('config.api-' + namespace) || {},
          _ref$url = _ref.url,
          url = _ref$url === undefined ? null : _ref$url,
          _ref$version = _ref.version,
          version = _ref$version === undefined ? null : _ref$version;

      return !Ember.isNone(url) && !Ember.isNone(version);
    }
  });
});