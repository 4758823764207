define('ln-ember-data-models/adapters/myliga/event-participant', ['exports', 'ln-ember-data-models/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({

    api: Ember.inject.service(),

    buildURL: function buildURL(modelName, id, snapshot, requestType, query) {
      var pathOnly = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : false;

      var url = [];
      var host = Ember.get(this, 'host');
      var prefix = this.urlPrefix();

      if (snapshot) {
        url.push('events');
        url.push(snapshot.attr('event_id'));
      }

      url.push('participants');

      if (id) {
        url.push(encodeURIComponent(id));
      }

      if (prefix) {
        url.unshift(prefix);
      }

      url = url.join('/');
      if (!host && url && url.charAt(0) !== '/') {
        url = '/' + url;
      }

      var baseUrl = '';
      if (!pathOnly) {
        baseUrl = this.baseUrlFor(modelName);
      }

      return '' + baseUrl + url;
    }
  });
});