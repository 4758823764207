define("ln-ember-contact-menu/templates/components/micro-menu-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "no1PZ9tH",
    "block": "{\"symbols\":[],\"statements\":[[7,\"a\",true],[11,\"href\",[22,\"href\"]],[11,\"target\",[22,\"target\"]],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"isFake\"]]],null,{\"statements\":[[0,\"    \"],[7,\"i\",true],[11,\"class\",[29,[\"micro-menu--icon \",[28,\"if\",[[24,[\"hasIcon\"]],\"fake-icon\"],null]]]],[8],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"i\",true],[11,\"class\",[29,[\"micro-menu--icon \",[22,\"name\"],\"-icon\"]]],[8],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n  \"],[7,\"section\",true],[8],[0,\"\\n    \"],[7,\"h4\",true],[10,\"class\",\"micro-menu-item--title\"],[11,\"title\",[22,\"titleTooltip\"]],[8],[0,\"\\n      \"],[4,\"unless\",[[24,[\"isFake\"]]],null,{\"statements\":[[1,[22,\"title\"],false]],\"parameters\":[]},null],[0,\"\\n    \"],[9],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"description\"]]],null,{\"statements\":[[0,\"      \"],[7,\"p\",true],[10,\"class\",\"micro-menu-item--description\"],[11,\"title\",[22,\"descriptionTooltip\"]],[8],[0,\"\\n        \"],[4,\"unless\",[[24,[\"isFake\"]]],null,{\"statements\":[[1,[22,\"description\"],false]],\"parameters\":[]},null],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ln-ember-contact-menu/templates/components/micro-menu-item.hbs"
    }
  });

  _exports.default = _default;
});