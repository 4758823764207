define("ln-ember-form-elements/templates/components/form-elements/option-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vdF3PmqP",
    "block": "{\"symbols\":[\"option\"],\"statements\":[[4,\"if\",[[24,[\"label\"]]],null,{\"statements\":[[7,\"label\",true],[11,\"for\",[22,\"elementId\"]],[8],[1,[22,\"label\"],false],[9]],\"parameters\":[]},null],[0,\"\\n\\n\"],[4,\"each\",[[24,[\"renderOptions\"]]],null,{\"statements\":[[4,\"form-elements/styled-button\",null,[[\"theme\",\"action\"],[[28,\"if\",[[23,1,[\"active\"]],[24,[\"highlightTheme\"]],[24,[\"theme\"]]],null],[28,\"action\",[[23,0,[]],\"setValue\",[23,1,[]]],null]]],{\"statements\":[[0,\"    \"],[1,[23,1,[\"label\"]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ln-ember-form-elements/templates/components/form-elements/option-button.hbs"
    }
  });

  _exports.default = _default;
});