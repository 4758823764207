define("ln-ember-form-elements/components/form-elements/styled-checkbox", ["exports", "ln-ember-form-elements/templates/components/form-elements/styled-checkbox"], function (_exports, _styledCheckbox) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   *
   * Renders an styled checkbox that behaves like a regular checkbox with a label.<br>
   *
   * ### Examples:
   *
   * ```Handlebars
   * {{form-elements/styled-checkbox label="I am checked!" value=true}}
   * ```
   *
   * @class formElements/StyledCheckboxComponent
   * @extends @ember/component
   */
  var _default = Ember.Component.extend({
    layout: _styledCheckbox.default,

    /**
     * Label of the checkbox
     *
     * @memberof formElements/StyledCheckboxComponent
     * @instance
     *
     * @type string
     * @default null
     */
    label: null,

    /**
     * State of the checkbox
     *
     * @memberof formElements/StyledCheckboxComponent
     * @instance
     *
     * @deprecated
     *
     * @type boolean
     * @default false
     */
    checked: null,

    /**
     * State of the checkbox
     *
     * @memberof formElements/StyledCheckboxComponent
     * @instance
     *
     * @type boolean
     * @default false
     */
    value: Ember.computed('checked', {
      get: function get() {
        this.get('checked');
      }
    }),

    /**
     * @memberof formElements/StyledCheckboxComponent
     * @instance
     *
     * @type String
     * @default 'light'
     */
    theme: null,

    /**
     * Change action handler.
     *
     * ### Example:**
     *
     * ```JavaScript
     * Controller.extend({
     *   actions: {
     *     change(value) {
     *       console.log('Checkbox is checked:', value);
     *     }
     *   }
     * })
     * ```
     *
     * ```Handlebars
     * {{form-elements/styled-checkbox onChange=(action "change"}}
     * ```
     *
     *
     * @memberof formElements/StyledCheckboxComponent
     * @instance
     *
     * @type {function}
     */
    onChange: null,

    /**
     * If set to true the input field is readonly
     *
     * @memberof StyledInputComponent
     * @instance
     *
     * @type Boolean
     * @default false
     */
    readonly: false,
    classNames: [// Deprecated: { id: ln-ember-form-elements-class-names, until: 3.0.0 }
    'styled-checkbox-component', 'form-elements--styled-checkbox-component'],
    classNameBindings: ['themeClass', 'inputValue:checked:unchecked', 'readonlyAttribute:read-only'],
    // Can't be false... See: https://github.com/emberjs/ember.js/issues/11828
    readonlyAttribute: Ember.computed('readonly', 'disabled', function () {
      return Boolean(this.get('readonly') || this.get('disabled')) || undefined;
    }),
    formElements: Ember.inject.service(),
    inputValue: Ember.computed('value', {
      get: function get() {
        return this.get('value');
      },
      set: function set(prop, value) {
        (false && !(Boolean(this.get('onChange'))) && Ember.deprecate('[StyledCheckboxComponent] Data binding is deprecated. onChange action should be handled.', Boolean(this.get('onChange')), {
          id: 'styled-checkbox-component-data-binding',
          until: '3.0.0'
        }));

        if (this.get('onChange')) {
          this.get('onChange')(value);
        } else {
          this.set('checked', value);
        }

        return value;
      }
    }),
    checkboxId: Ember.computed(function () {
      return "checkbox-".concat(Ember.guidFor(this));
    }),
    themeClass: Ember.computed('theme', function () {
      return "theme-".concat(this.get('theme') || 'light');
    }),
    init: function init() {
      this._super.apply(this, arguments);

      if (!this.get('theme')) {
        this.set('theme', this.get('formElements.defaults.theme'));
      }
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      (false && !(Ember.isNone(this.get('checked'))) && Ember.deprecate('[StyledCheckboxComponent] The attribute `checked` is deprecated use `value` instead.', Ember.isNone(this.get('checked')), {
        id: 'styled-checkbox-component-checked-attribute',
        until: '3.0.0'
      }));
    }
  });

  _exports.default = _default;
});