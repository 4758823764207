define('ln-ember-data-models/adapters/vdc/quality-audit-position', ['exports', 'ln-ember-data-models/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    buildURL: function buildURL(modelName, id, snapshot, requestType, query) {
      var path = void 0,
          qaAuditId = void 0;

      if (snapshot && snapshot.record) {
        qaAuditId = Ember.get(snapshot.record, 'quality_audit_id');
      } else if (query.quality_audit_id) {
        qaAuditId = query.quality_audit_id;
        delete query.quality_audit_id;
      }

      var url = [this.baseUrlFor(modelName)];

      if (modelName) {
        path = 'quality_audits/' + qaAuditId + '/quality_audit_positions';
        url.push(path);
      }

      if (id) {
        url.push(encodeURIComponent(id));
      }

      url = url.join('/');

      return url;
    }
  });
});