define("ln-ember-form-elements/templates/components/form-elements/styled-checkbox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "petXyBTB",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"input\",null,[[\"type\",\"checked\",\"id\",\"disabled\"],[\"checkbox\",[24,[\"inputValue\"]],[24,[\"checkboxId\"]],[24,[\"readonlyAttribute\"]]]]],false],[0,\"\\n\"],[7,\"label\",true],[11,\"for\",[22,\"checkboxId\"]],[8],[1,[22,\"label\"],false],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ln-ember-form-elements/templates/components/form-elements/styled-checkbox.hbs"
    }
  });

  _exports.default = _default;
});