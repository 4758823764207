define('ln-ember-data-models/models/vdc/return-position', ['exports', 'ember-data', 'ln-ember-data-models/utils/enum-option'], function (exports, _emberData, _enumOption) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.reasonOptions = undefined;
  var attr = _emberData.default.attr,
      hasMany = _emberData.default.hasMany,
      Model = _emberData.default.Model;


  /**
   * @name Vdc/ReturnPositionModel/reasonOptions
   * @type {EnumOption[]}
   */
  var reasonOptions = exports.reasonOptions = [_enumOption.default.create({ id: 'QualityProblems' })];

  /**
   * @class Vdc/ReturnPositionModel
   * @extends DS.Model
   */
  var ReturnPositionModel = Model.extend({

    /**
     * @memberof Vdc/ReturnPositionModel
     * @instance
     *
     * @type {Number}
     */
    version: attr('number'),

    /**
     * @memberof Vdc/ReturnPositionModel
     * @instance
     *
     * @type {String}
     */
    date_created: attr('date-time-string'),

    /**
     * @memberof Vdc/ReturnPositionModel
     * @instance
     *
     * @type {Number}
     */
    return_id: attr('id'),

    /**
     * @memberof Vdc/ReturnPositionModel
     * @instance
     *
     * @type {Number}
     */
    order_position_id: attr('id'),

    /**
     * @memberof Vdc/ReturnPositionModel
     * @instance
     *
     * @type {Object}
     */
    product: attr(),

    /**
     * @memberof Vdc/ReturnPositionModel
     * @instance
     *
     * @type {String}
     */
    reason: attr('enum-string', { options: reasonOptions }),

    /**
     * @memberof Vdc/ReturnPositionModel
     * @instance
     *
     * @type {Number}
     */
    amount: attr('number', { defaultValue: 0 }),

    /**
     * @memberof Vdc/ReturnPositionModel
     * @instance
     *
     * @type {Vdc/PictureModel[]}
     */
    pictures: hasMany('vdc/picture')

  });

  ReturnPositionModel.reopenClass({

    /**
     * @memberof Vdc/ReturnPositionModel
     * @static
     *
     * @type {EnumOption[]}
     */
    reasonOptions: reasonOptions

  });

  exports.default = ReturnPositionModel;
});