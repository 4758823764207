define('ln-ember-data-models/models/vdc/assembly', ['exports', 'ember-data', 'ln-ember-data-models/utils/enum-option'], function (exports, _emberData, _enumOption) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.statusOptions = undefined;
  var attr = _emberData.default.attr,
      hasMany = _emberData.default.hasMany,
      belongsTo = _emberData.default.belongsTo,
      Model = _emberData.default.Model;


  /**
   * @name Vdc/AssemblyModel/statusOptions
   * @type {EnumOption[]}
   */
  var statusOptions = exports.statusOptions = [_enumOption.default.create({ id: 'Assembled' }), _enumOption.default.create({ id: 'Approved' }), _enumOption.default.create({ id: 'NotApproved' }), _enumOption.default.create({ id: 'Estimated' }), _enumOption.default.create({ id: 'AdaptWithComments' }), _enumOption.default.create({ id: 'Revised' }), _enumOption.default.create({ id: 'Ordered' }), _enumOption.default.create({ id: 'Proposal' })];

  /**
   * @class Vdc/AssemblyModel
   * @extends DS.Model
   */
  var AssemblyModel = Model.extend({

    /**
     * @memberof Vdc/AssemblyModel
     * @instance
     *
     * @type {Number}
     */
    version: attr('number'),

    /**
     * @memberof Vdc/AssemblyModel
     * @instance
     *
     * @type {String}
     */
    comment: attr('nullable-string'),

    /**
     * @memberof Vdc/AssemblyModel
     * @instance
     *
     * @type {Number}
     */
    project_id: attr('id'),

    /**
     * @memberof Vdc/AssemblyModel
     * @instance
     *
     * @type {String}
     */
    desired_delivery_date: attr('date-string'),

    /**
     * @memberof Vdc/AssemblyModel
     * @instance
     *
     * @type {String}
     */
    start_date: attr('date-string'),

    /**
     * @memberof Vdc/AssemblyModel
     * @instance
     *
     * @type {String}
     */
    end_date: attr('date-string'),

    /**
     * @memberof Vdc/AssemblyModel
     * @instance
     *
     * @type {Number}
     */
    language1: attr('id'),

    /**
     * @memberof Vdc/AssemblyModel
     * @instance
     *
     * @type {Number}
     */
    language2: attr('id'),

    /**
     * @memberof Vdc/AssemblyModel
     * @instance
     *
     * @type {String}
     */
    go_out: attr('date-time-string'),

    /**
     * @memberof Vdc/AssemblyModel
     * @instance
     *
     * @type {String}
     */
    instore: attr('date-time-string'),

    /**
     * @memberof Vdc/AssemblyModel
     * @instance
     *
     * @type {String}
     */
    target_name: attr('nullable-string'),

    /**
     * @memberof Vdc/AssemblyModel
     * @instance
     *
     * @type {String}
     */
    status: attr('enum-string', {
      options: statusOptions,
      defaultValue: 'Assembled'
    }),

    /**
     * @memberof Vdc/AssemblyModel
     * @instance
     *
     * @type {Number}
     */
    shipping_cost: attr('number'),

    /**
     * @memberof Vdc/AssemblyModel
     * @instance
     *
     * @type {Number}
     */
    store_id: attr('id'),

    /**
     * @memberof Vdc/AssemblyModel
     * @instance
     *
     * @type {Number}
     */
    window_id: attr('id'),

    /**
     * @memberof Vdc/AssemblyModel
     * @instance
     *
     * @type {Number}
     */
    instore_area_id: attr('id'),

    /**
     * @memberof Vdc/AssemblyModel
     * @instance
     *
     * @type {Number}
     */
    fixture_id: attr('id'),

    /**
     * @memberof Vdc/AssemblyModel
     * @instance
     *
     * @type {Number}
     */
    delivery_type_id: attr('id'),

    /**
     * @memberof Vdc/AssemblyModel
     * @instance
     *
     * @type {Object}
     */
    delivery_type: attr(),

    /**
     * @memberof Vdc/AssemblyModel
     * @instance
     *
     * @type {Number}
     */
    sales_price: attr('number'),

    /**
     * @memberof Vdc/AssemblyModel
     * @instance
     *
     * @type {Number}
     */
    purchase_price: attr('number'),

    /**
     * @memberof Vdc/AssemblyModel
     * @instance
     *
     * @type {Number}
     */
    motif_expiration_date: attr('date-string'),

    /**
     * @memberof Vdc/AssemblyModel
     * @instance
     *
     * @type {String}
     */
    project_name: attr('nullable-string'),

    /**
     * @memberof Vdc/AssemblyModel
     * @instance
     *
     * @type {Vdc/AssemblyPositionModel[]}
     */
    assembly_positions: hasMany('vdc/assembly-position', { async: true }),

    /**
     * @memberof Vdc/AssemblyModel
     * @instance
     *
     * @type {Vdc/PictureModel}
     */
    print_picture: belongsTo('vdc/picture')

  });

  AssemblyModel.reopenClass({

    /**
     * @memberof Vdc/AssemblyModel
     * @static
     *
     * @type {EnumOption[]}
     */
    statusOptions: statusOptions

  });

  exports.default = AssemblyModel;
});