define('ln-ember-data-models/models/vdc/annotation', ['exports', 'ember-data', 'ln-ember-data-models/utils/enum-option'], function (exports, _emberData, _enumOption) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.typeOptions = undefined;
  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      Model = _emberData.default.Model;


  /**
   * @memberof Vdc/AnnotationModel/typeOptions
   * @type {EnumOption[]}
   */
  var typeOptions = exports.typeOptions = [_enumOption.default.create({ id: 'Comment' }), _enumOption.default.create({ id: 'Drawing' })];

  /**
   * @class Vdc/AnnotationModel
   * @extends DS.Model
   */
  var AnnotationModel = Model.extend({

    /**
     * @memberof Vdc/AnnotationModel
     * @instance
     *
     * @type {Number}
     */
    version: attr('number'),

    /**
     * @memberof Vdc/AnnotationModel
     * @instance
     *
     * @type {Number}
     */
    author_id: attr('id'),

    /**
     * @memberof Vdc/AnnotationModel
     * @instance
     *
     * @type {String}
     */
    message: attr('nullable-string'),

    /**
     * @memberof Vdc/AnnotationModel
     * @instance
     *
     * @type {Object}
     */
    properties: attr(),

    /**
     * @memberof Vdc/AnnotationModel
     * @instance
     *
     * @type {String}
     */
    annotation_type: attr('enum-string', { options: typeOptions }),

    /**
     * @memberof Vdc/AnnotationModel
     * @instance
     *
     * @type {Number}
     */
    position: attr('number'),

    /**
     * @memberof Vdc/AnnotationModel
     * @instance
     *
     * @type {Number}
     */
    resource_id: attr('id'),

    /**
     * @memberof Vdc/AnnotationModel
     * @instance
     *
     * @type {String}
     */
    date_created: attr('date-time-string'),

    /**
     * @memberof Vdc/AnnotationModel
     * @instance
     *
     * @type {String}
     */
    date_updated: attr('date-time-string'),

    /**
     * @memberof Vdc/AnnotationModel
     * @instance
     *
     * @type {Vdc/UserModel}
     */
    author: belongsTo('vdc/user'),

    isComment: Ember.computed.equal('annotation_type', 'Comment'),
    isDrawing: Ember.computed.equal('annotation_type', 'Drawing')
  });

  AnnotationModel.reopen({
    /**
     * @memberof Vdc/AnnotationModel
     * @static
     *
     * @type {EnumOption[]}
     */
    typeOptions: typeOptions
  });

  exports.default = AnnotationModel;
});