define('ln-ember-data-models/models/vdc/right-group-entry', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr,
      Model = _emberData.default.Model;
  exports.default = Model.extend({

    /**
     * @memberof Vdc/RightGroupEntryModel
     * @instance
     *
     * @type {Number}
     */
    version: attr('number'),

    /**
     * @memberof Vdc/RightGroupEntryModel
     * @instance
     *
     * @type {Boolean}
     */
    hierarchy_topdown: attr('boolean', { defaultValue: function defaultValue() {
        return false;
      } }),

    /**
     * @memberof Vdc/RightGroupEntryModel
     * @instance
     *
     * @type {Array}
     */
    restricted_object_ids: attr('number-array', { defaultValue: function defaultValue() {
        return [];
      } }),

    /**
     * @memberof Vdc/RightGroupEntryModel
     * @instance
     *
     * @type {Number}
     */
    right_group_id: attr('id'),

    /**
     * @memberof Vdc/RightGroupEntryModel
     * @instance
     *
     * @type {Number}
     */
    right_id: attr('id')
  });
});