define('ln-ember-data-models/models/myliga/talk-module', ['exports', 'ember-data', 'ln-ember-data-models/utils/enum-option'], function (exports, _emberData, _enumOption) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.typeOptions = undefined;
  var attr = _emberData.default.attr,
      Model = _emberData.default.Model;


  /**
   * @name Myliga/TalkModuleModel/typeOptions
   * @type {EnumOption[]}
   */
  var typeOptions = exports.typeOptions = [_enumOption.default.create({ id: 'Expectation' }), _enumOption.default.create({ id: 'Review' }), _enumOption.default.create({ id: 'GlobalFeedback' }), _enumOption.default.create({ id: 'Performance' }), _enumOption.default.create({ id: 'Competence' }), _enumOption.default.create({ id: 'Experience' }), _enumOption.default.create({ id: 'Motivation' }), _enumOption.default.create({ id: 'Teamwork' }), _enumOption.default.create({ id: 'Potential' }), _enumOption.default.create({ id: 'LiganovaFIT' }), _enumOption.default.create({ id: 'PersonalSuccessFeedback' }), _enumOption.default.create({ id: 'YourProfile' }), _enumOption.default.create({ id: 'AdditionalNotes' })];

  /**
   * @class Myliga/TalkModuleModel
   * @extends DS.Model
   */
  var TalkModuleModel = Model.extend({

    /**
     * @memberof Myliga/TalkModuleModel
     * @instance
     *
     * @type {Number}
     */
    version: attr('number'),

    /**
     * @memberof Myliga/TalkModuleModel
     * @instance
     *
     * @type {String}
     */
    name: attr('nullable-string'),

    /**
     * @memberof Myliga/TalkModuleModel
     * @instance
     *
     * @type {Number}
     */
    parent_id: attr('id'),

    /**
     * @memberof Myliga/TalkModuleModel
     * @instance
     *
     * @type {String}
     */
    type: attr('enum-string', { options: typeOptions })

  });

  TalkModuleModel.reopenClass({

    /**
     * @memberof Myliga/TalkModuleModel
     * @static
     *
     * @type {EnumOption[]}
     */
    typeOptions: typeOptions

  });

  exports.default = TalkModuleModel;
});