define('ln-ember-data-models/models/myliga/objective-component', ['exports', 'ember-data', 'ln-ember-data-models/utils/enum-option'], function (exports, _emberData, _enumOption) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.typeOptions = undefined;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr;


  /**
   * @name Myliga/ObjectiveComponent/typeOptions
   * @type {EnumOption[]}
   */
  var typeOptions = exports.typeOptions = [_enumOption.default.create({ id: 'MinimumAccountProfitPercentage' }), _enumOption.default.create({ id: 'MinimumAccountSalesEuro' }), _enumOption.default.create({ id: 'Miscellaneous' })];

  /**
   * @class Myliga/ObjectiveComponent
   * @extends DS.Model
   */
  var ObjectiveComponentModel = Model.extend({

    /**
     * @memberof Myliga/ObjectiveComponent
     * @instance
     *
     * @type {Number}
     */
    version: attr('number'),

    /**
     * @memberof Myliga/ObjectiveComponent
     * @instance
     *
     * @type {String}
     */
    type: attr('enum-string', { options: typeOptions }),

    /**
     * @memberof Myliga/ObjectiveComponent
     * @instance
     *
     * @type {String}
     */
    title: attr('nullable-string'),

    /**
     * @memberof Myliga/ObjectiveComponent
     * @instance
     *
     * @type {String}
     */
    description: attr('nullable-string'),

    /**
     * @memberof Myliga/ObjectiveComponent
     * @instance
     *
     * @type {Number}
     */
    previous_value: attr('number', { defaultValue: 0 }),

    /**
     * @memberof Myliga/ObjectiveComponent
     * @instance
     *
     * @type {Number}
     */
    actual_value: attr('number', { defaultValue: 0 }),

    /**
     * @memberof Myliga/ObjectiveComponent
     * @instance
     *
     * @type {Number}
     */
    target_value: attr('number', { defaultValue: 0 }),

    /**
     * Must be beween 0 - 100
     *
     * @memberof Myliga/ObjectiveComponent
     * @instance
     *
     * @type {Number}
     */
    percentage_achieved: attr('number', { defaultValue: 0 }),

    /**
     * Must be beween 0 - 100
     *
     * @memberof Myliga/ObjectiveComponent
     * @instance
     *
     * @type {Number}
     */
    objective_id: attr('id')

  });

  ObjectiveComponentModel.reopenClass({

    /**
     * @memberof Myliga/ObjectiveComponent
     * @static
     *
     * @type {EnumOption[]}
     */
    typeOptions: typeOptions

  });

  exports.default = ObjectiveComponentModel;
});