define('ln-ember-data-models/models/vdc/supplier-info', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr,
      Model = _emberData.default.Model;
  exports.default = Model.extend({

    /**
     * @memberof Vdc/SupplierInfoModel
     * @instance
     *
     * @type {Boolean}
     */
    force_preferred_supplier: attr('boolean', { defaultValue: false }),

    /**
     * @memberof Vdc/SupplierInfoModel
     * @instance
     *
     * @type {String} It's a UUID
     */
    /* eslint-disable ligadigital/ember-model-custom-types */
    supplier_id: attr('nullable-string'),

    /**
     * @memberof Vdc/SupplierInfoModel
     * @instance
     *
     * @type {String} It's a UUID
     */
    /* eslint-disable ligadigital/ember-model-custom-types */
    preferred_supplier_id: attr('nullable-string')
  });
});