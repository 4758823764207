define('ln-ember-data-models/models/vdc/event-category', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr,
      Model = _emberData.default.Model;


  /**
   * @class Vdc/EventCategoryModel
   * @extends DS.Model
   */
  var EventCategoryModel = Model.extend({

    /**
     * @memberof Vdc/EventCategoryModel
     * @instance
     *
     * @type {Number}
     */
    version: attr('number'),

    /**
     * @memberof Vdc/EventCategoryModel
     * @instance
     *
     * @type {String}
     */
    name: attr('nullable-string'),

    /**
     * @memberof Vdc/EventCategoryModel
     * @instance
     *
     * @type {Number}
     */
    client_id: attr('id'),

    /**
     * @memberof Vdc/EventCategoryModel
     * @instance
     *
     * @type {Number}
     */
    attribute_set_id: attr('id'),

    /**
     * @memberof Vdc/EventCategoryModel
     * @instance
     *
     * @type {Array}
     */
    attribute_ids: attr('number-array', { defaultValue: function defaultValue() {
        return [];
      } }),

    /**
     * @memberof Vdc/EventCategoryModel
     * @instance
     *
     * @type {Number}
     */
    position: attr('number')

  });

  exports.default = EventCategoryModel;
});