define('ln-ember-data-models/models/myliga/talk', ['exports', 'ember-data', 'ln-ember-data-models/utils/enum-option', 'ember-data-model-fragments/attributes'], function (exports, _emberData, _enumOption, _attributes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.statusOptions = undefined;
  var attr = _emberData.default.attr,
      Model = _emberData.default.Model;


  /**
   * @name Myliga/TalkModel/statusOptions
   * @type {EnumOption[]}
   */
  var statusOptions = exports.statusOptions = [_enumOption.default.create({ id: 'InProgress' }), _enumOption.default.create({ id: 'Complete' })];

  /**
   * @class Myliga/TalkModel
   * @extends DS.Model
   */
  var TalkModel = Model.extend({

    /**
     * @memberof Myliga/TalkModel
     * @instance
     *
     * @type {Number}
     */
    version: attr('number'),

    /**
     * @memberof Myliga/TalkModel
     * @instance
     *
     * @type {Number}
     */
    user_id: attr('id'),

    /**
     * @memberof Myliga/TalkModel
     * @instance
     *
     * @type {Number}
     */
    jobprofile_id: attr('id'),

    /**
     * @memberof Myliga/TalkModel
     * @instance
     *
     * @type {Number}
     */
    superior_user_id: attr('id'),

    /**
     * @memberof Myliga/TalkModel
     * @instance
     *
     * @type {Number[]}
     */
    participant_user_ids: attr('number-array', { defaultValue: function defaultValue() {
        return [];
      } }),

    /**
     * @memberof Myliga/TalkModel
     * @instance
     *
     * @type {String}
     */
    appointment_date: attr('date-time-string'),

    /**
     * @memberof Myliga/TalkModel
     * @instance
     *
     * @type {String}
     */
    closed_at: attr('date-time-string'),

    /**
     * @memberof Myliga/TalkModel
     * @instance
     *
     * @type {Number}
     */
    talk_type_id: attr('id'),

    /**
     * @memberof Myliga/TalkModel
     * @instance
     *
     * @type {String}
     */
    talk_type_name: attr('nullable-string'),

    /**
     * @memberof Myliga/TalkModel
     * @instance
     *
     * @type {String}
     */
    title: attr('string'),

    /**
     * @memberof Myliga/TalkModel
     * @instance
     *
     * @type {Boolean}
     */
    superior_feedback_visible: attr('boolean'),

    /**
     * @memberof Myliga/TalkModel
     * @instance
     *
     * @type {Boolean}
     */
    user_feedback_visible: attr('boolean'),

    /**
     * @memberof Myliga/TalkModel
     * @instance
     *
     * @type {Number[]}
     */
    talk_module_ids: attr('number-array', { defaultValue: function defaultValue() {
        return [];
      } }),

    /**
     * @memberof Myliga/TalkModel
     * @instance
     *
     * @type {Number}
     */
    feedback_year: attr('number'),

    /**
     * @memberof Myliga/TalkModel
     * @instance
     *
     * @type {String}
     */
    status: attr('enum-string', { options: statusOptions }),

    /**
     * @memberof Myliga/TalkModel
     * @instance
     *
     * @type {Object[]}
     */
    participant_user_infos: attr({ defaultValue: function defaultValue() {
        return [];
      } }),

    /**
     * @memberof Myliga/TalkModel
     * @instance
     *
     * @type {Object[]}
     */
    talk_feedbacks: attr({ defaultValue: function defaultValue() {
        return [];
      } }),
    /**
     * @memberof Myliga/TalkModel
     * @instance
     *
     * @type {MyLiga/fragments/UserMeta}
     */
    user_info: (0, _attributes.fragment)('myliga/fragments/user-meta'),

    /**
     * @memberof Myliga/TalkModel
     * @instance
     * @type {MyLiga/fragments/UserMeta}
     */
    superior_user_info: (0, _attributes.fragment)('myliga/fragments/user-meta')

  });

  TalkModel.reopenClass({

    /**
     * @memberof Myliga/TalkModel
     * @static
     *
     * @type {EnumOption[]}
     */
    statusOptions: statusOptions

  });

  exports.default = TalkModel;
});