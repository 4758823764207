define("ln-ember-toolkit/utils/regexp", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.replaceAll = replaceAll;
  _exports.escapeExpStr = escapeExpStr;

  /**
   * @module RegExp
   */

  /**
   * ```
   * import { replaceAll } from 'ln-ember-toolkit/utils/regexp';
   *
   * const str = replaceAll('abc', {
   *   '^a': '1',
   *   'c$': '3'
   * });
   * // => "1c3"
   *
   * ```
   *
   * @function
   * @param  {string} str
   * @param  {object} replaces
   * @return {string}
   */
  function replaceAll(str, replaces) {
    Object.keys(replaces).forEach(function (find) {
      str = str.replace(new RegExp(find, 'g'), replaces[find]);
    });
    return str;
  }
  /**
   * @function
   * @param  {string} str
   * @return {string}
   */


  function escapeExpStr(str) {
    return str && str.replace(/[-[\]/{}()*+?.\\^$|]/g, '\\$&');
  }
});