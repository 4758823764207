define('ln-ember-data-models/serializers/myliga/user-workload', ['exports', 'ln-ember-data-models/serializers/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    extractId: function extractId(_modelClass, resourceHash) {
      var date_from = resourceHash.date_from,
          date_till = resourceHash.date_till,
          user_id = resourceHash.user_id;


      return date_from + '#' + date_till + '#' + user_id;
    }
  });
});