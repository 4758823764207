define('ln-ember-data-models/models/myliga/personal-talent', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr,
      Model = _emberData.default.Model;
  exports.default = Model.extend({

    /**
     * @memberof Myliga/PersonalTalentModel
     * @instance
     *
     * @type {number}
     */
    version: attr('number'),

    /**
     * @memberof Myliga/PersonalTalentModel
     * @instance
     *
     * @type {number}
     */
    user_id: attr('id'),

    /**
     * @memberof Myliga/PersonalTalentModel
     * @instance
     *
     * @type {number}
     */
    talent_id: attr('id'),

    /**
     * @memberof Myliga/PersonalTalentModel
     * @instance
     *
     * @type {number}
     */
    talent_level: attr('number'),

    /**
     * @memberof Myliga/PersonalTalentModel
     * @instance
     *
     * @type {string}
     */
    text: attr('nullable-string'),

    /**
     * @memberof Myliga/PersonalTalentModel
     * @instance
     *
     * @type {number}
     */
    ranking: attr('number'),

    /**
     * @memberof Myliga/PersonalTalentModel
     * @instance
     *
     * @type {string}
     */
    talent_name: attr('nullable-string'),

    /**
     * @memberof Myliga/PersonalTalentModel
     * @instance
     *
     * @type {string}
     */
    time_created: attr('date-time-string', { readOnly: true }),

    /**
     * @memberof Myliga/PersonalTalentModel
     * @instance
     *
     * @type {number}
     */
    employee_id: attr('id')

  });
});