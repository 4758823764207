define('ln-ember-data-models/serializers/vdc/article-attribute', ['exports', 'ln-ember-data-models/serializers/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({

    get attrs() {
      return {
        attribute_options: {
          serialize: false,
          deserialize: 'records'
        }
      };
    },

    buildRelationshipLink: function buildRelationshipLink(key, relationshipMeta, modelClass, resourceHash) {
      if (key === 'attribute_options') {
        var baseUrl = this.baseUrlFor(modelClass.modelName);
        var linkKey = this.keyForLink(key, relationshipMeta.kind);

        return baseUrl + '/' + linkKey + '?attribute=' + resourceHash.id;
      }

      return this._super.apply(this, arguments);
    }
  });
});