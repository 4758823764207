define('ln-ember-data-models/models/myliga/like', ['exports', 'ember-data', 'ember-data-model-fragments/attributes'], function (exports, _emberData, _attributes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr;
  exports.default = Model.extend({

    /**
     * @memberof Myliga/LikeModel
     * @instance
     *
     * @type {Number}
     */
    version: attr('number'),

    /**
     * @memberof Myliga/LikeModel
     * @instance
     *
     * @type {String}
     */
    forename: attr('nullable-string'),

    /**
     * @memberof Myliga/LikeModel
     * @instance
     *
     * @type {String}
     */
    name: attr('nullable-string'),

    /**
     * @memberof Myliga/LikeModel
     * @instance
     *
     * @type {MyLiga/fragments/PictureMeta}
     */
    picture: (0, _attributes.fragment)('myliga/fragments/picture'),

    /**
     * @memberof Myliga/LikeModel
     * @instance
     *
     * @type {String}
     */
    fullName: Ember.computed('forename', 'name', function () {
      return [this.get('name'), this.get('forename')].filter(function (el) {
        return el;
      }).join(', ');
    })
  });
});