define('ln-ember-data-models/models/vdc/fragments/export-document', ['exports', 'ember-data', 'ember-data-model-fragments/fragment'], function (exports, _emberData, _fragment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr;
  exports.default = _fragment.default.extend({

    /**
     * @memberof Vdc/fragments/ExportDocumentFragment
     * @instance
     *
     * @type {String}
     */
    invoice_type: attr('nullable-string'),

    /**
     * @memberof Vdc/fragments/ExportDocumentFragment
     * @instance
     *
     * @type {Boolean}
     */
    eur1_certificate: attr('boolean', { defaultValue: function defaultValue() {
        return false;
      } }),

    /**
     * @memberof Vdc/fragments/ExportDocumentFragment
     * @instance
     *
     * @type {Boolean}
     */
    certificate_of_origin: attr('boolean', { defaultValue: function defaultValue() {
        return false;
      } })

  });
});