define('ln-ember-data-models/models/vdc/client', ['exports', 'ember-data', 'ln-ember-data-models/utils/enum-option'], function (exports, _emberData, _enumOption) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.headerColorOptions = undefined;
  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany,
      Model = _emberData.default.Model;


  /**
   * @name Vdc/ClientModel/headerColorOptions
   * @type {EnumOption[]}
   */
  var headerColorOptions = exports.headerColorOptions = [_enumOption.default.create({ id: 'White' }), _enumOption.default.create({ id: 'Black' })];

  /**
   * @class Vdc/ClientModel
   * @extends DS.Model
   */
  exports.default = Model.extend({

    /**
     * @memberof Vdc/ClientModel
     * @instance
     *
     * @type {Number}
     */
    version: attr('number'),

    /**
     * @memberof Vdc/ClientModel
     * @instance
     *
     * @type {String}
     */
    name: attr('nullable-string'),

    /**
     * @memberof Vdc/ClientModel
     * @instance
     *
     * @type {Number}
     */
    parent_id: attr('id'),

    /**
     * @memberof Vdc/ClientModel
     * @instance
     *
     * @type {Number}
     */
    icon_id: attr('id'),

    /**
     * @memberof Vdc/ClientModel
     * @instance
     *
     * @type {String}
     */
    icon_url: attr('nullable-string'),

    /**
     * @memberof Vdc/ClientModel
     * @instance
     *
     * @type {Array}
     */
    application_ids: attr('number-array', { defaultValue: function defaultValue() {
        return [];
      } }),

    /**
     * @memberof Vdc/ClientModel
     * @instance
     *
     * @type {Number}
     */
    user_id: attr('id'),

    /**
     * @memberof Vdc/ClientModel
     * @instance
     *
     * @type {Boolean}
     */
    active: attr('boolean', { defaultValue: function defaultValue() {
        return true;
      } }),

    /**
     * @memberof Vdc/ClientModel
     * @instance
     *
     * @type {Boolean}
     */
    show_calendar: attr('boolean', { defaultValue: function defaultValue() {
        return false;
      } }),

    /**
     * @memberof Vdc/ClientModel
     * @instance
     *
     * @type {Boolean}
     */
    show_timeline: attr('boolean', { defaultValue: function defaultValue() {
        return false;
      } }),

    /**
     * @memberof Vdc/ClientModel
     * @instance
     *
     * @type {Number}
     */
    address_id: attr('id'),

    /**
     * @memberof Vdc/ClientModel
     * @instance
     *
     * @type {Object}
     */
    address: attr(),

    /**
     * @memberof Vdc/ClientModel
     * @instance
     *
     * @type {String}
     */
    domain: attr('nullable-string'),

    /**
     * @memberof Vdc/ClientModel
     * @instance
     *
     * @type {Number}
     */
    motif_exp_warning: attr('number'),

    /**
     * @memberof Vdc/ClientModel
     * @instance
     *
     * @type {Number}
     */
    order_deadline_warning: attr('number'),

    /**
     * @memberof Vdc/ClientModel
     * @instance
     *
     * @type {Number}
     */
    password_expiration: attr('number'),

    /**
     * @memberof Vdc/ClientModel
     * @instance
     *
     * @type {Number}
     */
    password_expiration_leeway: attr('number'),

    /**
     * @memberof Vdc/ClientModel
     * @instance
     *
     * @type {Number}
     */
    store_update_notification_interval: attr('number'),

    /**
     * @memberof Vdc/ClientModel
     * @instance
     *
     * @type {Number}
     */
    contract_end_notification_period: attr('number'),

    /**
     * @memberof Vdc/ClientModel
     * @instance
     *
     * @type {String}
     */
    login_retry_exceeded_email: attr('nullable-string'),

    /**
     * @memberof Vdc/ClientModel
     * @instance
     *
     * @type {String}
     */
    email_sender_name: attr('nullable-string'),

    /**
     * @memberof Vdc/ClientModel
     * @instance
     *
     * @type {String}
     */
    email_sending_address: attr('nullable-string'),

    /**
     * @memberof Vdc/ClientModel
     * @instance
     *
     * @type {String}
     */
    email_support_address: attr('nullable-string'),

    /**
     * @memberof Vdc/ClientModel
     * @instance
     *
     * @type {Boolean}
     */
    calculate_last_post_update: attr('boolean', { defaultValue: function defaultValue() {
        return false;
      } }),

    /**
     * @memberof Vdc/ClientModel
     * @instance
     *
     * @type {Boolean}
     */
    automatic_delivery_number_generation: attr('boolean', { defaultValue: function defaultValue() {
        return false;
      } }),

    /**
     * @memberof Vdc/ClientModel
     * @instance
     *
     * @type {Boolean}
     */
    allow_only_eligible_trainable_users_to_be_assigned_to_store: attr('boolean', { defaultValue: function defaultValue() {
        return false;
      } }),

    /**
     * @memberof Vdc/ClientModel
     * @instance
     *
     * @type {Boolean}
     */
    show_market_filter_in_navigation_bar: attr('boolean', { defaultValue: function defaultValue() {
        return false;
      } }),

    /**
     * @memberof Vdc/ClientModel
     * @instance
     *
     * @type {String}
     */
    header_color: attr('enum-string', { options: headerColorOptions }),

    /**
     * @memberof Vdc/ClientModel
     * @instance
     *
     * @type {Number}
     */
    background_id: attr('id'),

    /**
     * @memberof Vdc/ClientModel
     * @instance
     *
     * @type {String}
     */
    background_url: attr('nullable-string'),

    /**
     * @memberof Vdc/ClientModel
     * @instance
     *
     * @type {String}
     */
    client_identifier: attr('nullable-string'),

    /**
     * @memberof Vdc/ClientModel
     * @instance
     *
     * @type {String}
     */
    customer_group_id: attr('id'),

    /**
     * @memberof Vdc/ClientModel
     * @instance
     *
     * @type {Boolean}
     */
    calculate_last_pos_update: attr('boolean', { defaultValue: function defaultValue() {
        return false;
      } }),

    /**
     * @memberof Vdc/ClientModel
     * @instance
     *
     * @type {Number}
     */
    default_store_type_id: attr('id'),

    /**
     * @memberof Vdc/ClientModel
     * @instance
     *
     * @type {Object}
     */
    user: belongsTo('vdc/user'),

    /**
     * @memberof Vdc/ClientModel
     * @instance
     *
     * @type {Vdc/ClientModel[]}
     */
    children: hasMany('vdc/client', { async: false, inverse: null })
  });
});