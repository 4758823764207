define("ln-ember-contact-menu/templates/components/micro-menu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "J7M3aVrr",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"microMenu\",\"isActive\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"micro-menu--overlay\"],[10,\"tabindex\",\"0\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"closeMicroMenu\"],null]],[8],[9],[0,\"\\n\\n\"],[4,\"ember-tether\",null,[[\"class\",\"classPrefix\",\"target\",\"attachment\",\"targetAttachment\",\"constraints\",\"bodyElement\",\"registerToParent\"],[[28,\"concat\",[\"micro-menu--container\",[28,\"if\",[[24,[\"isNorthern\"]],\" is-northern\",\"\"],null]],null],\"micro-menu\",[24,[\"targetElementId\"]],[24,[\"attachement\"]],[24,[\"targetAttachement\"]],[24,[\"tetherConstraints\"]],[24,[\"element\"]],[28,\"action\",[[23,0,[]],\"register\"],null]]],{\"statements\":[[0,\"    \"],[1,[28,\"component\",[[24,[\"component\"]]],[[\"didActivateItem\"],[[28,\"action\",[[23,0,[]],\"didActivateItem\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ln-ember-contact-menu/templates/components/micro-menu.hbs"
    }
  });

  _exports.default = _default;
});