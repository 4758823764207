define("ln-ember-form-elements/services/flash-messages", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @typedef {object} FlashMessage
   * @property {number} id
   * @property {string} type
   * @property {string} text
   */

  /**
   * The `FlashMessagesService` is used to show flash messages.
   *
   * There three types of messages:
   *
   * - `error`
   * - `warning`
   * - `success`
   *
   * ### Examples:
   *
   * Add a message
   *
   * ```JavaScript
   * const message = this.get('flashMessages').addErrorMessage('Unexpected error!')
   * ```
   * ```JavaScript
   * const message = this.get('flashMessages').addWarningMessage('Opps, this should not happen!')
   * ```
   * ```JavaScript
   * const message = this.get('flashMessages').addSuccessMessage('You are fine, don't worry!')
   * ```
   *
   * Remove a specific message:
   *
   * ```JavaScript
   * this.get('flashMessages').removeMessage(message)
   * ```
   *
   * Remove all messages:
   *
   * ```JavaScript
   * this.get('flashMessages').removeAllMessages()
   * ```
   *
   *
   *
   * @class FlashMessagesService
   * @extends Ember.Service
   */
  var _default = Ember.Service.extend({
    /**
     * @memberof FlashMessagesService
     * @instance
     *
     * @private
     * @type {number}
     */
    _uid: 0,

    /**
     * @memberof FlashMessagesService
     * @instance
     *
     * @private
     * @type {array}
     */
    messages: null,
    formElements: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      this.set('messages', []);
    },

    /**
     * @memberof FlashMessagesService
     * @instance
     *
     * @private
     *
     * @param {string} type
     * @param {string} text
     *
     * @return {FlashMessage}
     */
    addMessage: function addMessage(type, text) {
      var _this = this;

      var messageTypes = ['success', 'warning', 'error'];
      (false && !(messageTypes.indexOf(type) !== -1) && Ember.assert("A flash-message has to be one of the following types: ".concat(messageTypes.join(', ')), messageTypes.indexOf(type) !== -1));
      var id = this.incrementProperty('_uid');
      var message = {
        id: id,
        type: type,
        text: text
      };
      Ember.A(this.get('messages')).addObject(message);
      var defaultsKey = Ember.String.camelize("remove-".concat(type, "-after"));
      var timeout = this.get("formElements.defaults.".concat(defaultsKey));

      if (!Ember.isNone(timeout)) {
        Ember.run.later(function () {
          return _this.removeMessage(message);
        }, timeout);
      }

      return message;
    },

    /**
     * @memberof FlashMessagesService
     * @instance
     *
     * @param {string} message
     *
     * @return {FlashMessage}
     */
    addSuccessMessage: function addSuccessMessage(message) {
      return this.addMessage('success', message);
    },

    /**
     * @memberof FlashMessagesService
     * @instance
     *
     * @param {string} message
     *
     * @return {FlashMessage}
     */
    addWarningMessage: function addWarningMessage(message) {
      return this.addMessage('warning', message);
    },

    /**
     * @memberof FlashMessagesService
     * @instance
     *
     * @param {string} message
     *
     * @return {FlashMessage}
     */
    addErrorMessage: function addErrorMessage(message) {
      return this.addMessage('error', message);
    },

    /**
     * @memberof FlashMessagesService
     * @instance
     *
     * @param {object|string} error
     *
     * @return {FlashMessage[]}
     */
    addErrorMessages: function addErrorMessages(error) {
      var _this2 = this;

      if (typeof error === 'string') {
        return [this.addErrorMessage(error)];
      }

      var errorDetails = Ember.get(error, 'errors') || Ember.get(error, 'details') || [];
      var messagesCount = this.get('messages.length');

      if (Ember.get(error, 'message')) {
        this.addErrorMessage(Ember.get(error, 'message'));
      } else {
        Ember.debug(error);
      }

      errorDetails.forEach(function (detail) {
        if (Ember.get(detail, 'message')) {
          _this2.addErrorMessage(Ember.get(detail, 'message'));
        } else {
          Ember.debug(detail);
        }
      });
      return this.get('messages').slice(messagesCount);
    },

    /**
     * @memberof FlashMessagesService
     * @instance
     *
     * @param {object} message
     */
    removeMessage: function removeMessage(message) {
      Ember.A(this.get('messages')).removeObject(message);
    },

    /**
     * @memberof FlashMessagesService
     * @instance
     */
    removeAllMessages: function removeAllMessages() {
      this.set('messages', []);
    }
  });

  _exports.default = _default;
});