define('ln-ember-data-models/models/myliga/talent', ['exports', 'ember-data', 'ln-ember-data-models/utils/enum-option', 'ember-data-model-fragments/attributes'], function (exports, _emberData, _enumOption, _attributes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.typeOptions = undefined;
  var attr = _emberData.default.attr,
      Model = _emberData.default.Model;


  /**
   * @name Myliga/TalentModel/typeOptions
   * @type {EnumOption[]}
   */
  var typeOptions = exports.typeOptions = [_enumOption.default.create({ id: 'Competence' }), _enumOption.default.create({ id: 'Skill' })];

  /**
   * @class Myliga/TalentModel
   * @extends DS.Model
   */
  var TalentModel = Model.extend({

    /**
     * @memberof Myliga/TalentModel
     * @instance
     *
     * @type {Number}
     */
    version: attr('number'),

    /**
     * @memberof Myliga/TalentModel
     * @instance
     *
     * @type {String}
     */
    name: attr('nullable-string'),

    /**
     * @memberof Myliga/TalentModel
     * @instance
     *
     * @type {Number}
     */
    category_id: attr('id'),

    /**
     * @memberof Myliga/TalentModel
     * @instance
     *
     * @type {String}
     */
    type: attr('enum-string', { options: typeOptions }),

    /**
     * @memberof Myliga/TalentModel
     * @instance
     *
     * @type {String}
     */
    description: attr('nullable-string'),

    /**
     * @memberof Myliga/TalentModel
     * @instance
     *
     * @type {Boolean}
     */
    active: attr('boolean'),

    /**
     * @memberof Myliga/TalentModel
     * @instance
     *
     * @type {Myliga/fragments/TalentLevelFragment[]}
     */
    levels: (0, _attributes.fragmentArray)('myliga/fragments/talent-level')

  });

  TalentModel.reopenClass({

    /**
     * @memberof Myliga/TalentModel
     * @static
     *
     * @type {EnumOption[]}
     */
    typeOptions: typeOptions

  });

  exports.default = TalentModel;
});