define('ln-ember-data-models/models/myliga/fragments/jobprofile-objective-setting', ['exports', 'ember-data', 'ember-data-model-fragments/fragment', 'ln-ember-data-models/utils/enum-option'], function (exports, _emberData, _fragment, _enumOption) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.bonusPaymentTypeOptions = undefined;
  var attr = _emberData.default.attr;


  /**
   * @name Myliga/JobprofileModel/bonusPaymentTypeOptions
   * @type {EnumOption[]}
   */
  var bonusPaymentTypeOptions = exports.bonusPaymentTypeOptions = [_enumOption.default.create({ id: 'Miles' }), _enumOption.default.create({ id: 'EUR' }), _enumOption.default.create({ id: 'MonthlySalary' })];

  /**
   * @class Myliga/JobprofileObjectiveSettingFragment
   * @extends Fragment
   */
  var JobprofileObjectiveSettingFragment = _fragment.default.extend({

    /**
     * @memberof Myliga/JobprofileObjectiveSettingFragment
     * @instance
     *
     * @type {String}
     */
    bonus_payment_type: attr('enum-string', { options: bonusPaymentTypeOptions }),

    /**
     * @memberof Myliga/JobprofileObjectiveSettingFragment
     * @instance
     *
     * @type {Number}
     */
    max_bonus_payment: attr('number'),

    /**
     * @memberof Myliga/JobprofileObjectiveSettingFragment
     * @instance
     *
     * @type {Boolean}
     */
    objective_enabled: attr('boolean')
  });

  JobprofileObjectiveSettingFragment.reopenClass({

    /**
     * @memberof Myliga/JobprofileModel
     * @static
     *
     * @type {EnumOption[]}
     */
    bonusPaymentTypeOptions: bonusPaymentTypeOptions

  });

  exports.default = JobprofileObjectiveSettingFragment;
});