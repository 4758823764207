define('ln-ember-data-models/models/vdc/project', ['exports', 'ember-data', 'ln-ember-data-models/utils/enum-option', 'ember-data-model-fragments/attributes'], function (exports, _emberData, _enumOption, _attributes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.progressOptions = exports.statusOptions = undefined;
  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany,
      Model = _emberData.default.Model;


  /**
   * @name Vdc/ProjectModel/statusOptions
   * @type {EnumOption[]}
   */
  var statusOptions = exports.statusOptions = [_enumOption.default.create({ id: 'NotStarted' }), _enumOption.default.create({ id: 'Ongoing' }), _enumOption.default.create({ id: 'Completed' }), _enumOption.default.create({ id: 'OnHold' }), _enumOption.default.create({ id: 'Canceled' }), _enumOption.default.create({ id: 'Archived' })];

  /**
   * @name Vdc/ProjectModel/progressOptions
   * @type {EnumOption[]}
   */
  var progressOptions = exports.progressOptions = [_enumOption.default.create({ id: 'White' }), _enumOption.default.create({ id: 'Green' }), _enumOption.default.create({ id: 'Orange' }), _enumOption.default.create({ id: 'Red' })];

  /**
   * @class Vdc/ProjectModel
   * @extends DS.Model
   */
  var ProjectModel = Model.extend({

    /**
     * @memberof Vdc/ProjectModel
     * @instance
     *
     * @type {Number}
     */
    version: attr('number'),

    /**
     * @memberof Vdc/ProjectModel
     * @instance
     *
     * @type {String}
     */
    name: attr('nullable-string'),

    /**
     * @memberof Vdc/ProjectModel
     * @instance
     *
     * @type {Number}
     */
    client_id: attr('id'),

    /**
     * @memberof Vdc/ProjectModel
     * @instance
     *
     * @type {String}
     */
    number: attr('nullable-string'),

    /**
     * @memberof Vdc/ProjectModel
     * @instance
     *
     * @type {String}
     */
    article_prefix: attr('nullable-string'),

    /**
     * @memberof Vdc/ProjectModel
     * @instance
     *
     * @type {String}
     */
    assembly_mode: attr('nullable-string'),

    /**
     * @memberof Vdc/ProjectModel
     * @instance
     *
     * @type {String}
     */
    run_start: attr('date-string', { timeOption: 'start' }),

    /**
     * @memberof Vdc/ProjectModel
     * @instance
     *
     * @type {String}
     */
    run_end: attr('date-string', { timeOption: 'end' }),

    /**
     * @memberof Vdc/ProjectModel
     * @instance
     *
     * @type {String}
     */
    ordering_start: attr('date-string', { timeOption: 'start' }),

    /**
     * @memberof Vdc/ProjectModel
     * @instance
     *
     * @type {String}
     */
    ordering_end: attr('date-string', { timeOption: 'end' }),

    /**
     * @memberof Vdc/ProjectModel
     * @instance
     *
     * @type {String}
     */
    estimated_ordering_start: attr('date-string', { timeOption: 'start' }),

    /**
     * @memberof Vdc/ProjectModel
     * @instance
     *
     * @type {String}
     */
    estimated_ordering_end: attr('date-string', { timeOption: 'end' }),

    /**
     * @memberof Vdc/ProjectModel
     * @instance
     *
     * @type {String}
     */
    definitive_ordering_start: attr('date-string', { timeOption: 'start' }),

    /**
     * @memberof Vdc/ProjectModel
     * @instance
     *
     * @type {String}
     */
    definitive_ordering_end: attr('date-string', { timeOption: 'end' }),

    /**
     * @memberof Vdc/ProjectModel
     * @instance
     *
     * @type {String}
     */
    earliest_delivery_date: attr('date-string'),

    /**
     * @memberof Vdc/ProjectModel
     * @instance
     *
     * @type {String}
     */
    latest_delivery_date: attr('date-string'),

    /**
     * @memberof Vdc/ProjectModel
     * @instance
     *
     * @type {Boolean}
     */
    ordering_enabled: attr('boolean', { defaultValue: function defaultValue() {
        return false;
      } }),

    /**
     * @memberof Vdc/ProjectModel
     * @instance
     *
     * @type {Boolean}
     */
    return_enabled: attr('boolean', { defaultValue: function defaultValue() {
        return false;
      } }),

    /**
     * @memberof Vdc/ProjectModel
     * @instance
     *
     * @type {String}
     */
    return_end: attr('date-string', { timeOption: 'end' }),

    /**
     * @memberof Vdc/ProjectModel
     * @instance
     *
     * @type {String}
     */
    // TODO this should be a date-string or date-time-string
    launch_date: attr('date'), // eslint-disable-line ligadigital/ember-model-custom-types

    /**
     * @memberof Vdc/ProjectModel
     * @instance
     *
     * @type {String}
     */
    display_name: attr('nullable-string'),

    /**
     * @memberof Vdc/ProjectModel
     * @instance
     *
     * @type {Number}
     */
    plan_id: attr('id'),

    /**
     * @memberof Vdc/ProjectModel
     * @instance
     *
     * @type {Number[]}
     */
    market_ids: attr('number-array', { defaultValue: function defaultValue() {
        return [];
      } }),

    /**
     * @memberof Vdc/ProjectModel
     * @instance
     *
     * @type {Number[]}
     */
    country_ids: attr('number-array', { defaultValue: function defaultValue() {
        return [];
      } }),

    /**
     * @memberof Vdc/ProjectModel
     * @instance
     *
     * @type {Number[]}
     */
    store_type_ids: attr('number-array', { defaultValue: function defaultValue() {
        return [];
      } }),

    /**
     * @memberof Vdc/ProjectModel
     * @instance
     *
     * @type {Number[]}
     */
    store_subtype_ids: attr('number-array', { defaultValue: function defaultValue() {
        return [];
      } }),

    /**
     * @memberof Vdc/ProjectModel
     * @instance
     *
     * @type {Number[]}
     */
    store_ids: attr('number-array', { defaultValue: function defaultValue() {
        return [];
      } }),

    /**
     * @memberof Vdc/ProjectModel
     * @instance
     *
     * @type {Number}
     */
    project_type_id: attr('id'),

    /**
     * @memberof Vdc/ProjectModel
     * @instance
     *
     * @type {String}
     */
    project_type_name: attr('nullable-string'),

    /**
     * @memberof Vdc/ProjectModel
     * @instance
     *
     * @type {String}
     */
    status_color: attr('nullable-string'),

    /**
     * @memberof Vdc/ProjectModel
     * @instance
     *
     * @type {Array}
     */
    stores: attr(),

    /**
     * @memberof Vdc/ProjectModel
     * @instance
     *
     * @type {String}
     */
    status: attr('nullable-string'),

    /**
     * @memberof Vdc/ProjectModel
     * @instance
     *
     * @type {Number}
     */
    article_attachment_full_file_duration_access: attr('number'),

    /**
     * @memberof Vdc/ProjectModel
     * @instance
     *
     * @type {String}
     */
    market_name: attr('nullable-string'),

    /**
     * @memberof Vdc/ProjectModel
     * @instance
     *
     * @type {Number}
     */
    markets_count: attr('number'),

    /**
     * @memberof Vdc/ProjectModel
     * @instance
     *
     * @type {String}
     */
    store_name: attr('nullable-string'),

    /**
     * @memberof Vdc/ProjectModel
     * @instance
     *
     * @type {Number}
     */
    stores_count: attr('number'),

    /**
     * @memberof Vdc/ProjectModel
     * @instance
     *
     * @type {String}
     */
    quality_gate: attr('nullable-string'),

    /**
     * @memberof Vdc/ProjectModel
     * @instance
     *
     * @type {Number}
     */
    delivery_time_restriction: attr('number'),

    /**
     * @memberof Vdc/ProjectModel
     * @instance
     *
     * @type {Vdc/PictureModel}
     */
    preview_picture: belongsTo('vdc/picture'),

    /**
     * @memberof Vdc/ProjectModel
     * @instance
     *
     * @type {Number[]}
     */
    documents: hasMany('vdc/document', { async: true }),

    /**
     * @memberof Vdc/ProjectModel
     * @instance
     *
     * @type {Vdc/PictureModel[]}
     */
    pictures: hasMany('vdc/picture', { async: false }),

    /**
     * @memberof Vdc/ProjectModel
     * @instance
     *
     * @type {Vdc/AttributeSetModel}
     */
    attribute_set: belongsTo('vdc/attribute-set', { readOnly: true }),

    /**
     * @memberof Vdc/ProjectModel
     * @instance
     *
     * @type {Vdc/fragments/AttributeValueFragment[]}
     */
    attribute_values: (0, _attributes.fragmentArray)('vdc/fragments/attribute-value')
  });

  ProjectModel.reopenClass({

    /**
     * @memberof Vdc/ProjectModel
     * @static
     *
     * @type {EnumOption[]}
     */
    statusOptions: statusOptions,

    /**
     * @memberof Vdc/ProjectModel
     * @static
     *
     * @type {EnumOption[]}
     */
    progressOptions: progressOptions

  });

  exports.default = ProjectModel;
});