define('ln-ember-data-models/serializers/vdc/article', ['exports', 'ln-ember-data-models/serializers/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({

    get attrs() {
      return {
        attributes: {
          serialize: 'ids',
          deserialize: 'records'
        }
      };
    },

    keyForRelationship: function keyForRelationship(key, kind) {
      if (key === 'attributes' && kind === 'hasMany') {
        return 'attribute_ids';
      }

      return this._super.apply(this, arguments);
    },
    serializeHasMany: function serializeHasMany(snapshot, json, relationship) {
      var key = this.keyForRelationship(relationship.key, relationship.kind);

      this._super(snapshot, json, relationship);

      // API accepts only number[]
      if (key === 'attribute_ids') {
        json[key] = (json[key] || []).map(Number);
      }
    }
  });
});