define('ln-ember-data-models/serializers/vdc/kpi', ['exports', 'ln-ember-data-models/serializers/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({

    get attrs() {
      return {
        kpi_data: {
          key: 'data',
          deserialize: 'records'
        }
      };
    }

  });
});