define("ln-ember-form-elements/components/form-elements/calendar/month", ["exports", "moment", "ln-ember-form-elements/utils/moment-helper", "ln-ember-form-elements/templates/components/form-elements/calendar/month", "ln-ember-form-elements/utils/computed-properties"], function (_exports, _moment, _momentHelper, _month, _computedProperties) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _default = Ember.Component.extend({
    date: null,
    theme: null,
    range: null,
    visibleDate: new Date(),
    selectedWeek: null,
    showCalendarWeek: false,
    showWeekHighlight: false,
    onSelect: (0, _computedProperties.defaultAction)(function (value) {
      this.set('date', value);
    }),
    weekStatusComponent: null,
    themeClass: Ember.computed('theme', function () {
      return "theme-".concat(this.get('theme') || 'light');
    }),
    layout: _month.default,
    classNames: ['form-elements--calendar--month-component'],
    classNameBindings: ['showCalendarWeek', 'weekStatusComponent:has-status', 'disabled:is-disabled', 'themeClass', 'showWeekHighlight'],
    weekdays: Ember.computed('visibleDate', function () {
      var date = (0, _moment.default)(this.get('visibleDate') || new Date()).startOf('isoWeek');
      return _toConsumableArray(Array(7)).map(function (x, i) {
        return (0, _moment.default)(date).add(i, 'days').format('dd');
      });
    }),
    weeks: Ember.computed('visibleDate', 'date', 'range.{start,startIsHalfDay,end,endIsHalfDay}', {
      get: function get() {
        var _this = this;

        var visibleDate = this.get('visibleDate');
        var current = (0, _moment.default)(visibleDate).startOf('month').startOf('isoWeek');
        var last = (0, _moment.default)(visibleDate).endOf('month').endOf('isoWeek');
        var days = [];

        while (current.isBefore(last)) {
          days.push((0, _moment.default)(current));
          current.add(1, 'day');
        }

        var weeks = days.reduce(function (weeks, day) {
          var weekIdx = (0, _momentHelper.weekKey)(day);
          var dayIdx = (day.isoWeekday() || 7) - 1;

          if (!weeks[weekIdx]) {
            weeks[weekIdx] = new Array(7);
          }

          weeks[weekIdx][dayIdx] = {
            day: day,
            date: day.date(),
            className: _this.buildClassNames(day).join(' ')
          };
          return weeks;
        }, {});
        return Object.keys(weeks).map(function (key) {
          return {
            start: (0, _momentHelper.weekKeyToDate)(key).toDate(),
            cw: (0, _momentHelper.weekKeyToDate)(key).isoWeek(),
            selected: _this.get('selectedWeek') ? _this.get('selectedWeek').isoWeek() === (0, _momentHelper.weekKeyToDate)(key).isoWeek() && _this.get('selectedWeek').year() === (0, _momentHelper.weekKeyToDate)(key).year() : false,
            days: weeks[key]
          };
        });
      }
    }).readOnly(),
    buildClassNames: function buildClassNames(day) {
      var _ref = this.get('range') || {},
          start = _ref.start,
          end = _ref.end;

      start = start && (0, _moment.default)(start).startOf('day');
      end = end && (0, _moment.default)(end).startOf('day');

      if (start && end && start.isAfter(end)) {
        var _ref2 = [end, start];
        start = _ref2[0];
        end = _ref2[1];
      }

      if (!start && end || start && !end) {
        end = start;
      }

      var date = this.get('date') && (0, _moment.default)(this.get('date')).startOf('day');
      var isStart = start && day.isSame(start, 'day');
      var isEnd = end && day.isSame(end, 'day');
      var classNames = []; // Visible, but not in the visible month

      if (!day.isSame(this.get('visibleDate'), 'month')) {
        classNames.push('is-not-in-month');
      } // Today


      if (day.isSame(new Date(), 'day')) {
        classNames.push('is-today');
      } // Selection highlights
      // Selected date or one day range


      if (date && day.isSame(date, 'day') || isStart && isEnd) {
        classNames.push('is-selected');

        if (this.get('range.startIsHalfDay')) {
          classNames.push('is-half');
        } // Start date of multi date range

      } else if (isStart) {
        classNames.push('is-range-start');

        if (this.get('range.startIsHalfDay')) {
          classNames.push('is-half');
        } // End date of multi date range

      } else if (isEnd) {
        classNames.push('is-range-end');

        if (this.get('range.endIsHalfDay')) {
          classNames.push('is-half');
        } // Dates in between of multi date range

      } else if (start && end && day.isBetween(start, end)) {
        classNames.push('is-range');
      }

      return classNames.sort();
    },
    actions: {
      onDayClick: function onDayClick(_ref3) {
        var day = _ref3.day;

        if (!this.get('disabled')) {
          this.get('onSelect')(day.toDate());
        }
      }
    }
  });

  _exports.default = _default;
});