define('ln-ember-data-models/models/myliga/objective-agreement', ['exports', 'ember-data', 'ln-ember-data-models/utils/enum-option', 'ember-data-model-fragments/attributes'], function (exports, _emberData, _enumOption, _attributes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.statusOptions = undefined;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      hasMany = _emberData.default.hasMany;


  /**
   * @memberof Myliga/ObjectiveAgreementModel
   * @static
   *
   * @type {EnumOption[]}
   */
  var statusOptions = exports.statusOptions = [_enumOption.default.create({ id: 'Draft' }), _enumOption.default.create({ id: 'Published' }), _enumOption.default.create({ id: 'Closed' })];

  /**
   * @class Myliga/ObjectiveAgreementModel
   * @extends DS.Model
   */
  var objectiveAgreementModel = Model.extend({

    /**
     * @memberof Myliga/ObjectiveAgreementModel
     * @instance
     *
     * @type {Number}
     */
    version: attr('number'),

    /**
     * @memberof Myliga/ObjectiveAgreementModel
     * @instance
     *
     * @type {Number}
     */
    user_id: attr('id'),

    /**
     * @memberof Myliga/ObjectiveAgreementModel
     * @instance
     *
     * @type {String}
     */
    date_from: attr('date-string'),

    /**
     * @memberof Myliga/ObjectiveAgreementModel
     * @instance
     *
     * @type {String}
     */
    date_till: attr('date-string'),

    /**
     * @memberof Myliga/ObjectiveAgreementModel
     * @instance
     *
     * @type {Number}
     */
    talk_id: attr('id'),

    /**
     * @memberof Myliga/ObjectiveAgreementModel
     * @instance
     *
     * @type {Number}
     */
    superior_user_id: attr('id'),

    /**
     * @memberof Myliga/ObjectiveAgreementModel
     * @instance
     *
     * @type {String}
     */
    status: attr('enum-string', { options: statusOptions }),

    /**
     * @memberof Myliga/ObjectiveAgreementModel
     * @instance
     *
     * @type {Boolean}
     */
    qualitative_objectives: attr('boolean'),

    /**
     * @memberof Myliga/ObjectiveAgreementModel
     * @instance
     *
     * @type {Boolean}
     */
    quantitative_objectives: attr('boolean'),

    /**
     * @memberof Myliga/ObjectiveAgreementModel
     * @instance
     *
     * @type {String}
     */
    comment: attr('nullable-string'),

    /**
     * @memberof Myliga/ObjectiveAgreementModel
     * @instance
     *
     * @type {Myliga/Objective}
     */
    objectives: hasMany('myliga/objective'),

    /**
     * @memberof Myliga/ObjectiveAgreementModel
     * @instance
     *
     * @type {Myliga/fragments/JobprofileObjectiveSettingFragment}
     */
    quantitative_objective_setting: (0, _attributes.fragment)('myliga/fragments/jobprofile-objective-setting', {
      defaultValue: {
        bonus_payment_type: null,
        max_bonus_payment: null,
        objective_enabled: false
      }
    }),

    /**
     * @memberof Myliga/ObjectiveAgreementModel
     * @instance
     *
     * @type {Myliga/fragments/JobprofileObjectiveSettingFragment}
     */
    qualitative_objective_setting: (0, _attributes.fragment)('myliga/fragments/jobprofile-objective-setting', {
      defaultValue: {
        bonus_payment_type: null,
        max_bonus_payment: null,
        objective_enabled: false
      }
    })
  });

  objectiveAgreementModel.reopenClass({

    /**
     * @memberof Myliga/ObjectiveAgreementModel
     * @static
     *
     * @type {EnumOption[]}
     */
    statusOptions: statusOptions

  });

  exports.default = objectiveAgreementModel;
});