define('ln-ember-data-models/models/vdc/article', ['exports', 'ember-data', 'ln-ember-data-models/utils/enum-option', 'ember-data-model-fragments/attributes'], function (exports, _emberData, _enumOption, _attributes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.salesOrConsumption = exports.attachmentType = exports.statusOptions = exports.fitTypeOptions = exports.typeOptions = exports.targetTypeOptions = undefined;
  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany,
      Model = _emberData.default.Model;


  /**
   * @name Vdc/ArticleModel/typeOptions
   * @type {EnumOption[]}
   */
  var targetTypeOptions = exports.targetTypeOptions = [_enumOption.default.create({ id: 'Instore' }), _enumOption.default.create({ id: 'Store', name: 'POS' }), _enumOption.default.create({ id: 'Window' })];

  /**
   * @name Vdc/ArticleModel/typeOptions
   * @type {EnumOption[]}
   */
  var typeOptions = exports.typeOptions = [_enumOption.default.create({ id: 'ArticleMaster' }), _enumOption.default.create({ id: 'ArticleVariant' }), _enumOption.default.create({ id: 'ArticleSet' })];

  /**
   * @name Vdc/ArticleModel/fitTypeOptions
   * @type {EnumOption[]}
   */
  var fitTypeOptions = exports.fitTypeOptions = [_enumOption.default.create({ id: 'Fixture' }), _enumOption.default.create({ id: 'FixtureCatalog' }), _enumOption.default.create({ id: 'FixtureType' }), _enumOption.default.create({ id: 'InstoreArea' }), _enumOption.default.create({ id: 'Store', name: 'POS' }), _enumOption.default.create({ id: 'Window' })];

  /**
   * @name Vdc/ArticleModel/statusOptions
   * @type {EnumOption[]}
   */
  var statusOptions = exports.statusOptions = [_enumOption.default.create({ id: 'Draft' }), _enumOption.default.create({ id: 'Active' }), _enumOption.default.create({ id: 'Locked' }), _enumOption.default.create({ id: 'Inactive' })];

  /**
   * @name Vdc/ArticleModel/attachmentType
   * @type {EnumOption[]}
   */
  var attachmentType = exports.attachmentType = [_enumOption.default.create({ id: 'None' }), _enumOption.default.create({ id: 'Both' }), _enumOption.default.create({ id: 'FullFile' }), _enumOption.default.create({ id: 'PreviewFile' }), _enumOption.default.create({ id: 'Any' })];

  /**
   * @name Vdc/ArticleModel/attachmentType
   * @type {EnumOption[]}
   */
  var salesOrConsumption = exports.salesOrConsumption = [_enumOption.default.create({ id: 'Sale' }), _enumOption.default.create({ id: 'Consumption' })];

  /**
   * @class Vdc/ArticleModel
   * @extends DS.Model
   */
  var ArticleModel = Model.extend({

    /**
     * @memberof Vdc/ArticleModel
     * @instance
     *
     * @type {Number}
     */
    version: attr('number'),

    /**
     * @memberof Vdc/ArticleModel
     * @instance
     *
     * @type {String}
     */
    name: attr('nullable-string'),

    /**
     * @memberof Vdc/ArticleModel
     * @instance
     *
     * @type {String}
     */
    number: attr('nullable-string'),

    /**
     * @memberof Vdc/ArticleModel
     * @instance
     *
     * @type {String}
     */
    description: attr('nullable-string'),

    /**
     * @memberof Vdc/ArticleModel
     * @instance
     *
     * @type {String}
     */
    sourcing_description: attr('nullable-string'),

    /**
     * @memberof Vdc/ArticleModel
     * @instance
     *
     * @type {String}
     */
    client_id: attr('id'),

    /**
     * @memberof Vdc/ArticleModel
     * @instance
     *
     * @type {String}
     */
    child_count: attr('number'),

    /**
     * @memberof Vdc/ArticleModel
     * @instance
     *
     * @type {Number[]}
     */
    usable_client_ids: attr('number-array', { defaultValue: function defaultValue() {
        return [];
      } }),

    /**
     * @memberof Vdc/ArticleModel
     * @instance
     *
     * @type {Number[]}
     */
    project_ids: attr('number-array', { defaultValue: function defaultValue() {
        return [];
      } }),

    /**
     * @memberof Vdc/ArticleModel
     * @instance
     *
     * @type {Array}
     */
    parents: attr({ defaultValue: function defaultValue() {
        return [];
      } }),

    /**
     * @memberof Vdc/ArticleModel
     * @instance
     *
     * @type {Number}
     */
    material_id: attr('id'),

    /**
     * @memberof Vdc/ArticleModel
     * @instance
     *
     * @type {String}
     */
    material_name: attr('nullable-string'),

    /**
     * @memberof Vdc/ArticleModel
     * @instance
     *
     * @type {Boolean}
     */
    shared: attr('boolean', { defaultValue: function defaultValue() {
        return false;
      } }),

    /**
     * @memberof Vdc/ArticleModel
     * @instance
     *
     * @type {Boolean}
     */
    disposable: attr('boolean', { defaultValue: function defaultValue() {
        return false;
      } }),

    /**
     * @memberof Vdc/ArticleModel
     * @instance
     *
     * @type {Boolean}
     */
    alpha: attr('boolean', { defaultValue: function defaultValue() {
        return false;
      } }),

    /**
     * Differentiates Articles from ArticleSets
     * @memberof Vdc/ArticleModel
     * @instance
     *
     * @type {String}
     */
    type: attr('enum-string', { options: typeOptions, defaultValue: 'ArticleMaster' }),

    /**
     * @memberof Vdc/ArticleModel
     * @instance
     *
     * @type {String}
     */
    target_type: attr('enum-string', { options: targetTypeOptions }),

    /**
     * @memberof Vdc/ArticleModel
     * @instance
     *
     * @type {String}
     */
    fit_type: attr('enum-string', { options: fitTypeOptions }),

    /**
     * @memberof Vdc/ArticleModel
     * @instance
     *
     * @type {String}
     */
    status: attr('enum-string', { options: statusOptions, defaultValue: 'Draft' }),

    /**
     * @memberof Vdc/ArticleModel
     * @instance
     *
     * @type {String}
     */
    sale_or_consumption: attr('enum-string', { options: salesOrConsumption, defaultValue: 'Sale' }),

    /**
     * @memberof Vdc/ArticleModel
     * @instance
     *
     * @type {Boolean}
     */
    sold_individually: attr('boolean', { defaultValue: false }),

    /**
     * @memberof Vdc/ArticleModel
     * @instance
     *
     * @type {Number}
     */
    attribute_set_id: attr('id'),

    /**
     * @memberof Vdc/ArticleModel
     * @instance
     *
     * @type {Array}
     */
    fixture_type_ids: attr('number-array', { defaultValue: function defaultValue() {
        return [];
      } }),

    /**
     * @memberof Vdc/ArticleModel
     * @instance
     *
     * @type {Number}
     */
    fixture_catalog_id: attr('id'),

    /**
     * @memberof Vdc/ArticleModel
     * @instance
     *
     * @type {Number}
     */
    language_id: attr('id'),

    /**
     * @memberof Vdc/ArticleModel
     * @instance
     *
     * @type {Number}
     */
    article_category_id: attr('id'),

    /**
     * @memberof Vdc/ArticleModel
     * @instance
     *
     * @type {String}
     */
    article_category_name: attr('nullable-string'),

    /**
     * @memberof Vdc/ArticleModel
     * @instance
     *
     * @type {Number[]}
     */
    text_module_ids: attr('number-array', { defaultValue: function defaultValue() {
        return [];
      } }),

    /**
     * @memberof Vdc/ArticleModel
     * @instance
     *
     * @type {Number}
     */
    position: attr('number'),

    /**
     * @memberof Vdc/ArticleModel
     * @instance
     *
     * @type {Number}
     */
    additional_fee: attr('number'),

    /**
     * @memberof Vdc/ArticleModel
     * @instance
     *
     * @type {Number}
     */
    default_quantity: attr('number', { defaultValue: 1 }),

    /**
     * @memberof Vdc/ArticleModel
     * @instance
     *
     * @type {Array}
     */
    childs: attr(),

    /**
     * @memberof Vdc/ArticleModel
     * @instance
     *
     * @type {Array}
     */
    sales_prices: attr(),

    /**
     * @memberof Vdc/ArticleModel
     * @instance
     *
     * @type {Number[]}
     */
    motif_ids: attr('number-array', { defaultValue: function defaultValue() {
        return [];
      } }),

    /**
     * @memberof Vdc/ArticleModel
     * @instance
     *
     * @type {Boolean}
     */
    scalable: attr('boolean', { defaultValue: function defaultValue() {
        return false;
      } }),

    /**
     * @memberof Vdc/ArticleModel
     * @instance
     *
     * @type {Boolean}
     */
    mandatory_review: attr('boolean', { defaultValue: function defaultValue() {
        return false;
      } }),

    /**
     * @memberof Vdc/ArticleModel
     * @instance
     *
     * @type {Number}
     */
    attachment_full_file_size: attr('number'),

    /**
     * @memberof Vdc/ArticleModel
     * @instance
     *
     * @type {String}
     */
    article_review_approval_date: attr('date-string'),

    /**
     * @memberof Vdc/ArticleModel
     * @instance
     *
     * @type {String}
     */
    article_review_status: attr('nullable-string'),

    /**
     * @memberof Vdc/ArticleModel
     * @instance
     *
     * @type {Number}
     */
    product_category_id: attr('id'),

    /**
     * @memberof Vdc/ArticleModel
     * @instance
     *
     * @type {Vdc/FitCriterion[]}
     */
    fit_criteria: hasMany('vdc/fit_criterion', { async: true }),

    /**
     * @memberof Vdc/ArticleModel
     * @instance
     *
     * @type {Vdc/PictureModel}
     */
    preview_picture: belongsTo('vdc/picture'),

    /**
     * @memberof Vdc/ArticleModel
     * @instance
     *
     * @type {Vdc/Picture[]}
     */
    pictures: hasMany('vdc/picture', { async: false }),

    /**
     * @memberof Vdc/ArticleModel
     * @instance
     *
     * @type {Vdc/ArticleAttribute[]}
     */
    attributes: hasMany('vdc/article_attribute', { async: false, defaultValue: function defaultValue() {
        return [];
      } }),

    /**
     * @memberof Vdc/ArticleModel
     * @instance
     *
     * @type {Vdc/TextModuleModel[]}
     */
    text_modules: hasMany('vdc/text-module'),

    /**
     * @memberof Vdc/ArticleModel
     * @instance
     *
     * @type {Vdc/SupplierInfoModel}
     */
    supplier_info: belongsTo('vdc/supplier-info', { async: false }),

    /**
     * @memberof Vdc/ArticleModel
     * @instance
     *
     * @type {Vdc/GoodsMovementModel}
     */
    goods_movement: belongsTo('vdc/goods-movement', { async: false }),

    /**
     * @memberof Vdc/ArticleModel
     * @instance
     *
     * @type {Vdc/ArticleAttachmentModel}
     */
    attachment_full_file: belongsTo('vdc/article-attachment', { async: false }),

    /**
     * @memberof Vdc/ArticleModel
     * @instance
     *
     * @type {Vdc/ArticleAttachmentModel}
     */
    attachment_preview_file: belongsTo('vdc/article-attachment', { async: false }),

    /**
     * @memberof Vdc/ArticleModel
     * @instance
     *
     * @type {Vdc/fragments/EstimatedDimensionsFragment}
     */
    estimated_dimensions: (0, _attributes.fragment)('vdc/fragments/estimated-dimensions')

  });

  ArticleModel.reopenClass({

    /**
     * @memberof Vdc/ArticleModel
     * @static
     *
     * @type {EnumOption[]}
     */
    typeOptions: typeOptions,

    /**
     * @memberof Vdc/ArticleModel
     * @static
     *
     * @type {EnumOption[]}
     */
    targetTypeOptions: targetTypeOptions,

    /**
     * @memberof Vdc/ArticleModel
     * @static
     *
     * @type {EnumOption[]}
     */
    fitTypeOptions: fitTypeOptions,

    /**
     * @memberof Vdc/ArticleModel
     * @static
     *
     * @type {EnumOption[]}
     */
    statusOptions: statusOptions,

    /**
     * @memberof Vdc/ArticleModel
     * @static
     *
     * @type {EnumOption[]}
     */
    attachmentType: attachmentType

  });

  exports.default = ArticleModel;
});