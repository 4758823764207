define('ln-ember-data-models/transforms/date-string', ['exports', 'ember-data', 'moment'], function (exports, _emberData, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Transform = _emberData.default.Transform;


  var timeMap = {
    start: '00:00:00.000',
    end: '23:59:59.999'
  };

  /**
   * @class DateStringTransform
   * @extends DS.Transform
   */

  exports.default = Transform.extend({

    dateTimeFormat: 'YYYY-MM-DDTHH:mm:ss.SSSZ',

    dateFormat: 'YYYY-MM-DD',

    deserialize: function deserialize(serialized) {
      var _ref = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
          _ref$timeOption = _ref.timeOption,
          timeOption = _ref$timeOption === undefined ? null : _ref$timeOption;

      if (!timeOption) {
        return serialized;
      }

      var dateFormat = this.get('dateFormat');

      return (0, _moment.default)(serialized).format(dateFormat);
    },
    serialize: function serialize(deserialized) {
      var _ref2 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
          _ref2$timeOption = _ref2.timeOption,
          timeOption = _ref2$timeOption === undefined ? null : _ref2$timeOption;

      if (!deserialized || !(0, _moment.default)(deserialized).isValid()) {
        return null;
      }

      if (!timeOption) {
        return deserialized;
      }

      var time = Ember.get(timeMap, timeOption);

      (false && !(time) && Ember.assert('[date-string transform] `timeOption` has to be either "start" or "end".', time));


      var dateTimeFormat = this.get('dateTimeFormat');
      var dateTime = (0, _moment.default)(deserialized + ' ' + time);

      return dateTime.isValid() ? dateTime.format(dateTimeFormat) : null;
    }
  });
});