define('ln-ember-data-models/models/vdc/country', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany,
      Model = _emberData.default.Model;
  exports.default = Model.extend({

    /**
     * @memberof Vdc/Country
     * @instance
     *
     * @type {Number}
     */
    version: attr('number'),

    /**
     * @memberof Vdc/Country
     * @instance
     *
     * @type {String}
     */
    name: attr('nullable-string'),

    /**
     * @memberof Vdc/Country
     * @instance
     *
     * @type {String}
     */
    code: attr('nullable-string'),

    /**
     * @memberof Vdc/Country
     * @instance
     *
     * @type {Number}
     */
    pictureId: attr('number'),

    /**
     * @memberof Vdc/Country
     * @instance
     *
     * @type {Number}
     */
    latitude: attr('number'),

    /**
     * @memberof Vdc/Country
     * @instance
     *
     * @type {Number}
     */
    longitude: attr('number'),

    /**
     * @memberof Vdc/Country
     * @instance
     *
     * @type {Boolean}
     */
    eu: attr('boolean', { defaultValue: function defaultValue() {
        return false;
      } }),

    /**
     * @memberof Vdc/Country
     * @instance
     *
     * @type {transforms/number-array}
     */
    language_ids: attr('number-array', { defaultValue: function defaultValue() {
        return [];
      } }),

    /**
     * @memberof Vdc/Country
     * @instance
     *
     * @type {Vdc/PictureModel}
     */
    picture: belongsTo('vdc/picture'),

    /**
     * @memberof Vdc/Country
     * @instance
     *
     * @type {Vdc/Contact}
     */
    contact: belongsTo('vdc/contact', { async: false }),

    /**
     * @memberof Vdc/Country
     * @instance
     *
     * @type {Vdc/Language[]}
     */
    languages: hasMany('vdc/language', { async: true })

  });
});