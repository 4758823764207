define('ln-ember-contact-menu/components/fake-contact-menu', ['exports', 'ln-ember-contact-menu/templates/components/fake-contact-menu'], function (exports, _fakeContactMenu) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _fakeContactMenu.default,

    classNames: ['contact-menu-component', 'is-fake']
  });
});