define("ln-ember-form-elements/templates/components/flash-messages/flash-messages", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XbFdH+jq",
    "block": "{\"symbols\":[\"message\"],\"statements\":[[4,\"each\",[[24,[\"visibleMessages\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"flash-messages/flash-message\",null,[[\"message\",\"allMessages\",\"onHeightChange\",\"onRemove\",\"onCloseClick\"],[[23,1,[]],[24,[\"visibleMessages\"]],[28,\"action\",[[23,0,[]],\"setMessageHeight\",[23,1,[]]],null],[28,\"action\",[[23,0,[]],\"removeMessageHeight\",[23,1,[]]],null],[28,\"action\",[[23,0,[]],\"removeMessage\",[23,1,[]]],null]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ln-ember-form-elements/templates/components/flash-messages/flash-messages.hbs"
    }
  });

  _exports.default = _default;
});