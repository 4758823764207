define('ln-ember-data-models/models/vdc/fit-criterion', ['exports', 'ember-data', 'ln-ember-data-models/utils/enum-option', 'ember-inflector'], function (exports, _emberData, _enumOption, _emberInflector) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr,
      Model = _emberData.default.Model;


  _emberInflector.default.inflector.irregular('fit_criterion', 'fit_criteria');

  /**
   * @memberof Vdc/FitCriterionModel
   * @static
   *
   * @type {EnumOption[]}
   */
  var compareOperatorOptions = [_enumOption.default.create({ id: 'Greater', name: 'Is greater than', type: 'Number' }), _enumOption.default.create({ id: 'Smaller', name: 'Is smaller than', type: 'Number' }), _enumOption.default.create({ id: 'Equal', name: 'Equals', type: 'Number' }), _enumOption.default.create({ id: 'Not', name: 'Is not', type: 'Other' }), _enumOption.default.create({ id: 'Equal', name: 'Equals', type: 'Other' })];

  /**
   * @class Vdc/FitCriterionModel
   * @extends DS.Model
   */
  var FitCriterionModel = Model.extend({
    /**
     * @memberof Vdc/FitCriterionModel
     * @instance
     *
     * @type {Number}
     */
    version: attr('number'),

    /**
     * @memberof Vdc/FitCriterionModel
     * @instance
     *
     * @type {Number}
     */
    article_id: attr('id'),

    /**
     * @memberof Vdc/FitCriterionModel
     * @instance
     *
     * @type {Number}
     */
    attribute_id: attr('id'),

    /**
     * @memberof Vdc/FitCriterionModel
     * @instance
     *
     * @type {Number}
     */
    compare_operator: attr('enum-string', { options: compareOperatorOptions }),

    /**
     * @memberof Vdc/FitCriterionModel
     * @instance
     *
     * @type {}
     */
    value: attr()
  });

  FitCriterionModel.reopenClass({

    /**
     * @memberof Vdc/FitCriterionModel
     * @static
     *
     * @type {EnumOption[]}
     */
    compareOperatorOptionsForTypeNumber: Ember.A(compareOperatorOptions).filterBy('type', 'Number'),

    /**
     * @memberof Vdc/FitCriterionModel
     * @static
     *
     * @type {EnumOption[]}
     */
    compareOperatorOptionsForOther: Ember.A(compareOperatorOptions).filterBy('type', 'Other')

  });

  exports.default = FitCriterionModel;
});