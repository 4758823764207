define('ln-ember-data-models/models/vdc/quality-audit-position', ['exports', 'ember-data', 'ln-ember-data-models/utils/enum-option'], function (exports, _emberData, _enumOption) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.statusOptions = undefined;
  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      Model = _emberData.default.Model;


  /**
   * @name Vdc/QualityAuditPositionModel/statusOptions
   * @type {EnumOption[]}
   */
  var statusOptions = exports.statusOptions = [_enumOption.default.create({ id: 'Todo' }), _enumOption.default.create({ id: 'Approved' }), _enumOption.default.create({ id: 'NotApproved' }), _enumOption.default.create({ id: 'NoLongerInUse' })];

  /**
   * @class Vdc/QualityAuditPositionModel
   * @extends DS.Model
   */
  var QualityAuditPositionModel = Model.extend({

    /**
     * @memberof Vdc/QualityAuditPositionModel
     * @instance
     *
     * @type {Number}
     */
    version: attr('number'),

    /**
     * @memberof Vdc/QualityAuditPositionModel
     * @instance
     *
     * @type {String}
     */
    date_created: attr('date-time-string'),

    /**
     * @memberof Vdc/QualityAuditPositionModel
     * @instance
     *
     * @type {String}
     */
    date_changed: attr('date-time-string'),

    /**
     * @memberof Vdc/QualityAuditPositionModel
     * @instance
     *
     * @type {Number}
     */
    changed_by: attr('id'),

    /**
     * @memberof Vdc/QualityAuditPositionModel
     * @instance
     *
     * @type {Number}
     */
    quality_audit_id: attr('id'),

    /**
     * @memberof Vdc/QualityAuditPositionModel
     * @instance
     *
     * @type {Number}
     */
    article_category_id: attr('id'),

    /**
     * @memberof Vdc/QualityAuditPositionModel
     * @instance
     *
     * @type {String}
     */
    article_category_name: attr('nullable-string'),

    /**
     * @memberof Vdc/QualityAuditPositionModel
     * @instance
     *
     * @type {Number}
     */
    product_id: attr('id'),

    /**
     * @memberof Vdc/QualityAuditPositionModel
     * @instance
     *
     * @type {String}
     */
    product_name: attr('nullable-string'),

    /**
     * @memberof Vdc/QualityAuditPositionModel
     * @instance
     *
     * @type {String}
     */
    status: attr('enum-string', { options: statusOptions }),
    /**
     * @memberof Vdc/QualityAuditPositionModel
     * @instance
     *
     * @type {Vdc/UserModel}
     */
    last_changed_by: belongsTo('vdc/user'),

    /**
     * @memberof Vdc/QualityAuditPositionModel
     * @instance
     *
     * @type {Vdc/CommentModel}
     */
    last_comment: belongsTo('vdc/comment')

  });

  exports.default = QualityAuditPositionModel;
});