define('ln-ember-data-models/models/myliga/absence', ['exports', 'ember-data', 'ln-ember-data-models/utils/enum-option', 'ember-data-model-fragments/attributes'], function (exports, _emberData, _enumOption, _attributes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.typeOptions = undefined;
  var attr = _emberData.default.attr,
      Model = _emberData.default.Model;


  /**
   * @memberof Myliga/AbsenceModel/typeOptions
   * @type {EnumOption[]}
   */
  var typeOptions = exports.typeOptions = [_enumOption.default.create({ id: 'Holiday' }), _enumOption.default.create({ id: 'Sickness' }), _enumOption.default.create({ id: 'Compensation' }), _enumOption.default.create({ id: 'SpecialLeave' })];

  /**
   * @class Myliga/AbsenceModel
   * @extends DS.Model
   */
  var AbsenceModel = Model.extend({

    /**
     * @memberof Myliga/AbsenceModel
     * @instance
     *
     * @type {Number}
     */
    version: attr('number'),

    /**
     * @memberof Myliga/AbsenceModel
     * @instance
     *
     * @type {Number}
     */
    user_id: attr('id'),

    /**
     * @memberof Myliga/AbsenceModel
     * @instance
     *
     * @type {String}
     */
    date_from: attr('date-string'),

    /**
     * @memberof Myliga/AbsenceModel
     * @instance
     *
     * @type {String}
     */
    date_till: attr('date-string'),

    /**
     * @memberof Myliga/AbsenceModel
     * @instance
     *
     * @type {String}
     */
    type: attr('enum-string', { options: typeOptions }),

    /**
     * @memberof Myliga/AbsenceModel
     * @instance
     *
     * @type {Boolean}
     */
    from_full_day: attr('boolean', { defaultValue: true }),

    /**
     * @memberof Myliga/AbsenceModel
     * @instance
     *
     * @type {Boolean}
     */
    till_full_day: attr('boolean', { defaultValue: true }),

    /**
     * @memberof Myliga/AbsenceModel
     * @instance
     *
     * @type {String}
     */
    reason: attr('nullable-string'),

    /**
     * @memberof Myliga/AbsenceModel
     * @instance
     *
     * @type {MyLiga/fragments/UserMeta}
     */
    user_meta: (0, _attributes.fragment)('myliga/fragments/user-meta'),

    isFullDay: function isFullDay(date) {
      if (date === this.get('date_from')) {
        return this.get('from_full_day');
      }

      if (date === this.get('date_till')) {
        return this.get('till_full_day');
      }

      return true;
    }
  });

  AbsenceModel.reopenClass({

    /**
     * @memberof Myliga/AbsenceModel
     * @static
     *
     * @type {EnumOption[]}
     */
    typeOptions: typeOptions

  });

  exports.default = AbsenceModel;
});