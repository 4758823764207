define('ln-ember-data-models/models/vdc/training', ['exports', 'ember-data', 'ember-data-model-fragments/attributes', 'ln-ember-data-models/utils/enum-option'], function (exports, _emberData, _attributes, _enumOption) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.assignmentStatusOptions = exports.dynamicModesOptions = exports.staticModesOptions = exports.statusOptions = undefined;
  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany,
      Model = _emberData.default.Model;


  /**
   * @name Vdc/TrainingModel/statusOptions
   * @type {EnumOption[]}
   */
  var statusOptions = exports.statusOptions = [_enumOption.default.create({ id: 'Planned' }), _enumOption.default.create({ id: 'Done' }), _enumOption.default.create({ id: 'Canceled' })];

  /**
   * @name Vdc/TrainingModel/staticModeOptions
   * @type {EnumOption[]}
   */
  var staticModesOptions = exports.staticModesOptions = [_enumOption.default.create({ id: 'AMGCar', name: 'AMG Car' }), _enumOption.default.create({ id: 'Exhibits' }), _enumOption.default.create({ id: 'CompetitorCar' })];

  /**
   * @name Vdc/TrainingModel/dynamicModeOptions
   * @type {EnumOption[]}
   */
  var dynamicModesOptions = exports.dynamicModesOptions = [_enumOption.default.create({ id: 'OnRoadAMGCar', name: 'On Road AMG Car' }), _enumOption.default.create({ id: 'OnRoadCompetitorCar' }), _enumOption.default.create({ id: 'OnTrackAMGCar', name: 'On Track AMG Car' }), _enumOption.default.create({ id: 'OnTrackCompetitorCar' })];

  /**
   * TODO Description: What is this used for?
   *
   * @name Vdc/TrainingModel/assignmentStatusOptions
   * @type {EnumOption[]}
   */
  var assignmentStatusOptions = exports.assignmentStatusOptions = [_enumOption.default.create({ id: 'Invited' }), _enumOption.default.create({ id: 'Accepted' }), _enumOption.default.create({ id: 'Pending' }), _enumOption.default.create({ id: 'Declined' }), _enumOption.default.create({ id: 'Canceled' }), _enumOption.default.create({ id: 'Attended' }), _enumOption.default.create({ id: 'Missing' }), _enumOption.default.create({ id: 'EmailFailure' }), _enumOption.default.create({ id: 'Removed' })];

  /**
   * @class Vdc/TrainingModel
   * @extends DS.Model
   */
  var TrainingModel = Model.extend({

    /**
     * @memberof Vdc/TrainingModel
     * @instance
     *
     * @type {Number}
     */
    version: attr('number'),

    /**
     * @memberof Vdc/TrainingModel
     * @instance
     *
     * @type {String}
     */
    name: attr('nullable-string'),

    /**
     * @memberof Vdc/TrainingModel
     * @instance
     *
     * @type {String}
     */
    date_start: attr('date-string'),

    /**
     * @memberof Vdc/TrainingModel
     * @instance
     *
     * @type {String}
     */
    date_end: attr('date-string'),

    /**
     * @memberof Vdc/TrainingModel
     * @instance
     *
     * @type {Number}
     */
    duration: attr('number'),

    /**
     * @memberof Vdc/TrainingModel
     * @instance
     *
     * @type {String}
     */
    description: attr('nullable-string'),

    /**
     * @memberof Vdc/TrainingModel
     * @instance
     *
     * @type {String}
     */
    status: attr('enum-string', { options: statusOptions }),

    /**
     * @memberof Vdc/TrainingModel
     * @instance
     *
     * @type {Number}
     */
    client_id: attr('id'),

    /**
     * @memberof Vdc/TrainingModel
     * @instance
     *
     * @type {Number}
     */
    training_type_id: attr('id'),

    /**
     * @memberof Vdc/TrainingModel
     * @instance
     *
     * @type {String}
     */
    training_type_name: attr('nullable-string'),

    /**
     * @memberof Vdc/TrainingModel
     * @instance
     *
     * @type {Number}
     */
    training_subtype_id: attr('id'),

    /**
     * @memberof Vdc/TrainingModel
     * @instance
     *
     * @type {String}
     */
    training_subtype_name: attr('nullable-string'),

    /**
     * @memberof Vdc/TrainingModel
     * @instance
     *
     * @type {Number}
     */
    max_participants: attr('number'),

    /**
     * @memberof Vdc/TrainingModel
     * @instance
     *
     * @type {Number}
     */
    category_product_knowledge: attr('number'),

    /**
     * @memberof Vdc/TrainingModel
     * @instance
     *
     * @type {Number}
     */
    category_brand_fascination: attr('number'),

    /**
     * @memberof Vdc/TrainingModel
     * @instance
     *
     * @type {Number}
     */
    category_customer_care: attr('number'),

    /**
     * @memberof Vdc/TrainingModel
     * @instance
     *
     * @type {String}
     */
    dominant_category: attr('nullable-string'),

    /**
     * @memberof Vdc/TrainingModel
     * @instance
     *
     * @type {String}
     */
    city: attr('nullable-string'),

    /**
     * @memberof Vdc/TrainingModel
     * @instance
     *
     * @type {Number}
     */
    country_id: attr('id'),

    /**
     * @memberof Vdc/TrainingModel
     * @instance
     *
     * @type {Boolean}
     */
    has_agenda: attr('boolean', { defaultValue: function defaultValue() {
        return false;
      } }),

    /**
     * @memberof Vdc/TrainingModel
     * @instance
     *
     * @type {Array}
     */
    static_modes: attr({ defaultValue: function defaultValue() {
        return [];
      } }), /**
            * @memberof Vdc/TrainingModel
            * @instance
            *
            * @type {Array}
            */
    dynamic_modes: attr({ defaultValue: function defaultValue() {
        return [];
      } }),

    /**
     * @memberof Vdc/TrainingModel
     * @instance
     *
     * @type {Number[]}
     */
    user_type_ids: attr('number-array', { defaultValue: function defaultValue() {
        return [];
      } }),

    /**
     * @memberof Vdc/TrainingModel
     * @instance
     *
     * @type {Number}
     */
    registered_participants_count: attr('number', { defaultValue: 0, readOnly: true }),

    /**
     * @memberof Vdc/TrainingModel
     * @instance
     *
     * @type {Number}
     */
    missing_participants_count: attr('number', { defaultValue: 0, readOnly: true }),

    /**
     * @memberof Vdc/TrainingModel
     * @instance
     *
     * @type {Number}
     */
    uninvited_participants_count: attr('number', { defaultValue: 0, readOnly: true }),

    /**
     * @memberof Vdc/TrainingModel
     * @instance
     *
     * @type {Number}
     */
    available_slots_count: attr('number', { defaultValue: 0, readOnly: true }),

    /**
     * @memberof Vdc/TrainingModel
     * @instance
     *
     * @type {Number}
     */
    attended_participants_count: attr('number', { defaultValue: 0, readOnly: true }),
    /**
     * @memberof Vdc/TrainingModel
     * @instance
     *
     * @type {Vdc/AttributeSetModel}
     */
    attribute_set: belongsTo('vdc/attribute-set'),

    /**
     * @memberof Vdc/TrainingModel
     * @instance
     *
     * @type {Vdc/PictureModel}
     */
    preview_picture: belongsTo('vdc/picture'),

    /**
     * @memberof Vdc/TrainingModel
     * @instance
     *
     * @type {Vdc/Picture[]}
     */
    pictures: hasMany('vdc/picture'),

    /**
     * @memberof Vdc/TrainingModel
     * @instance
     *
     * @type {Vdc/fragments/AttributeValueFragment[]}
     */
    attribute_values: (0, _attributes.fragmentArray)('vdc/fragments/attribute-value')
  });

  TrainingModel.reopenClass({

    /**
     * @memberof Vdc/TrainingModel
     * @static
     *
     * @type {EnumOption[]}
     */
    statusOptions: statusOptions,

    /**
     * TODO should be renamed to `staticModesOptions`
     *
     * @memberof Vdc/TrainingModel
     * @static
     *
     * @type {EnumOption[]}
     */
    staticModeOptions: staticModesOptions,

    /**
     * TODO should be renamed to `dynamicModesOptions`
     *
     * @memberof Vdc/TrainingModel
     * @static
     *
     * @type {EnumOption[]}
     */
    dynamicModeOptions: dynamicModesOptions,

    /**
     * @memberof Vdc/TrainingModel
     * @static
     *
     * @type {EnumOption[]}
     */
    assignmentStatusOptions: assignmentStatusOptions

  });

  exports.default = TrainingModel;
});