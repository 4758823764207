define('ln-ember-data-models/models/vdc/kpi', ['exports', 'ember-data', 'ln-ember-data-models/utils/enum-option', 'ember-data-model-fragments/attributes'], function (exports, _emberData, _enumOption, _attributes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.formulaTypeOptions = undefined;
  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      Model = _emberData.default.Model;


  /**
   * @name Vdc/KpiModel/formulaTypeOptions
   * @type {EnumOption[]}
   */
  var formulaTypeOptions = exports.formulaTypeOptions = [_enumOption.default.create({ id: 'UsersStaff' }), _enumOption.default.create({ id: 'UsersStaffLengthOfStay' }), _enumOption.default.create({ id: 'UsersStaffTurnover' }), _enumOption.default.create({ id: 'UsersPersonalLengthOfStay' }), _enumOption.default.create({ id: 'UsersDelta' }), _enumOption.default.create({ id: 'EventsTestDrives' }), _enumOption.default.create({ id: 'EventsLeads' }), _enumOption.default.create({ id: 'EventsCostsPerSale' }), _enumOption.default.create({ id: 'EventsCostsPerLead' }), _enumOption.default.create({ id: 'EventsCostsPerGuest' }), _enumOption.default.create({ id: 'EventsConversionRate' }), _enumOption.default.create({ id: 'EventsSales' }), _enumOption.default.create({ id: 'EventsNewCustomers' }), _enumOption.default.create({ id: 'EventsGuests' }), _enumOption.default.create({ id: 'EventsBudget' }), _enumOption.default.create({ id: 'EventsNumbers' }), _enumOption.default.create({ id: 'TrainingsCount' }), _enumOption.default.create({ id: 'TrainingsHours' }), _enumOption.default.create({ id: 'TrainingsIntensity' }), _enumOption.default.create({ id: 'TrainingsParticipants' }), _enumOption.default.create({ id: 'TrainingsExpertQualification' }), _enumOption.default.create({ id: 'TrainingsPersonalQualificationLevel' }), _enumOption.default.create({ id: 'TrainingsPersonalHoursTotal' }), _enumOption.default.create({ id: 'TrainingsPersonalHoursYearToDate' }), _enumOption.default.create({ id: 'TrainingsPersonalLastTraining' }), _enumOption.default.create({ id: 'SalesMarketShare' }), _enumOption.default.create({ id: 'SalesMixShare' }), _enumOption.default.create({ id: 'SalesShareOfNetworkSales' }), _enumOption.default.create({ id: 'SalesSegmentLeadership' }), _enumOption.default.create({ id: 'SalesRetail' }), _enumOption.default.create({ id: 'StoresShare' })];

  /**
   * @class Vdc/KpiModel
   * @extends DS.Model
   */
  var KpiModel = Model.extend({

    /**
     * @memberof Vdc/KpiModel
     * @instance
     *
     * @type {Number}
     */
    version: attr('number'),

    /**
     * @memberof Vdc/KpiModel
     * @instance
     *
     * @type {String}
     */
    title: attr('nullable-string'),

    /**
     * @memberof Vdc/KpiModel
     * @instance
     *
     * @type {Number}
     */
    position: attr('number', { defaultValue: 0 }),

    /**
     * @memberof Vdc/KpiModel
     * @instance
     *
     * @type {Number}
     */
    client_id: attr('id'),

    /**
     * @memberof Vdc/KpiModel
     * @instance
     *
     * @type {Boolean}
     */
    deferred: attr('boolean', { readOnly: true }),

    /**
     * @memberof Vdc/KpiModel
     * @instance
     *
     * @type {String}
     */
    explanation: attr('nullable-string'),

    /**
     * @memberof Vdc/KpiModel
     * @instance
     *
     * @type {String}
     */
    kpi_url: attr('nullable-string'),

    /**
     * @memberof Vdc/KpiModel
     * @instance
     *
     * @type {Number}
     */
    kpi_category_id: attr('id'),

    /**
     * @memberof Vdc/KpiModel
     * @instance
     *
     * @type {String}
     */
    kpi_formula_type: attr('enum-string', { options: formulaTypeOptions }),

    /**
     * @memberof Vdc/KpiModel
     * @instance
     *
     * @type {Object|Array}
     */
    kpi_data: attr(),

    /**
     * @memberof Vdc/KpiModel
     * @instance
     *
     * @type {Vdc/KpiCategoryModel}
     */
    kpi_category: belongsTo('vdc/kpi-category'),

    /**
     * @memberof Vdc/KpiModel
     * @instance
     *
     * @type {Vdc/fragments/TypeConfigurationFragment[]}
     */
    data_configuration: (0, _attributes.fragmentArray)('vdc/fragments/type-configuration', { defaultValue: function defaultValue() {
        return [];
      } }),

    /**
     * @memberof Vdc/KpiModel
     * @instance
     *
     * @type {Vdc/fragments/KpiVariantFragment[]}
     */
    variant: (0, _attributes.fragment)('vdc/fragments/kpi-variant')
  });

  KpiModel.reopenClass({
    /**
     * @memberof Vdc/SalesFigureModel
     * @static
     *
     * @type {EnumOption[]}
     */
    formulaTypeOptions: formulaTypeOptions

  });

  exports.default = KpiModel;
});