define("ln-ember-form-elements/templates/components/form-elements/calendar/navigation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RPgQVeZ7",
    "block": "{\"symbols\":[],\"statements\":[[7,\"a\",true],[10,\"class\",\"calendar-navigation__direction previous\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"onDirectionClick\",-1],null]],[8],[9],[0,\"\\n\"],[7,\"a\",true],[10,\"class\",\"calendar-navigation__label label\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"onClickMonth\"],null]],[8],[1,[22,\"label\"],false],[9],[0,\"\\n\"],[7,\"a\",true],[10,\"class\",\"calendar-navigation__direction next\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"onDirectionClick\",1],null]],[8],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ln-ember-form-elements/templates/components/form-elements/calendar/navigation.hbs"
    }
  });

  _exports.default = _default;
});