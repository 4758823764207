define('ln-ember-data-models/models/myliga/fragments/time-entry-meta', ['exports', 'ember-data', 'ember-data-model-fragments/fragment', 'ember-data-model-fragments/attributes'], function (exports, _emberData, _fragment, _attributes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr;
  exports.default = _fragment.default.extend({

    /**
     * @memberof Myliga/fragments/TimeEntryMetaFragment
     * @instance
     *
     * @type {String}
     */
    date_from: attr('date-time-string'),

    /**
     * @memberof Myliga/fragments/TimeEntryMetaFragment
     * @instance
     *
     * @type {String}
     */
    date_till: attr('date-time-string'),

    /**
     * @memberof Myliga/fragments/TimeEntryMetaFragment
     * @instance
     *
     * @type {String}
     */
    comment: attr('nullable-string'),

    /**
     * @memberof Myliga/fragments/TimeEntryMetaFragment
     * @instance
     *
     * @type {String}
     */
    worklog_task_key: attr('nullable-string', { defaultValue: '' }),
    /**
     * @memberof Myliga/fragments/TimeEntryMetaFragment
     * @instance
     *
     * @type {Myliga/fragments/TimeEntryAssignmentOptionFragment}
     */
    assignment_option: (0, _attributes.fragment)('myliga/fragments/time-entry-assignment-option')

  });
});