define('ln-ember-data-models/models/vdc/fragments/kpi-variant', ['exports', 'ember-data', 'ember-data-model-fragments/fragment', 'ln-ember-data-models/utils/enum-option'], function (exports, _emberData, _fragment, _enumOption) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.calculationBaseOptions = exports.calculationTypeOptions = exports.chartTypeOptions = undefined;
  var attr = _emberData.default.attr;


  /**
   * @name vdc/fragments/kpiVariantFragment/chartTypeOptions
   * @type {EnumOption[]}
   */
  var chartTypeOptions = exports.chartTypeOptions = [_enumOption.default.create({ id: 'BarChart' }), _enumOption.default.create({ id: 'CircleChart' }), _enumOption.default.create({ id: 'PieChart' }), _enumOption.default.create({ id: 'TextChart' }), _enumOption.default.create({ id: 'DeltaChart' })];

  /**
   * @name vdc/fragments/kpiVariantFragment/calculationTypeOptions
   * @type {EnumOption[]}
   */
  var calculationTypeOptions = exports.calculationTypeOptions = [_enumOption.default.create({ id: 'Relative' }), _enumOption.default.create({ id: 'Absolute' }), _enumOption.default.create({ id: 'Text' })];

  /**
   * @name vdc/fragments/kpiVariantFragment/calculationBaseOptions
   * @type {Number[]}
   */
  var calculationBaseOptions = exports.calculationBaseOptions = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 200];

  /**
   * @class Vdc/fragments/KpiVariantFragment
   * @extends Fragment
   */
  var KpiVariantFragment = _fragment.default.extend({

    /**
     * @memberof Vdc/fragments/kpiVariantFragment
     * @instance
     *
     * @type {String}
     */
    chart_type: attr('enum-string', { options: chartTypeOptions }),

    /**
     * @memberof Vdc/fragments/kpiVariantFragment
     * @instance
     *
     * @type {String}
     */
    calculation_type: attr('enum-string', { options: calculationTypeOptions }),

    /**
     * @memberof Vdc/fragments/kpiVariantFragment
     * @instance
     *
     * @type {Number}
     */
    calculation_base: attr('number')
  });

  KpiVariantFragment.reopenClass({

    /**
     * @memberof Vdc/fragments/TypeConfigurationFragment
     * @static
     *
     * @type {EnumOption[]}
     */
    chartTypeOptions: chartTypeOptions,

    /**
     * @memberof Vdc/fragments/TypeConfigurationFragment
     * @static
     *
     * @type {EnumOption[]}
     */
    calculationTypeOptions: calculationTypeOptions,

    /**
     * @memberof Vdc/fragments/TypeConfigurationFragment
     * @static
     *
     * @type {[]}
     */
    calculationBaseOptions: calculationBaseOptions

  });

  exports.default = KpiVariantFragment;
});