define('ln-ember-data-models/models/vdc/motif-cropping', ['exports', 'ember-data', 'ln-ember-data-models/utils/enum-option'], function (exports, _emberData, _enumOption) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.statusOptions = undefined;
  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      Model = _emberData.default.Model;


  /**
   * @memberof Vdc/MotifCroppingModel/statusOptions
   * @static
   *
   * @type {EnumOption[]}
   */
  var statusOptions = exports.statusOptions = [_enumOption.default.create({ id: 'Approved' }), _enumOption.default.create({ id: 'Declined' }), _enumOption.default.create({ id: 'Proposed' }), _enumOption.default.create({ id: 'New' })];

  /**
   * @class Vdc/MotifCroppingModel
   * @extends DS.Model
   */
  var MotifCroppingModel = Model.extend({

    /**
     * @memberof Vdc/MotifCroppingModel
     * @instance
     *
     * @type {Number}
     */
    version: attr('number'),

    /**
     * @memberof Vdc/MotifCroppingModel
     * @instance
     *
     * @type {Number}
     */
    motif_id: attr('id'),

    /**
     * @memberof Vdc/MotifCroppingModel
     * @instance
     *
     * @type {Number}
     */
    width: attr('number'),

    /**
     * @memberof Vdc/MotifCroppingModel
     * @instance
     *
     * @type {Number}
     */
    height: attr('number'),

    /**
     * @memberof Vdc/MotifCroppingModel
     * @instance
     *
     * @type {String}
     */
    name: attr('nullable-string'),

    /**
     * @memberof Vdc/MotifCroppingModel
     * @instance
     *
     * @type {Number}
     */
    comments_count: attr('number'),

    /**
     * @memberof Vdc/MotifCroppingModel
     * @instance
     *
     * @type {String}
     */
    comment: attr('nullable-string'),

    /**
     * @memberof Vdc/MotifCroppingModel
     * @instance
     *
     * @type {String}
     */
    status: attr('enum-string', { options: statusOptions }),

    /**
     * @memberof Vdc/MotifCroppingModel
     * @instance
     *
     * @type {String}
     */
    motif_name: attr('nullable-string'),

    /**
     * @memberof Vdc/MotifCroppingModel
     * @instance
     *
     * @type {Object}
     */
    picture: belongsTo('vdc/picture'),

    /**
     * @memberof Vdc/MotifCroppingModel
     * @instance
     *
     * @type {Boolean}
     */
    isApproved: Ember.computed.equal('status', 'Approved'),

    /**
     * @memberof Vdc/MotifCroppingModel
     * @instance
     *
     * @type {Boolean}
     */
    isDeclied: Ember.computed.equal('status', 'Declined'),

    /**
     * @memberof Vdc/MotifCroppingModel
     * @instance
     *
     * @type {Boolean}
     */
    isProposed: Ember.computed.equal('status', 'Proposed'),

    /**
     * @memberof Vdc/MotifCroppingModel
     * @instance
     *
     * @type {Boolean}
     */
    isNew: Ember.computed.equal('status', 'New')
  });

  MotifCroppingModel.reopenClass({

    /**
     * @memberof Vdc/MotifCroppingModel
     * @static
     *
     * @type {EnumOption[]}
     */
    statusOptions: statusOptions

  });

  exports.default = MotifCroppingModel;
});