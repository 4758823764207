define('ln-ember-data-models/models/vdc/project-participant', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr,
      Model = _emberData.default.Model;
  exports.default = Model.extend({

    /**
     * @memberof Vdc/ProjectParticipantModel
     * @instance
     *
     * @type {Number}
     */
    version: attr('number'),

    /**
     * @memberof Vdc/ProjectParticipantModel
     * @instance
     *
     * @type {Number}
     */
    project_id: attr('id'),

    /**
     * @memberof Vdc/ProjectParticipantModel
     * @instance
     *
     * @type {Number}
     */
    market_id: attr('id'),

    /**
     * @memberof Vdc/ProjectParticipantModel
     * @instance
     *
     * @type {Number}
     */
    country_id: attr('id'),

    /**
     * @memberof Vdc/ProjectParticipantModel
     * @instance
     *
     * @type {Number}
     */
    store_id: attr('id'),

    /**
     * @memberof Vdc/ProjectParticipantModel
     * @instance
     *
     * @type {Number}
     */
    window_id: attr('id')
  });
});