define('ln-ember-data-models/models/vdc/comment', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      Model = _emberData.default.Model;
  exports.default = Model.extend({

    /**
     * @memberof Vdc/CommentModel
     * @instance
     *
     * @type {Number}
     */
    version: attr('number'),

    /**
     * @memberof Vdc/CommentModel
     * @instance
     *
     * @type {Number}
     */
    context_id: attr('id'),

    /**
     * Optional, needed when context_type is `TextModuleGroup`
     *
     * @memberof Vdc/CommentModel
     * @instance
     *
     * @type {Number}
     */
    country_id: attr('id'),

    /**
     * Optional, needed when context_type is `TextModuleGroup`
     *
     * @memberof Vdc/CommentModel
     * @instance
     *
     * @type {Number}
     */
    language_id: attr('id'),

    /**
     * @memberof Vdc/CommentModel
     * @instance
     *
     * @type {String}
     */
    context_type: attr('nullable-string'),

    /**
     * @memberof Vdc/CommentModel
     * @instance
     *
     * @type {String}
     */
    message: attr('nullable-string'),

    /**
     * @memberof Vdc/CommentModel
     * @instance
     *
     * @type {String}
     */
    publish_time: attr('date-time-string'),

    /**
     * @memberof Vdc/CommentModel
     * @instance
     *
     * @type {Number}
     */
    author_id: attr('id'),

    /**
     * @memberof Vdc/CommentModel
     * @instance
     *
     * @type {Object}
     */
    author: belongsTo('vdc/user')
  });
});