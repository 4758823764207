define('ln-ember-data-models/transforms/date-time-string', ['exports', 'ember-data', 'moment'], function (exports, _emberData, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Transform = _emberData.default.Transform;
  exports.default = Transform.extend({
    deserialize: function deserialize(serialized) {
      return serialized;
    },
    serialize: function serialize(deserialized) {
      if (!deserialized || !(0, _moment.default)(deserialized).isValid()) {
        return null;
      }

      return deserialized;
    }
  });
});