define('ln-ember-data-models/models/vdc/text-modules-view', ['exports', 'ember-data', 'ember-inflector', 'ember-data-model-fragments/attributes'], function (exports, _emberData, _emberInflector, _attributes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany,
      Model = _emberData.default.Model;


  _emberInflector.default.inflector.uncountable('text_modules_view');

  /**
   * @class Vdc/TextModulesViewModel
   * @extends DS.Model
   */
  exports.default = Model.extend({

    /**
     * @memberof Vdc/TextModuleModel
     * @instance
     *
     * @type {Number}
     */
    version: attr('number'),

    /**
     * @memberof Vdc/TextModuleModel
     * @instance
     *
     * @type {String}
     */
    name: attr('nullable-string'),

    /**
     * @memberof Vdc/TextModuleModel
     * @instance
     *
     * @type {Number}
     */
    client_id: attr('id'),

    /**
     * @memberof Vdc/TextModuleModel
     * @instance
     *
     * @type {Number}
     */
    project_id: attr('id'),

    /**
     * @memberof Vdc/TextModuleModel
     * @instance
     *
     * @type {Number}
     */
    text_module_group_id: attr('id'),

    /**
     * @memberof Vdc/TextModuleModel
     * @instance
     *
     * @type {Vdc/TextModuleGroupModel}
     */
    text_module_group_name: attr('nullable-string'),

    /**
     * @memberof Vdc/TextModuleModel
     * @instance
     *
     * @type {String}
     */
    text: attr('nullable-string'),

    /**
     * @memberof Vdc/TextModuleModel
     * @instance
     *
     * @type {Number}
     */
    text_module_category_id: attr('id'),

    /**
     * @memberof Vdc/TextModuleModel
     * @instance
     *
     * @type {Vdc/ProjectModel}
     */
    project: belongsTo('vdc/project'),

    /**
     * @memberof Vdc/TextModuleModel
     * @instance
     *
     * @type {Vdc/TextModuleCategoryModel}
     */
    text_module_category: belongsTo('vdc/text-module-category'),

    /**
     * @memberof Vdc/TextModuleModel
     * @instance
     *
     * @type {Vdc/Picture[]}
     */
    pictures: hasMany('vdc/picture', { async: false }),

    /**
     * @memberof Vdc/TextModuleModel
     * @instance
     *
     * @type {Vdc/TextModuleTranslation[]}
     */
    text_module_translations: hasMany('vdc/text-module-translation', { async: false }),

    /**
     * @memberof Vdc/TextModuleModel
     * @instance
     *
     * @type {Vdc/TextModuleModel[]}
     */
    text_modules: hasMany('vdc/text-module', { async: false }),

    /**
     * @memberof Vdc/TextModuleModel
     * @instance
     *
     * @type {Vdc/fragments/TextModuleGroupCommentLinkFragment[]}
     */
    text_module_group_comment_links: (0, _attributes.fragmentArray)('vdc/fragments/text-module-group-comment-link')

  });
});