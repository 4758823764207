define("ln-ember-toolkit/utils/format-compact-number", ["exports", "ln-ember-toolkit/utils/format-number"], function (_exports, _formatNumber) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = formatCompactNumber;

  function formatCompactNumber(val, options) {
    if (val === null || typeof val === 'undefined') {
      return '';
    }

    var strVal = String(val).trim();

    if (!(0, _formatNumber.isNumeric)(strVal)) {
      return strVal;
    }

    var parsed = round(Number(strVal));
    var rounded = roundCompact(parsed);
    var compact = getCompactNumber(rounded);
    var abbr = getAbbr(rounded);
    var localized = (0, _formatNumber.default)(compact, options);
    return "".concat(localized).concat(abbr);
  }

  function round(value) {
    return (value >= 0 ? 1 : -1) * Math.round(Math.abs(value));
  }

  function preciseToFirstDecimal(num) {
    return round(num * 10) / 10;
  }

  function roundNumberByDecimal(num) {
    var roundFrom = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0.899;

    if (Math.abs(num % 1.0) >= roundFrom) {
      return round(num);
    }

    return num;
  }

  function roundCompact(num, div) {
    if (!div) {
      if (Math.abs(num) >= 999500) {
        return roundCompact(num, 1000000);
      }

      if (Math.abs(num) >= 1000) {
        return roundCompact(num, 1000);
      }

      if (Math.abs(num) >= 999) {
        return roundCompact(num, 100);
      }

      if (Math.abs(num) >= 99.9) {
        return roundCompact(num, 10);
      }

      return roundCompact(num, 1);
    }

    var compact = preciseToFirstDecimal(num / div);
    var rounded = roundNumberByDecimal(compact);
    return rounded * div;
  }

  function getCompactNumber(num) {
    var maxDigits = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 3;
    var compacted;

    if (Math.abs(num) >= 1000000) {
      compacted = preciseToFirstDecimal(num / 1000000);
    } else if (Math.abs(num) >= 1000) {
      compacted = preciseToFirstDecimal(num / 1000);
    } else {
      compacted = preciseToFirstDecimal(num);
    }

    var digitsLength = String(compacted).match(/\d/g).length;
    return digitsLength > maxDigits ? round(compacted) : compacted;
  }

  function getAbbr(num) {
    if (Math.abs(num) >= 1000000) {
      return 'm';
    }

    if (Math.abs(num) >= 1000) {
      return 'k';
    }

    return '';
  }
});