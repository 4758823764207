define("ln-ember-form-elements/templates/components/form-elements/styled-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "A0bL3/qh",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"label\"]]],null,{\"statements\":[[7,\"label\",true],[11,\"for\",[22,\"inputId\"]],[8],[1,[22,\"label\"],false],[9]],\"parameters\":[]},null],[0,\"\\n\\n\"],[1,[28,\"input\",null,[[\"type\",\"id\",\"value\",\"placeholder\",\"maxlength\",\"autofocus\",\"autocomplete\",\"focus-in\",\"focus-out\",\"wheel\",\"key-press\",\"key-down\",\"key-up\",\"readonly\",\"disabled\"],[[24,[\"type\"]],[24,[\"inputId\"]],[24,[\"inputValue\"]],[24,[\"placeholder\"]],[24,[\"maxLength\"]],[24,[\"autoFocus\"]],[24,[\"autocomplete\"]],[28,\"action\",[[23,0,[]],\"focusIn\"],null],[28,\"action\",[[23,0,[]],\"focusOut\"],null],[28,\"action\",[[23,0,[]],\"wheel\"],null],[24,[\"onKeyPress\"]],[24,[\"onKeyDown\"]],[24,[\"onKeyUp\"]],[24,[\"readonlyAttribute\"]],[24,[\"disabled\"]]]]],false],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"maxLength\"]]],null,{\"statements\":[[0,\"  \"],[7,\"span\",true],[11,\"class\",[29,[\"characters-countdown \",[28,\"if\",[[24,[\"limitExceeded\"]],\"limit-exceeded\"],null]]]],[8],[1,[22,\"remainingCharacters\"],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[24,[\"errorMessage\"]]],null,{\"statements\":[[0,\"  \"],[7,\"span\",true],[10,\"class\",\"error-message\"],[8],[1,[22,\"errorMessage\"],false],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ln-ember-form-elements/templates/components/form-elements/styled-input.hbs"
    }
  });

  _exports.default = _default;
});