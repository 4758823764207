define('ln-ember-data-models/models/vdc/role-assignment', ['exports', 'ember-data', 'ln-ember-data-models/utils/enum-option'], function (exports, _emberData, _enumOption) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.contextTypeOptions = undefined;
  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      Model = _emberData.default.Model;


  /**
   * @name Vdc/RoleAssignmentModel/contextTypeOptions
   * @type {EnumOption[]}
   */
  var contextTypeOptions = exports.contextTypeOptions = [_enumOption.default.create({ id: 'General' }), _enumOption.default.create({ id: 'Project' }), _enumOption.default.create({ id: 'Market' }), _enumOption.default.create({ id: 'Store' }), _enumOption.default.create({ id: 'Event' })];

  /**
   * @class Vdc/RoleAssignmentModel
   * @extends DS.Model
   */
  var RoleAssignmentModel = Model.extend({

    /**
     * @memberof Vdc/RoleAssignmentModel
     * @instance
     *
     * @type {Number}
     */
    version: attr('number'),

    /**
     * @memberof Vdc/RoleAssignmentModel
     * @instance
     *
     * @type {Number}
     */
    user_id: attr('id'),

    /**
     * @memberof Vdc/RoleAssignmentModel
     * @instance
     *
     * @type {Number}
     */
    role_id: attr('id'),

    /**
     * @memberof Vdc/RoleAssignmentModel
     * @instance
     *
     * @type {Number}
     */
    client_id: attr('id'),

    /**
     * @memberof Vdc/RoleAssignmentModel
     * @instance
     *
     * @type {Number}
     */
    context_id: attr('id'),

    /**
     * @memberof Vdc/RoleAssignmentModel
     * @instance
     *
     * @type {String}
     */
    context_type: attr('enum-string', { options: contextTypeOptions }),

    /**
     * @memberof Vdc/RoleAssignmentModel
     * @instance
     *
     * @type {Vdc/RoleModel}
     */
    role: belongsTo('vdc/role')

  });

  RoleAssignmentModel.reopenClass({

    /**
     * @memberof Vdc/RoleAssignmentModel
     * @static
     *
     * @type {EnumOption[]}
     */
    contextTypeOptions: contextTypeOptions

  });

  exports.default = RoleAssignmentModel;
});