define('ln-ember-data-models/models/myliga/fragments/picture', ['exports', 'ember-data', 'ember-data-model-fragments/fragment'], function (exports, _emberData, _fragment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr;
  exports.default = _fragment.default.extend({

    /**
     * @memberof Myliga/fragments/PictureFragment
     * @instance
     *
     * @type {string}
     */
    url: attr('nullable-string'),

    /**
     * @memberof Myliga/fragments/PictureFragment
     * @instance
     *
     * @type {string}
     */
    preview_url: attr('nullable-string')
  });
});