define('ln-ember-data-models/models/vdc/store', ['exports', 'ember-data', 'ember-data-model-fragments/attributes', 'ln-ember-data-models/utils/enum-option'], function (exports, _emberData, _attributes, _enumOption) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.storeStatuses = exports.assemblyTargetTypes = exports.aggregatedAssembliesStatuses = undefined;

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var Model = _emberData.default.Model,
      belongsTo = _emberData.default.belongsTo,
      attr = _emberData.default.attr,
      hasMany = _emberData.default.hasMany;


  /**
   * @name Vdc/StoreModel/aggregatedAssembliesStatuses
   * @type {EnumOption[]}
   */
  var aggregatedAssembliesStatuses = exports.aggregatedAssembliesStatuses = [_enumOption.default.create({ id: 'NotAssembled' }), _enumOption.default.create({ id: 'Assembled' }), _enumOption.default.create({ id: 'Approved' }), _enumOption.default.create({ id: 'NotApproved' }), _enumOption.default.create({ id: 'Estimated' }), _enumOption.default.create({ id: 'AdaptWithComments' }), _enumOption.default.create({ id: 'Revised' }), _enumOption.default.create({ id: 'Ordered' }), _enumOption.default.create({ id: 'Proposal' })];

  /**
   * @name Vdc/StoreModel/assemblyTargetTypes
   * @type {EnumOption[]}
   */
  var assemblyTargetTypes = exports.assemblyTargetTypes = [_enumOption.default.create({ id: 'Fixture' }), _enumOption.default.create({ id: 'Store' }), _enumOption.default.create({ id: 'Window' })];

  /**
   * @name Vdc/StoreModel/storeStatuses
   * @type {EnumOption[]}
   */
  var storeStatuses = exports.storeStatuses = [{ id: 'closed', name: 'Closed' }, { id: 'in_progress', name: 'In Progress' }, { id: 'opened', name: 'Open' }];

  /**
   * @class Vdc/StoreModel
   * @extends DS.Model
   */
  var StoreModel = Model.extend({

    /**
     * @memberof Vdc/StoreModel
     * @instance
     *
     * @type {Number}
     */
    version: attr('number'),

    /**
     * @memberof Vdc/StoreModel
     * @instance
     *
     * @type {String}
     */
    name: attr('nullable-string'),

    /**
     * @memberof Vdc/StoreModel
     * @instance
     *
     * @type {Number}
     */
    store_type_id: attr('id'),

    /**
     * @memberof Vdc/StoreModel
     * @instance
     *
     * @type {Number}
     */
    store_subtype_id: attr('id'),

    /**
     * @memberof Vdc/StoreModel
     * @instance
     *
     * @type {String}
     */
    store_type_name: attr('nullable-string'),

    /**
     * @memberof Vdc/StoreModel
     * @instance
     *
     * @type {String}
     */
    store_subtype_name: attr('nullable-string'),

    /**
     * @memberof Vdc/StoreModel
     * @instance
     *
     * @type {String}
     */
    opening_date: attr('date-string'),

    /**
     * @memberof Vdc/StoreModel
     * @instance
     *
     * @type {String}
     */
    closing_date: attr('date-string'),

    /**
     * @memberof Vdc/StoreModel
     * @instance
     *
     * @type {String}
     */
    number: attr('nullable-string'),

    /**
     * @memberof Vdc/StoreModel
     * @instance
     *
     * @type {Number}
     */
    client_id: attr('id'),

    /**
     * @memberof Vdc/StoreModel
     * @instance
     *
     * @type {transforms/number-array}
     */
    language_ids: attr('number-array', { defaultValue: function defaultValue() {
        return [];
      } }),

    /**
     * @memberof Vdc/StoreModel
     * @instance
     *
     * @type {String}
     */
    delivery_number: attr('nullable-string'),

    /**
     * @memberof Vdc/StoreModel
     * @instance
     *
     * @type {String}
     */
    last_pos_update: attr('nullable-string'),

    /**
     * @memberof Vdc/StoreModel
     * @instance
     *
     * @type {String}
     */
    last_pos_update_completed: attr('nullable-string'),

    /**
     * @memberof Vdc/StoreModel
     * @instance
     *
     * @type {EnumString}
     */
    status: attr('enum-string', { options: storeStatuses }),

    /**
     * @memberof Vdc/StoreModel
     * @instance
     *
     * @type {Boolean}
     */
    default_address_is_delivery_address: attr('boolean', { defaultValue: true }),

    /**
     * @memberof Vdc/StoreModel
     * @instance
     *
     * @type {Boolean}
     */
    default_address_is_invoice_address: attr('boolean', { defaultValue: true }),

    /**
     * @memberof Vdc/StoreModel
     * @instance
     *
     * @type {Number}
     */
    default_address_id: attr('id'),

    /**
     * @memberof Vdc/StoreModel
     * @instance
     *
     * @type { Number }
     */
    delivery_address_id: attr('id'),

    /**
     * @memberof Vdc/StoreModel
     * @instance
     *
     * @type { Number }
     */
    invoice_address_id: attr('id'),

    /**
     * @memberof Vdc/StoreModel
     * @instance
     *
     * @type {Number}
     */
    budget: attr('number'),

    /**
     * @memberof Vdc/StoreModel
     * @instance
     *
     * @type {String}
     */
    customer_identifier: attr('nullable-string'),

    /**
     * @memberof Vdc/StoreModel
     * @instance
     *
     * @type {String}
     */
    aggregated_assembly_status: attr('enum-string', { options: aggregatedAssembliesStatuses }),

    /**
     * @memberof Vdc/StoreModel
     * @instance
     *
     * @type {Number}
     */
    aggregated_assembly_article_amount: attr('number'),

    /**
     * @memberof Vdc/StoreModel
     * @instance
     *
     * API sends a fragment of this attribute set, with only those attributes that belong to store_type
     * but with still the same id
     * If we set this as `belongsTo('vdc/attribute-set')`, then EmberData will overwrite the values.
     *
     * @type {Vdc/AttributeSetModel}
     */
    attribute_set: attr(),

    /**
     * @memberof Vdc/StoreModel
     * @instance
     *
     * @type {Vdc/AddressModel}
     */
    default_address: belongsTo('vdc/address', { async: false }),

    /**
     * @memberof Vdc/StoreModel
     * @instance
     *
     * @type {Vdc/MarketModel}
     */
    market: belongsTo('vdc/market', { async: false }),

    /**
     * @memberof Vdc/StoreModel
     * @instance
     *
     * @type {Vdc/AssemblyModel}
     */
    assembly: belongsTo('vdc/assembly'),

    /**
     * @memberof Vdc/StoreModel
     * @instance
     *
     * @type {Vdc/AddressModel}
     */
    delivery_address: belongsTo('vdc/address', { async: false }),

    /**
     * @memberof Vdc/StoreModel
     * @instance
     *
     * @type {Vdc/AddressModel}
     */
    invoice_address: belongsTo('vdc/address', { async: false }),

    /**
     * @memberof Vdc/StoreModel
     * @instance
     *
     * @type {Vdc/ContractModel}
     */
    contracts: hasMany('vdc/contract', { async: true }),

    /**
     * @memberof Vdc/StoreModel
     * @instance
     *
     * @type {Array}
     */
    pictures: hasMany('vdc/picture', { async: false }),

    /**
     * @memberof Vdc/StoreModel
     * @instance
     *
     * @type {Vdc/fragments/AttributeValueFragment[]}
     */
    attribute_values: (0, _attributes.fragmentArray)('vdc/fragments/attribute-value'),

    /**
     * @memberof Vdc/StoreModel
     * @instance
     *
     * @type {Boolean}
     */
    isClosed: Ember.computed('closing_date', function () {
      var closingDate = this.get('closing_date');

      var _toJSON$split = new Date().toJSON().split('T'),
          _toJSON$split2 = _slicedToArray(_toJSON$split, 1),
          today = _toJSON$split2[0];

      // To get the timestamp as '2017-01-24'

      if (!closingDate) {
        return false;
      }

      return closingDate < today;
    }),

    /**
     * @memberof Vdc/StoreModel
     * @instance
     *
     * @type {Boolean}
     */
    isInProgress: Ember.computed('opening_date', function () {
      var openingDate = this.get('opening_date');

      var _toJSON$split3 = new Date().toJSON().split('T'),
          _toJSON$split4 = _slicedToArray(_toJSON$split3, 1),
          today = _toJSON$split4[0];

      // To get the timestamp as '2017-01-24'

      if (!openingDate) {
        return false;
      }

      return openingDate > today;
    })

  });

  StoreModel.reopenClass({

    /**
     * @memberof Vdc/StoreModel
     * @instance
     *
     * @type {EnumOption[]}
     */
    aggregatedAssembliesStatuses: aggregatedAssembliesStatuses,

    /**
     * @memberof Vdc/StoreModel
     * @instance
     *
     * @type {EnumOption[]}
     */
    assemblyTargetTypes: assemblyTargetTypes,

    /**
     * @memberof Vdc/StoreModel
     * @instance
     *
     * @type {EnumOption[]}
     */
    storeStatuses: storeStatuses

  });

  exports.default = StoreModel;
});