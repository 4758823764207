define('ln-ember-data-models/models/vdc/fixture-type', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr,
      hasMany = _emberData.default.hasMany,
      Model = _emberData.default.Model;
  exports.default = Model.extend({

    /**
     * @memberof Vdc/FixtureTypeModel
     * @instance
     *
     * @type {Number}
     */
    version: attr('number'),

    /**
     * @memberof Vdc/FixtureTypeModel
     * @instance
     *
     * @type {String}
     */
    name: attr('nullable-string'),

    /**
     * @memberof Vdc/FixtureTypeModel
     * @instance
     *
     * @type {Number}
     */
    client_id: attr('id'),

    /**
     * @memberof Vdc/FixtureTypeModel
     * @instance
     *
     * @type {Number}
     */
    attribute_set_id: attr('id'),

    /**
     * @memberof Vdc/FixtureTypeModel
     * @instance
     *
     * @type {Array}
     */
    attribute_ids: attr('number-array', { defaultValue: function defaultValue() {
        return [];
      } }),

    /**
     * @memberof Vdc/FixtureTypeModel
     * @instance
     *
     * @type {Vdc/Picture[]}
     */
    pictures: hasMany('vdc/picture')
  });
});