define('ln-ember-data-models/models/vdc/cost-rule', ['exports', 'ember-data', 'ln-ember-data-models/utils/enum-option'], function (exports, _emberData, _enumOption) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.replacePriceOptions = exports.typeOptions = undefined;
  var attr = _emberData.default.attr,
      hasMany = _emberData.default.hasMany,
      Model = _emberData.default.Model;


  /**
   * @name Vdc/CostRuleModel/typeOptions
   * @type {EnumOption[]}
   */
  var typeOptions = exports.typeOptions = [_enumOption.default.create({ id: 'PerOrder' }), _enumOption.default.create({ id: 'PerArticle' })];

  /**
   * TODO Add documentation: What is this used for?
   *
   * TODO Should probably look like this:
   *
   * ```
   * EnumOption.create({ id: true,  name: loc('Create Additional Cost') })
   * EnumOption.create({ id: false: name: loc('Replace Article Price') }
   * ```
   *
   * @name Vdc/CostRuleModel/replacePriceOptions
   * @type {EnumOption[]}
   */
  var replacePriceOptions = exports.replacePriceOptions = [_enumOption.default.create({ id: 'CreateAdditionalCost' }), _enumOption.default.create({ id: 'ReplaceArticlePrice' })];

  /**
   * @class Vdc/CostRuleModel
   * @extends DS.Model
   */
  var CostRuleModel = Model.extend({

    /**
     * @memberof Vdc/CostRuleModel
     * @instance
     *
     * @type {Number}
     */
    version: attr('number'),

    /**
     * @memberof Vdc/CostRuleModel
     * @instance
     *
     * @type {String}
     */
    name: attr('nullable-string'),

    /**
     * @memberof Vdc/CostRuleModel
     * @instance
     *
     * @type {String}
     */
    group_name: attr('nullable-string'),

    /**
     * @memberof Vdc/CostRuleModel
     * @instance
     *
     * @type {String}
     */
    description: attr('nullable-string'),

    /**
     * @memberof Vdc/CostRuleModel
     * @instance
     *
     * @type {Number}
     */
    priority: attr('number'),

    /**
     * @memberof Vdc/CostRuleModel
     * @instance
     *
     * @type {Boolean}
     */
    active: attr('boolean', { defaultValue: function defaultValue() {
        return true;
      } }),

    /**
     * @memberof Vdc/CostRuleModel
     * @instance
     *
     * @type {String}
     */
    type: attr('enum-string', {
      options: typeOptions,
      defaultValue: 'PerOrder'
    }),

    /**
     * @memberof Vdc/CostRuleModel
     * @instance
     *
     * @type {Boolean}
     */
    replace_price: attr('boolean', { defaultValue: false }),

    /**
     * @memberof Vdc/CostRuleModel
     * @instance
     *
     * @type {Number}
     */
    project_id: attr('id'),

    /**
     *
     * @memberof Vdc/CostRuleModel
     * @instance
     *
     * @type {Vdc/CostRulePosition[]}
     */
    cost_rule_positions: hasMany('vdc/cost-rule-position', { async: false }),

    /**
     *
     * @memberof Vdc/CostRuleModel
     * @instance
     *
     * @type {Vdc/CostRuleCondition[]}
     */
    conditions: hasMany('vdc/cost-rule-condition', { async: false })

  });

  CostRuleModel.reopenClass({

    /**
     * @memberof Vdc/CostRuleModel
     * @static
     *
     * @type {EnumOption[]}
     */
    typeOptions: typeOptions,

    /**
     * TODO should be renamed to `replacePriceOptions`
     *
     * @memberof Vdc/CostRuleModel
     * @static
     *
     * @type {EnumOption[]}
     */
    replaceOptions: replacePriceOptions

  });

  exports.default = CostRuleModel;
});