define('ln-ember-data-models/models/myliga/talent-category', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr,
      hasMany = _emberData.default.hasMany,
      Model = _emberData.default.Model;
  exports.default = Model.extend({

    /**
     * @memberof Myliga/TalentCategoryModel
     * @instance
     *
     * @type {Number}
     */
    version: attr('number'),

    /**
     * @memberof Myliga/TalentCategoryModel
     * @instance
     *
     * @type {String}
     */
    name: attr('nullable-string'),

    /**
     * @memberof Myliga/TalentCategoryModel
     * @instance
     *
     * @type {Number}
     */
    position: attr('number'),

    /**
     * @memberof Myliga/TalentCategoryModel
     * @instance
     *
     * @type {Myliga/TalentModel[]}
     */
    talents: hasMany('myliga/talent')

  });
});