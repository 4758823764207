define('ln-ember-data-models/models/myliga/compensation-request', ['exports', 'ember-data', 'ln-ember-data-models/utils/enum-option', 'ember-data-model-fragments/attributes'], function (exports, _emberData, _enumOption, _attributes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.statusOptions = undefined;
  var attr = _emberData.default.attr,
      Model = _emberData.default.Model;


  /**
   * @name Myliga/CompensationRequestModel/statusOptions
   * @type {EnumOption[]}
   */
  var statusOptions = exports.statusOptions = [_enumOption.default.create({ id: 'Verify' }), _enumOption.default.create({ id: 'Declined' }), _enumOption.default.create({ id: 'Approved' }), _enumOption.default.create({ id: 'Canceled' })];

  /**
   * @class Myliga/CompensationRequestModel
   * @extends DS.Model
   */
  var CompensationRequestModel = Model.extend({

    /**
     * @memberof Myliga/CompensationRequestModel
     * @instance
     *
     * @type {Number}
     */
    version: attr('number'),

    /**
     * @memberof Myliga/CompensationRequestModel
     * @instance
     *
     * @type {Number}
     */
    user_id: attr('id'),

    /**
     * @memberof Myliga/CompensationRequestModel
     * @instance
     *
     * @type {String}
     */
    date_from: attr('date-string'),

    /**
     * @memberof Myliga/CompensationRequestModel
     * @instance
     *
     * @type {String}
     */
    date_till: attr('date-string'),

    /**
     * @memberof Myliga/CompensationRequestModel
     * @instance
     *
     * @type {Number}
     */
    compensation_days: attr('number'),

    /**
     * @memberof Myliga/CompensationRequestModel
     * @instance
     *
     * @type {String}
     */
    status: attr('enum-string', {
      options: statusOptions,
      defaultValue: 'Verify'
    }),

    /**
     * @memberof Myliga/CompensationRequestModel
     * @instance
     *
     * @type {Boolean}
     */
    status_changes_allowed: attr('boolean'),

    /**
     * @memberof Myliga/CompensationRequestModel
     * @instance
     *
     * @type {String}
     */
    request_reason: attr('nullable-string'),

    /**
     * @memberof Myliga/CompensationRequestModel
     * @instance
     *
     * @type {String}
     */
    status_reason: attr('nullable-string'),

    /**
     * @memberof Myliga/CompensationRequestModel
     * @instance
     *
     * @type {Number}
     */
    liga3000_project_id: attr('id'),

    /**
     * @memberof Myliga/CompensationRequestModel
     * @instance
     *
     * @type {String}
     */
    date_submitted: attr('date-string'),

    /**
     * @memberof Myliga/CompensationRequestModel
     * @instance
     *
     * @type {MyLiga/fragments/UserMeta}
     */
    user_meta: (0, _attributes.fragment)('myliga/fragments/user-meta')

  });

  CompensationRequestModel.reopenClass({

    /**
     * @memberof Myliga/CompensationRequestModel
     * @static
     *
     * @type {EnumOption[]}
     */
    statusOptions: statusOptions

  });

  exports.default = CompensationRequestModel;
});