define('ln-ember-data-models/models/vdc/revision', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      Model = _emberData.default.Model;
  exports.default = Model.extend({

    /**
     * @memberof Vdc/RevisionModel
     * @instance
     *
     * @type {Number}
     */
    version: attr('number'),

    /**
     * @memberof Vdc/RevisionModel
     * @instance
     *
     * @type {String}
     */
    comment: attr('nullable-string'),

    /**
     * @memberof Vdc/RevisionModel
     * @instance
     *
     * @type {String}
     */
    date_added: attr('date-string'),

    /**
     * @memberof Vdc/RevisionModel
     * @instance
     *
     * @type {String}
     */
    date_created: attr('date-time-string'),

    /**
     * @memberof Vdc/RevisionModel
     * @instance
     *
     * @type {String}
     */
    file_type: attr('nullable-string'),

    /**
     * @memberof Vdc/RevisionModel
     * @instance
     *
     * @type {String}
     */
    filename: attr('nullable-string'),

    /**
     * @memberof Vdc/RevisionModel
     * @instance
     *
     * @type {Number}
     */
    filesize: attr('number'),

    /**
     * @memberof Vdc/RevisionModel
     * @instance
     *
     * @type {Boolean}
     */
    preview_ready: attr('boolean', { defaultValue: function defaultValue() {
        return false;
      } }),

    /**
     * @memberof Vdc/RevisionModel
     * @instance
     *
     * @type {String}
     */
    preview_url: attr('nullable-string'),

    /**
     * @memberof Vdc/RevisionModel
     * @instance
     *
     * @type {String[]}
     */
    preview_urls: attr({ defaultValue: function defaultValue() {
        return [];
      } }),

    /**
     * @memberof Vdc/RevisionModel
     * @instance
     *
     * @type {Number}
     */
    revision: attr('number'),

    /**
     * @memberof Vdc/RevisionModel
     * @instance
     *
     * @type {String}
     */
    url: attr('nullable-string'),

    /**
     * @memberof Vdc/RevisionModel
     * @instance
     *
     * @type {Number}
     */
    user_id: attr('id'),

    /**
     * @memberof Vdc/RevisionModel
     * @instance
     *
     * @type {Boolean}
     */
    gallery: attr('boolean', { defaultValue: false }),

    /**
     * @memberof Vdc/RevisionModel
     * @instance
     *
     * @type {Vdc/UserModel}
     */
    user: belongsTo('vdc/user'),

    /**
     * @memberof Vdc/RevisionModel
     * @instance
     *
     * @type {Boolean}
     */
    isPDF: Ember.computed.equal('file_type', 'PDF')
  });
});