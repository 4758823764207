define("ln-ember-form-elements/templates/components/form-elements/dropdown-button/button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TFx18yK7",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"form-elements/styled-button\",null,[[\"action\",\"theme\",\"disabled\",\"loading\"],[[28,\"unless\",[[24,[\"hasDropdown\"]],[24,[\"action\"]],[24,[\"toggleDropdown\"]]],null],[28,\"if\",[[24,[\"hasDropdown\"]],\"light-highlight\",\"light\"],null],[24,[\"disabled\"]],[24,[\"loading\"]]]],{\"statements\":[[0,\"  \"],[14,1],[0,\"\\n\"],[4,\"if\",[[24,[\"hasDropdown\"]]],null,{\"statements\":[[0,\"    \"],[7,\"span\",true],[11,\"class\",[29,[\"dropdown-arrow \",[28,\"if\",[[24,[\"isOpen\"]],\"up\"],null]]]],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ln-ember-form-elements/templates/components/form-elements/dropdown-button/button.hbs"
    }
  });

  _exports.default = _default;
});