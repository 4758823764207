define("ln-ember-form-elements/templates/components/form-elements/expanding-textarea", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Hfe393bG",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"label\"]]],null,{\"statements\":[[7,\"label\",true],[11,\"for\",[28,\"concat\",[[24,[\"elementId\"]],\"-textarea\"],null]],[8],[1,[22,\"label\"],false],[9]],\"parameters\":[]},null],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"textarea-wrapper\"],[8],[0,\"\\n  \"],[1,[28,\"textarea\",null,[[\"id\",\"placeholder\",\"readonly\",\"value\",\"maxlength\",\"focus-in\",\"focus-out\",\"key-up\"],[[28,\"concat\",[[24,[\"elementId\"]],\"-textarea\"],null],[24,[\"placeholder\"]],[24,[\"readonly\"]],[24,[\"value\"]],[24,[\"maxLength\"]],[28,\"action\",[[23,0,[]],\"focusIn\"],null],[28,\"action\",[[23,0,[]],\"focusOut\"],null],[28,\"action\",[[23,0,[]],\"keyUp\"],null]]]],false],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"maxLength\"]]],null,{\"statements\":[[0,\"    \"],[7,\"span\",true],[11,\"class\",[29,[\"characters-countdown \",[28,\"if\",[[24,[\"limitExceeded\"]],\"limit-exceeded\"],null]]]],[8],[1,[22,\"remainingCharacters\"],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ln-ember-form-elements/templates/components/form-elements/expanding-textarea.hbs"
    }
  });

  _exports.default = _default;
});