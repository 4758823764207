define("ln-ember-toolkit/services/host", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @deprecated
   * @class LigaOS
   * @extends Ember.Service
   */
  var _default = Ember.Service.extend({
    store: Ember.inject.service(),

    /**
     * @memberof LigaOS
     * @instance
     *
     * @property {Number}
     */
    clientId: null,

    /**
     * @memberof LigaOS
     * @instance
     *
     * @return {Array}
     */
    clients: null,

    /**
     * @memberof LigaOS
     * @instance
     *
     * @return {Array}
     */
    loadClients: function loadClients() {
      var _this = this;

      if (this.clients && this.clients.length) {
        return Ember.RSVP.resolve(this.clients);
      }

      return this.store.findAll('vdc/client').then(function (clients) {
        _this.set('clients', clients);

        return clients;
      });
    },
    init: function init() {
      this._super.apply(this, arguments);

      this.clients = [];
    }
  });

  _exports.default = _default;
});