define("ln-ember-form-elements/services/form-elements", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class FormElementsService
   * @extends Ember/Service
   */
  var _default = Ember.Service.extend({
    defaults: {
      formatDate: 'YYYY-MM-DD',
      formatDateTime: 'YYYY-MM-DD HH:mm',
      formatDateWeek: 'YYYY-[CW]WW',
      theme: 'light',
      removeSuccessAfter: 5000,
      removeWarningAfter: null,
      removeErrorAfter: null,
      minimumResultsForSearch: 10
    },

    /**
     * Allows to override certain default values for all form elements.
     *
     * ### Example:
     *
     * ```JavaScript
     * this.get('formElements').setDefaults({
     *   formatDate: 'YYYY/MM/DD',
     *   theme: 'dark'
     * })
     * ```
     *
     * Default values are:
     *
     * ```JavaScript
     * {
     *   formatDate: 'YYYY-MM-DD',
     *   formatDateTime: 'YYYY-MM-DD HH:mm',
     *
     *   theme: 'light',
     *
     *   // Time after which messages are removed
     *   removeSuccessAfter: 5000,
     *   removeWarningAfter: null,
     *   removeErrorAfter: null,
     *
     *   minimumResultsForSearch: 10
     * }
     * ```
     *
     * @memberof FormElementsService
     * @instance
     *
     * @type {function}
     * @param {object} defaults
     */
    setDefaults: function setDefaults(defaults) {
      this.set('defaults', Ember.assign({}, this.get('defaults'), defaults));
    }
  });

  _exports.default = _default;
});