define('ln-ember-data-models/models/myliga/comment', ['exports', 'ember-data', 'ember-data-model-fragments/attributes'], function (exports, _emberData, _attributes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr;
  exports.default = Model.extend({

    /**
     * @memberof Myliga/CommentModel
     * @instance
     *
     * @type {Number}
     */
    version: attr('number'),

    /**
     * @memberof Myliga/CommentModel
     * @instance
     *
     * @type {String}
     */
    message: attr('nullable-string'),

    /**
     * @memberof Myliga/CommentModel
     * @instance
     *
     * @type {Number}
     */
    context_id: attr('id'),

    /**
     * @memberof Myliga/CommentModel
     * @instance
     *
     * @type {String}
     */
    context_type: attr('nullable-string'),

    /**
     * @memberof Myliga/CommentModel
     * @instance
     *
     * @type {String}
     */
    date_created: attr('date-string'),

    /**
     * @memberof Myliga/CommentModel
     * @instance
     *
     * @type {String}
     */
    date_changed: attr('date-string'),

    /**
     * @memberof Myliga/CommentModel
     * @instance
     *
     * @type {Number}
     */
    author_id: attr('id'),

    /**
     * @memberof Myliga/CommentModel
     * @instance
     *
     * @type {String}
     */
    author_name: attr('nullable-string'),

    /**
     * @memberof Myliga/CommentModel
     * @instance
     *
     * @type {MyLiga/fragments/PictureMeta}
     */
    author_picture: (0, _attributes.fragment)('myliga/fragments/picture'),

    /**
     * @memberof Myliga/CommentModel
     * @instance
     *
     * @type {Boolean}
     */
    deletable: Ember.computed('author_id', '_hasDeleteRights', function () {
      var authorId = this.get('author_id');
      var currentUserId = this.get('apiEvents.session.user_id');

      if (authorId !== currentUserId) {
        return this.get('_hasDeleteRights');
      }

      return authorId === currentUserId;
    }),

    /**
     * @memberof Myliga/CommentModel
     * @instance
     *
     * @method
     */
    getDeleteRight: Ember.on('init', function () {
      var _this = this;

      this.get('rights').can('OTHER', 'pin.comment.delete').then(function (deletable) {
        _this.set('_hasDeleteRights', deletable);
      });
    }),

    rights: Ember.inject.service(),

    apiEvents: Ember.inject.service(),

    _hasDeleteRights: false
  });
});