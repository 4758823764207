define("ln-ember-form-elements/components/form-elements/toggle-button", ["exports", "ln-ember-form-elements/templates/components/form-elements/toggle-button"], function (_exports, _toggleButton) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   *
   * Renders a toggle button that is basically a checkbox.
   *
   * ### Example:
   *
   * ```Handlebars
   * {{form-elements/toggle-button checked=true}}
   * ```
   * @class formElements/ToggleButtonComponent
   * @extends @ember/component
   */
  var _default = Ember.Component.extend({
    layout: _toggleButton.default,

    /**
     * Contains the current state of the toggle button
     *
     * @memberof formElements/ToggleButtonComponent
     * @instance
     *
     * @type Boolean
     * @default false
     */
    checked: false,
    classNames: [// Deprecated: { id: ln-ember-form-elements-class-names, until: 3.0.0 }
    'toggle-button-component', 'form-elements--toggle-button-component'],
    checkboxId: Ember.computed(function () {
      return "".concat(Ember.guidFor(this), "-checkbox");
    }),
    actions: {
      toggleValue: function toggleValue(event) {
        this.set('value', event.target.checked);
      }
    }
  });

  _exports.default = _default;
});