define("ln-ember-toolkit/services/logger", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  var origOnError;
  /**
   * @class Logger
   * @extends Ember.Service
   */

  var _default = Ember.Service.extend({
    init: function init() {
      var _this = this;

      origOnError = Ember.onerror; // Set `Ember.onerror` handler, to handle all uncaught exceptions.

      Ember.onerror = function (error) {
        return _this.error(error);
      };

      this._super.apply(this, arguments);
    },
    destroy: function destroy() {
      Ember.onerror = origOnError;
    },

    /**
     * @memberof Logger
     * @instance
     *
     * @param {String|Object} message
     */
    error: function error(message) {
      if (_typeof(message) === 'object' && message.stack) {
        message = (message.stack || message.message).replace(/^Error: /, '');
      }

      if (!this.config.get('logger.quiet')) {
        console.error(message);
      }

      this.push({
        level: 'Error',
        message: message
      });
    },

    /**
     * @memberof Logger
     * @instance
     *
     * @param {String|Object} message
     */
    warn: function warn(message) {
      if (!this.config.get('logger.quiet')) {
        // eslint-disable-next-line no-console
        console.warn(message);
      }

      this.push({
        level: 'Warning',
        message: message
      });
    },

    /**
     * @memberof Logger
     * @instance
     *
     * @param {String|Object} message
     */
    log: function log(message) {
      if (!this.config.get('logger.quiet')) {
        // eslint-disable-next-line no-console
        console.log(message);
      }

      this.push({
        level: 'Log',
        message: message
      });
    },

    /**
     * @memberof Logger
     * @instance
     *
     * @private
     * @param {Object} data
     */
    push: function push(data) {
      var url = this.config.get('logger.url');

      if (url) {
        data.source = 'myliga';

        _jquery.default.ajax({
          type: 'POST',
          url: url,
          crossDomain: true,
          dataType: 'json',
          data: data
        });
      }
    }
  });

  _exports.default = _default;
});