define('ln-ember-data-models/models/vdc/contact', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr,
      Model = _emberData.default.Model;
  exports.default = Model.extend({

    /**
     * @memberof Vdc/Contact
     * @instance
     *
     * @type {Number}
     */
    version: attr('number'),

    /**
     * @memberof Vdc/Contact
     * @instance
     *
     * @type {Number}
     */
    client_id: attr('id'),

    /**
     * @memberof Vdc/Contact
     * @instance
     *
     * @type {String}
     */
    name: attr('nullable-string'),

    /**
     * @memberof Vdc/Contact
     * @instance
     *
     * @type {String}
     */
    email: attr('nullable-string'),

    /**
     * @memberof Vdc/Contact
     * @instance
     *
     * @type {String}
     */
    phone: attr('nullable-string'),

    /**
     * @memberof Vdc/Contact
     * @instance
     *
     * @type {String}
     */
    street1: attr('nullable-string'),

    /**
     * @memberof Vdc/Contact
     * @instance
     *
     * @type {String}
     */
    street2: attr('nullable-string'),

    /**
     * @memberof Vdc/Contact
     * @instance
     *
     * @type {String}
     */
    zip: attr('nullable-string'),

    /**
     * @memberof Vdc/Contact
     * @instance
     *
     * @type {String}
     */
    city: attr('nullable-string'),

    /**
     * @memberof Vdc/Contact
     * @instance
     *
     * @type {Number}
     */
    market_id: attr('id'),

    /**
     * @memberof Vdc/Contact
     * @instance
     *
     * @type {Number}
     */
    country_id: attr('id')

  });
});