define("ln-ember-toolkit/services/media-matcher", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var MOBILE_MEDIA_QUERY = 'only screen and (max-width: 736px)';

  var _default = Ember.Service.extend(Ember.Evented, {
    mediaVersion: null,
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.set('matcher', matchMedia(MOBILE_MEDIA_QUERY));
      this.updateMediaVersion();
      this.matcher.addListener(function () {
        _this.updateMediaVersion();

        _this.trigger('mediaDidChange', _this.mediaVersion);
      });
    },
    isMobile: Ember.computed.equal('mediaVersion', 'Mobile'),
    isDesktop: Ember.computed.equal('mediaVersion', 'Desktop'),
    updateMediaVersion: function updateMediaVersion() {
      this.set('mediaVersion', this.matcher.matches ? 'Mobile' : 'Desktop');
    }
  });

  _exports.default = _default;
});