define('ln-ember-contact-menu/components/clap-form', ['exports', 'ln-ember-contact-menu/templates/components/clap-form'], function (exports, _clapForm) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var PromiseObject = Ember.ObjectProxy.extend(Ember.PromiseProxyMixin);

  exports.default = Ember.Component.extend({

    store: Ember.inject.service(),

    api: Ember.inject.service(),

    layout: _clapForm.default,

    classNames: ['clap-form-component'],

    /**
     * Represent employee to be clapped
     *
     * @type Models/Myliga/Employee
     */
    employee: null,

    /**
     * Clap creation response
     *
     * @type Object
     */
    result: null,

    isDisabled: Ember.computed('text', function () {
      return Ember.isBlank(this.get('text'));
    }),

    userImages: Ember.computed('employee.picture.preview_url', 'leader.picture.preview_url', function () {
      var dummyImage = 'assets/images/user.svg';

      return {
        employee: this.get('employee.picture.preview_url') || dummyImage,
        leader: this.get('leader.picture.preview_url') || dummyImage
      };
    }),

    leader: Ember.computed('employee', function () {
      var userId = this.get('employee.id');

      return PromiseObject.create({
        promise: this._loadEmployee(userId)
      });
    }),

    _loadEmployee: function _loadEmployee(userId) {
      return this.get('api').read('myliga', ['users', { subordinate: userId }]).then(function (_ref) {
        var result = _ref.result;
        return result && result[0];
      });
    },


    actions: {
      sendClap: function sendClap(visibleForUser, visibleForSuperior) {
        var _this = this;

        var text = this.get('text');
        var user_id = this.get('employee.id');

        return this.get('api').create('myliga', 'hr/claps', {
          id: null,
          version: null,
          text: text,
          user_id: user_id,
          visible_for_superior: visibleForSuperior,
          visible_for_user: visibleForUser
        }).then(function (result) {
          _this.set('result', result);
        });
      }
    }
  });
});