define("ln-ember-form-elements/utils/computed-properties", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.defaultAction = defaultAction;
  _exports.resetOnChange = resetOnChange;
  _exports.assertValue = assertValue;

  function defaultAction(defaultHandler) {
    var bind = function bind(that, handler) {
      return Ember.isNone(handler) ? defaultHandler.bind(that) : handler;
    };

    return Ember.computed({
      get: function get() {
        return bind(this);
      },
      set: function set(key, handler) {
        return bind(this, handler);
      }
    });
  }

  function resetOnChange(dependentKey) {
    var defaultValue = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
    return Ember.computed(dependentKey, {
      get: function get() {
        return defaultValue;
      },
      set: function set(key, value) {
        return value;
      }
    });
  }

  function assertValue(defaultValue) {
    var assertFn = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : function () {};
    return Ember.computed({
      get: function get() {
        return defaultValue;
      },
      set: function set(key, value) {
        if (assertFn.call(this, value)) {
          return value;
        }

        return defaultValue;
      }
    });
  }
});