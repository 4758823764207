define('ln-ember-data-models/models/myliga/event', ['exports', 'ember-data', 'ember-data-model-fragments/attributes'], function (exports, _emberData, _attributes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      hasMany = _emberData.default.hasMany;
  exports.default = Model.extend({

    /**
     * @memberof Myliga/EventModel
     * @instance
     *
     * @type {Number}
     */
    version: attr('number'),

    /**
     * @memberof Myliga/EventModel
     * @instance
     *
     * @type {String}
     */
    date_end: attr('date-time-string'),

    /**
     * @memberof Myliga/EventModel
     * @instance
     *
     * @type {String}
     */
    date_start: attr('date-time-string'),

    /**
     * @memberof Myliga/EventModel
     * @instance
     *
     * @type {String}
     */
    description: attr('nullable-string'),

    /**
     * @memberof Myliga/EventModel
     * @instance
     *
     * @type {Number}
     */
    director_id: attr('id'),

    /**
     * @memberof Myliga/EventModel
     * @instance
     *
     * @type {String}
     */
    director_name: attr('nullable-string'),

    /**
     * @memberof Myliga/EventModel
     * @instance
     *
     * @type {String}
     */
    location: attr('nullable-string'),

    /**
     * @memberof Myliga/EventModel
     * @instance
     *
     * @type {Number}
     */
    max_participants: attr('number'),

    /**
     * @memberof Myliga/EventModel
     * @instance
     *
     * @type {String}
     */
    preview_url: attr('nullable-string'),

    /**
     * @memberof Myliga/EventModel
     * @instance
     *
     * @type {Boolean}
     */
    is_public: attr('boolean', { defaultValue: false }),

    /**
     * @memberof Myliga/EventModel
     * @instance
     *
     * @type {String}
     */
    speaker: attr('nullable-string'),

    /**
     * @memberof Myliga/EventModel
     * @instance
     *
     * @type {String}
     */
    title: attr('nullable-string'),

    /**
     * @memberof Myliga/EventModel
     * @instance
     *
     * @type {String}
     */
    url: attr('nullable-string'),

    /**
     * @memberof Myliga/EventModel
     * @instance
     *
     * @type {String}
     */
    registration_deadline: attr('nullable-string'),

    /**
     * @memberof Myliga/EventModel
     * @instance
     *
     * @type {EventParticipantModel[]}
     */
    participants: hasMany('myliga/event-participant'),

    /**
     * @memberof Myliga/EventModel
     * @instance
     *
     * @type {FileModel[]}
     */
    files: hasMany('myliga/file', { async: true }),

    /**
     * @memberof Myliga/EventModel
     * @instance
     *
     * @type {MyLiga/fragments/PictureMeta}
     */
    director_picture: (0, _attributes.fragment)('myliga/fragments/picture'),

    /**
     * @memberof Myliga/EventModel
     * @instance
     *
     * @type {EventParticipantModel}
     */
    currentUserParticipant: Ember.computed('participants.@each.isCurrentUser', function () {
      return this.get('participants').findBy('isCurrentUser');
    })
  });
});