define('ln-ember-data-models/transforms/nullable-string', ['exports', 'ember-data/transform'], function (exports, _transform) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _transform.default.extend({
    deserialize: function deserialize(serialized) {
      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      var deserialized = Ember.isEmpty(serialized) ? null : String(serialized);

      if (options.shouldTrim !== false) {
        deserialized = deserialized && deserialized.trim();
      }

      return deserialized;
    },
    serialize: function serialize(deserialized) {
      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      var serialized = Ember.isEmpty(deserialized) ? null : String(deserialized);

      if (options.shouldTrim !== false) {
        serialized = serialized && serialized.trim();
      }

      return serialized;
    }
  });
});