define("ln-ember-toolkit/services/resizer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function regexQuote(string) {
    return string.replace(/[-\\^$*+?.()|[\]{}/]/g, '\\$&');
  }
  /**
   * @class Resizer
   * @extends Ember.Service
   */


  var _default = Ember.Service.extend({
    configuration: Ember.inject.service(),

    /**
     *
     * Examples:
     *
     * ```javascript
     * ImageHelper.resize('http://example.org/image.png', 100, 100);
     * // => "http://resizer.example.org/resize/100x100/example.org/image.png"
     *
     * ImageHelper.resize('http://example.org/image.png', 100)
     * // => "http://resizer.example.org/resize/100x/example.org/image.png"
     *
     * ImageHelper.resize('http://example.org/image.png', null, 100)
     * // => "http://resizer.example.org/resize/x100/example.org/image.png"
     * ```
     *
     * @memberof Resizer
     * @instance
     *
     * @method resize
     * @param  {String}  url
     * @param  {Number}  width
     * @param  {Number} height
     * @return {string}
     */
    resize: function resize(url, width, height) {
      return this.getUrl('resize', url, width, height);
    },

    /**
     *
     * Examples:
     *
     * ```javascript
     * ImageHelper.shrink('http://example.org/image.png', 100, 100);
     * // => "http://resizer.example.org/shrink/100x100/example.org/image.png"
     * ```
     *
     * @memberof Resizer
     * @instance
     *
     * @method shrink
     * @param  {String}  url
     * @param  {Number}  width
     * @param  {Number} height
     * @return {String}
     */
    shrink: function shrink(url, width, height) {
      return this.getUrl('shrink', url, width, height);
    },

    /**
     *
     * Examples:
     *
     * ```javascript
     * ImageHelper.crop('http://example.org/image.png', 100, 100);
     * // => "http://resizer.example.org/crop/100x100/example.org/image.png"
     * ```
     *
     * @memberof Resizer
     * @instance
     *
     * @method crop
     * @param  {string}  url
     * @param  {number}  width
     * @param  {Number} height
     * @return {string}
     */
    crop: function crop(url, width, height) {
      return this.getUrl('crop', url, width, height);
    },

    /**
     *
     * Examples:
     *
     * ```javascript
     * ImageHelper.resizeFit('http://example.org/image.png', 100, 100);
     * // => "http://resizer.example.org/crop/100x100/example.org/image.png"
     * ```
     *
     * @memberof Resizer
     * @instance
     *
     * @method resizeFit
     * @param  {string}  url
     * @param  {Number}  width
     * @param  {Number} height
     * @return {string}
     */
    resizeFit: function resizeFit(url, width, height) {
      return this.getUrl('resizefit', url, width, height);
    },

    /**
     * @memberof Resizer
     * @instance
     *
     * @method getUrl
     * @param  {String}  mode
     * @param  {String}  url
     * @param  {Number}  width
     * @param  {Number} height
     * @return {String}
     */
    getUrl: function getUrl(mode, url, width, height) {
      if (Ember.typeOf(url) !== 'string') {
        url = Ember.get(url, 'preview_url');
      }

      var resizerUrl = this.configuration.getConfig('resizer.url');

      if (resizerUrl && this._isResizableUrl(url) && (width || height)) {
        if (!url.match(/^https?:\/\//)) {
          url = location.href.replace(/\/?#.*/, '/') + url;
        } // Screws up URLs for the screenshot service, is it needed?
        // url = url.replace(/\?.*$/, '');


        if (url.match(/\.svg$/)) {
          return url;
        }

        url = this.stripResizer(url);
        var size = "".concat(width || '', "x").concat(height || '');
        url = [resizerUrl, mode, size, url.replace(/^https?:\/\//, '')].join('/');
      }

      return url;
    },

    /**
     * @memberof Resizer
     * @instance
     *
     * @param  {String} url
     * @return {String}
     */
    stripResizer: function stripResizer(url) {
      var resizerUrl = this.configuration.getConfig('resizer.url');
      var isSSL = resizerUrl.match(/^https:/);
      var regex = new RegExp("^".concat(regexQuote(resizerUrl), "/(resize|crop)/(d+xd+|xd+|d+x)/"));

      if (regex.test(url)) {
        url = url.replace(regex, isSSL ? 'https://' : 'http://');
      }

      return url;
    },

    /**
     * @memberof Resizer
     * @instance
     *
     * @param  {String} url
     * @return {Ember.RSVP.Promise}
     */
    getImage: function getImage(url) {
      var _this = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        if (url) {
          var img = new Image();
          img.src = url;

          if (_this._isLoaded(img)) {
            resolve(img);
          } else {
            img.onload = function () {
              return resolve(img);
            };
          }
        } else {
          reject();
        }
      });
    },
    getDimentions: function getDimentions(url) {
      (false && !(false) && Ember.deprecate('[ResizerService] getDimentions(url) is deprecated use getDimensions(url) instead.', false, {
        id: 'resizer-service-get-dimentions',
        until: '3.0.0'
      }));
      return this.getDimensions(url);
    },

    /**
     * @memberof Resizer
     * @instance
     *
     * @param  {String} url
     * @return {Ember.RSVP.Promise}
     */
    getDimensions: function getDimensions(url) {
      var _this2 = this;

      return new Ember.RSVP.Promise(function (resolve
      /* , reject */
      ) {
        _this2.getImage(url).then(function (img) {
          resolve({
            width: img.width,
            height: img.height,
            ratio: img.width / img.height
          });
        });
      });
    },
    _isLoaded: function _isLoaded(_ref) {
      var complete = _ref.complete,
          naturalWidth = _ref.naturalWidth;

      if (!complete) {
        return false;
      }

      if (typeof naturalWidth !== 'undefined' && naturalWidth === 0) {
        return false;
      }

      return true;
    },
    _isResizableUrl: function _isResizableUrl(url) {
      url = url || '';
      return String(url).match(/^https?:\/\//) && !String(url).match(/^https?:\/\/(localhost|127\.0\.0\.1)/);
    }
  });

  _exports.default = _default;
});