define("ln-ember-guide-tooltips/templates/components/guide-tooltips", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UFxW6ZXl",
    "block": "{\"symbols\":[\"step\"],\"statements\":[[4,\"each\",[[24,[\"guideTooltips\",\"stepObjects\"]]],null,{\"statements\":[[4,\"tooltip-on-element\",null,[[\"target\",\"side\",\"tooltipIsVisible\",\"event\",\"class\"],[[23,1,[\"target\"]],[23,1,[\"side\"]],[23,1,[\"isCurrent\"]],\"none\",[28,\"concat\",[[23,1,[\"class\"]],\" \",[28,\"if\",[[23,1,[\"closeSide\"]],[28,\"concat\",[\"close-\",[23,1,[\"closeSide\"]]],null]],null]],null]]],{\"statements\":[[0,\"    \"],[1,[23,1,[\"content\",\"content\"]],true],[0,\"\\n\\n    \"],[7,\"button\",true],[11,\"class\",[29,[\"close \",[23,1,[\"closeSide\"]]]]],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"next\",[23,1,[]]],null]],[8],[0,\" \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ln-ember-guide-tooltips/templates/components/guide-tooltips.hbs"
    }
  });

  _exports.default = _default;
});