define('ln-ember-data-models/models/vdc/right-group', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr,
      hasMany = _emberData.default.hasMany,
      Model = _emberData.default.Model;
  exports.default = Model.extend({

    /**
     * @memberof Vdc/RightGroupModel
     * @instance
     *
     * @type {Number}
     */
    version: attr('number'),

    /**
     * @memberof Vdc/RightGroupModel
     * @instance
     *
     * @type {String}
     */
    name: attr('nullable-string'),

    /**
     * @memberof Vdc/RightGroupModel
     * @instance
     *
     * @type {String}
     */
    date_created: attr('date-time-string'),

    /**
     * @memberof Vdc/RightGroupModel
     * @instance
     *
     * @type {Number}
     */
    application_id: attr('id'),

    /**
     * @memberof Vdc/RightGroupModel
     * @instance
     *
     * @type {Vdc/RightGroupEntry[]}
     */
    entries: hasMany('vdc/right-group-entry')
  });
});