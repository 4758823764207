define('ln-ember-data-models/models/vdc/email-template', ['exports', 'ember-data', 'ln-ember-data-models/utils/enum-option'], function (exports, _emberData, _enumOption) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.typeOptions = undefined;
  var attr = _emberData.default.attr,
      Model = _emberData.default.Model;


  /**
   * @name Vdc/EmailTemplateModel/typeOptions
   * @type {EnumOption[]}
   */
  var typeOptions = exports.typeOptions = [_enumOption.default.create({
    id: 'PasswordExpiration',
    placeholders: ['{{forename}}', '{{name}}']
  }), _enumOption.default.create({
    id: 'StoreUpdateNotification',
    placeholders: ['{{forename}}', '{{name}}']
  }), _enumOption.default.create({
    id: 'TrainingInvitation',
    isTrainingOption: true,
    placeholders: ['{{forename}}', '{{name}}', '{{participantEmail}}', '{{acceptLink}}', '{{declineLink}}']
  }), _enumOption.default.create({
    id: 'AcceptedTrainingInvitation',
    isTrainingOption: true,
    placeholders: ['{{forename}}', '{{name}}', '{{participantEmail}}', '{{acceptLink}}', '{{declineLink}}']
  }), _enumOption.default.create({
    id: 'DeclinedTrainingInvitation',
    isTrainingOption: true,
    placeholders: ['{{forename}}', '{{name}}', '{{participantEmail}}', '{{acceptLink}}', '{{declineLink}}']
  }), _enumOption.default.create({
    id: 'AssemblyApprovedWithoutChanges',
    placeholders: ['{{basketUrl}}', '{{comment}}', '{{projectName}}']
  }), _enumOption.default.create({
    id: 'AssemblyApprovedWithChanges',
    placeholders: ['{{basketUrl}}', '{{comment}}', '{{projectName}}']
  }), _enumOption.default.create({
    id: 'AssemblyCanceled',
    placeholders: ['{{basketUrl}}', '{{comment}}', '{{projectName}}']
  }), _enumOption.default.create({
    id: 'ProjectOrderPhaseReminder',
    placeholders: ['{{basketUrl}}', '{{comment}}', '{{projectName}}']
  }), _enumOption.default.create({
    id: 'OrderNewState',
    placeholders: ['{{userName}}', '{{userForename}}', '{{projectName}}', '{{storeName}}', '{{orderNumber}}', '{{orderPoNumber}}', '{{> deliveryTable}}', '{{> commentsTable}}', '{{> orderTable}}']
  }), _enumOption.default.create({
    id: 'AssemblyEstimatedState',
    placeholders: ['{{approveUrl}}', '{{assemblyMasterUrl}}', '{{orderEndDate}}', '{{storeName}}', '{{> assemblyTable}}', '{{> approveLink name="approve"}}', '{{> assemblyMasterLink name="here"}}', '{{> comment}}']
  }), _enumOption.default.create({
    id: 'OrderReadyForPONumberState',
    name: 'Order Ready For PO Number State',
    placeholders: ['{{userName}}', '{{userForename}}', '{{projectName}}', '{{storeName}}', '{{orderNumber}}', '{{> poSubmissionLink name="here"}}', '{{> deliveryTable}}', '{{> commentsTable}}', '{{> orderTable}}']
  }), _enumOption.default.create({
    id: 'ArticleReviewCreation',
    placeholders: ['{{userLastName}}', '{{userForename}}', '{{articleName}}', '{{projectName}}', '{{countryName}}', '{{languageName}}', '{{languageCode}}', '{{> translationToolLink name="Translation Tool"}}']
  }), _enumOption.default.create({
    id: 'TranslationsReadyForArticleReview',
    placeholders: ['{{userLastName}}', '{{userForename}}', '{{articleName}}', '{{projectName}}', '{{countryName}}', '{{languageName}}', '{{languageCode}}', '{{> translationToolLink name="Translation Tool"}}']
  }), _enumOption.default.create({
    id: 'PasswordReset',
    placeholders: ['{{userForename}}', '{{userName}}', '{{appName}}', '{{userEmailAddress}}', '{{> supportEmail name="support"}}']
  })];

  /**
   * @class Vdc/EmailTemplateModel
   * @extends DS.Model
   */
  var EmailTemplateModel = Model.extend({

    /**
     * @memberof Vdc/EmailTemplateModel
     * @instance
     *
     * @type {Number}
     */
    version: attr('number'),

    /**
     * @memberof Vdc/EmailTemplateModel
     * @instance
     *
     * @type {String}
     */
    name: attr('nullable-string'),

    /**
     * @memberof Vdc/EmailTemplateModel
     * @instance
     *
     * @type {String}
     */
    body: attr('nullable-string'),

    /**
     * @memberof Vdc/EmailTemplateModel
     * @instance
     *
     * @type {String}
     */
    subject: attr('nullable-string'),

    /**
     * @memberof Vdc/EmailTemplateModel
     * @instance
     *
     * @type {String}
     */
    type: attr('enum-string', { options: typeOptions }),

    /**
     * @memberof Vdc/EmailTemplateModel
     * @instance
     *
     * @type {Number}
     */
    client_id: attr('id'),

    /**
     * @memberof Vdc/EmailTemplateModel
     * @instance
     *
     * @type {Number}
     */
    training_id: attr('id'),

    /**
     * @memberof Vdc/EmailTemplateModel
     * @instance
     *
     * @type {Number}
     */
    language_id: attr('id')
  });

  EmailTemplateModel.reopenClass({

    /**
     * @memberof Vdc/EmailTemplateModel
     * @static
     *
     * @type {EnumOption[]}
     */
    typeOptions: typeOptions

  });

  exports.default = EmailTemplateModel;
});