define('ln-ember-data-models/models/vdc/user', ['exports', 'ember-data', 'ember-data-model-fragments/attributes'], function (exports, _emberData, _attributes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      Model = _emberData.default.Model;
  exports.default = Model.extend({

    /**
     * @memberof Vdc/UserModel
     * @instance
     *
     * @type {Number}
     */
    version: attr('number'),

    /**
     * @memberof Vdc/UserModel
     * @instance
     *
     * @type {Boolean}
     */
    active: attr('boolean', { defaultValue: function defaultValue() {
        return true;
      } }),

    /**
     * @memberof Vdc/UserModel
     * @instance
     *
     * @type {String}
     */
    forename: attr('nullable-string'),

    /**
     * @memberof Vdc/UserModel
     * @instance
     *
     * @type {String}
     */
    name: attr('nullable-string'),

    /**
     * @memberof Vdc/UserModel
     * @instance
     *
     * @type {String}
     */
    email: attr('nullable-string'),

    /**
     * @memberof Vdc/UserModel
     * @instance
     *
     * @type {String}
     */
    position: attr('nullable-string'),

    /**
     * @memberof Vdc/UserModel
     * @instance
     *
     * @type {String}
     */
    expert_qualification: attr('nullable-string'),

    /**
     * @memberof Vdc/UserModel
     * @instance
     *
     * @type {Number}
     */
    client_id: attr('id'),

    /**
     * @memberof Vdc/UserModel
     * @instance
     *
     * @type {Array}
     */
    client_roles: attr(),

    /**
     * @memberof Vdc/UserModel
     * @instance
     *
     * @type {Number}
     */
    picture_id: attr('id'),

    /**
     * @memberof Vdc/UserModel
     * @instance
     *
     * @type {Number}
     */
    address_id: attr('id'),

    /**
     * @memberof Vdc/UserModel
     * @instance
     *
     * @type {Object}
     */
    address: attr(),

    /**
     * @memberof Vdc/UserModel
     * @instance
     *
     * @type {String}
     */
    client_name: attr('nullable-string'),

    /**
     * @memberof Vdc/UserModel
     * @instance
     *
     * @type {String}
     */
    phone: attr('nullable-string'),

    /**
     * @memberof Vdc/UserModel
     * @instance
     *
     * @type {String}
     */
    phone_mobile: attr('nullable-string'),

    /**
     * @memberof Vdc/UserModel
     * @instance
     *
     * @type {String}
     */
    language_id: attr('id'),

    /**
     * @memberof Vdc/UserModel
     * @instance
     *
     * @type {Number}
     */
    user_type_id: attr('id'),

    /**
     * @memberof Vdc/UserModel
     * @instance
     *
     * @type {String}
     */
    user_type_name: attr('nullable-string'),

    /**
     * @memberof Vdc/UserModel
     * @instance
     *
     * @type {Number}
     */
    tegroup_fid: attr('id'),

    /**
     * @memberof Vdc/UserModel
     * @instance
     *
     * @type {Number}
     */
    username: attr('nullable-string'),

    /**
     * @memberof Vdc/UserModel
     * @instance
     *
     * @type {String}
     */
    entry_date: attr('date-string', { timeOption: 'start' }),

    /**
     * @memberof Vdc/UserModel
     * @instance
     *
     * @type {String}
     */
    exit_date: attr('date-string', { timeOption: 'end' }),

    /**
     * @memberof Vdc/UserModel
     * @instance
     *
     * @type {Array}
     */
    store_market_names: attr({ defaultValue: function defaultValue() {
        return [];
      } }, { readOnly: true }),

    /**
     * @memberof Vdc/UserModel
     * @instance
     *
     * @type {Array}
     */
    store_names: attr({ defaultValue: function defaultValue() {
        return [];
      } }, { readOnly: true }),

    /**
     * @memberof Vdc/UserModel
     * @instance
     *
     * @type {Array}
     */
    training_status_logs: attr({ defaultValue: function defaultValue() {
        return [];
      } }, { readOnly: true }),

    /**
     * @memberof Vdc/UserModel
     * @instance
     *
     * @type {String}
     */
    last_coaching_training_date: attr('date-string', { readOnly: true }),

    /**
     * @memberof Vdc/UserModel
     * @instance
     *
     * @type {String}
     */
    last_expert_training_date: attr('date-string', { readOnly: true }),

    /**
     * @memberof Vdc/UserModel
     * @instance
     *
     * @type {String}
     */
    last_kickoff_training_date: attr('date-string', { readOnly: true }),

    /**
     * @memberof Vdc/UserModel
     * @instance
     *
     * @type {String}
     */
    last_training_date: attr('date-string', { readOnly: true }),

    /**
     * @memberof Vdc/UserModel
     * @instance
     *
     * @type {Number}
     */
    training_intensity: attr('number', { readOnly: true }),

    /**
     * @memberof Vdc/UserModel
     * @instance
     *
     * @type {Number}
     */
    training_time: attr('number', { readOnly: true }), // eslint-disable-line ligadigital/ember-model-custom-types

    /**
     * @memberof Vdc/UserModel
     * @instance
     *
     * @type {Number}
     */
    brand_training: attr('number', { readOnly: true }),

    /**
     * @memberof Vdc/UserModel
     * @instance
     *
     * @type {Number}
     */
    product_training: attr('number', { readOnly: true }),

    /**
     * @memberof Vdc/UserModel
     * @instance
     *
     * @type {Number}
     */
    customer_training: attr('number', { readOnly: true }),

    /**
     * @memberof Vdc/UserModel
     * @instance
     *
     * @type {Vdc/PictureModel}
     */
    picture: belongsTo('vdc/picture'),

    /**
     * @memberof Vdc/UserModel
     * @instance
     *
     * @type {Vdc/AttributeSetModel}
     */
    attribute_set: belongsTo('vdc/attribute-set'),

    /**
     * @memberof Vdc/UserModel
     * @instance
     *
     * @type {Vdc/fragments/AttributeValueFragment[]}
     */
    attribute_values: (0, _attributes.fragmentArray)('vdc/fragments/attribute-value'),

    /**
     * @memberof Vdc/UserModel
     * @instance
     *
     * @type {String}
     * @readonly
     */
    fullName: Ember.computed('name', 'forename', function () {
      var name = this.get('name');
      var forename = this.get('forename');

      return forename + ' ' + name;
    }).readOnly()
  });
});