define('ln-ember-contact-menu/components/micro-menu', ['exports', 'ln-ember-contact-menu/templates/components/micro-menu'], function (exports, _microMenu) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var MM_MOBILE_SPACE_ALLOWED = 250;
  var MM_DESKTOP_SPACE_ALLOWED = 310;

  exports.default = Ember.Component.extend({
    microMenu: Ember.inject.service(),

    mediaMatcher: Ember.inject.service(),

    layout: _microMenu.default,

    classNames: ['micro-menu-component'],

    classNameBindings: ['microMenu.hasActiveItem:has-active'],

    component: Ember.computed.alias('microMenu.component'),

    targetElementId: Ember.computed('microMenu.elementId', function () {
      return '#' + this.get('microMenu.elementId');
    }),

    isNorthern: Ember.computed('targetElementId', function () {
      var targetElement = document.querySelector(this.get('targetElementId'));
      var isMobile = this.get('mediaMatcher.isMobile');

      if (isMobile) {
        return hasEnoughSpaceBelow(targetElement, MM_MOBILE_SPACE_ALLOWED);
      } else {
        return hasEnoughSpaceBelow(targetElement, MM_DESKTOP_SPACE_ALLOWED);
      }
    }),

    tetherConstraints: Ember.computed(function () {
      return [{
        to: 'window',
        attachement: 'together',
        pin: true
      }];
    }),

    verticalAttachement: Ember.computed('isNorthern', function () {
      return this.get('isNorthern') ? 'top' : 'bottom';
    }),

    horizontalAttachement: 'middle',

    attachement: Ember.computed('verticalAttachement', function () {
      return this.get('verticalAttachement') + ' ' + this.get('horizontalAttachement');
    }),

    targetAttachement: Ember.computed('verticalAttachement', function () {
      var vertical = this.get('verticalAttachement') === 'top' ? 'bottom' : 'top';

      return vertical + ' ' + this.get('horizontalAttachement');
    }),

    didRender: function didRender() {
      var _this = this;

      if (this.get('microMenu.isActive')) {
        Ember.run.scheduleOnce('render', function () {
          _this.element.querySelector('.micro-menu--container').classList.add('is-rendered');
          if (_this.get('tetherComponent')) {
            _this.get('tetherComponent').positionTether();
          }
        });
      }
    },
    click: function click() {
      this._close();
    },
    _close: function _close() {
      this.get('microMenu').close();
    },


    actions: {
      register: function register(tetherComponent) {
        this.set('tetherComponent', tetherComponent);
      },
      closeMicroMenu: function closeMicroMenu() {
        this._close();

        return true;
      },
      didActivateItem: function didActivateItem() {
        this.get('microMenu').set('hasActiveItem', true);
      }
    }
  });


  /**
   * Checks if there is enough space below the element (at least 310px on desktop and 250px on mobile)
   *
   * @param {HTMLElement} element
   * @param {number} spaceAllowedBelow
   * @return {boolean}
   */
  function hasEnoughSpaceBelow(element, spaceAllowedBelow) {
    var elementRect = element.getBoundingClientRect();
    var spaceBelowElement = window.innerHeight - elementRect.bottom;

    return spaceBelowElement > spaceAllowedBelow;
  }
});