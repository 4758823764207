define('ln-ember-data-models/models/vdc/article-review', ['exports', 'ember-data', 'ln-ember-data-models/utils/enum-option'], function (exports, _emberData, _enumOption) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.statusOptions = undefined;
  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      Model = _emberData.default.Model,
      hasMany = _emberData.default.hasMany;


  /**
   * @name Vdc/ArticleReviewModel/statusOptions
   * @type {EnumOption[]}
   */
  var statusOptions = exports.statusOptions = [_enumOption.default.create({ id: 'New' }), _enumOption.default.create({ id: 'Draft' }), _enumOption.default.create({ id: 'ReadyForReview' }), _enumOption.default.create({ id: 'AdaptWithComments' }), _enumOption.default.create({ id: 'Approved' }), _enumOption.default.create({ id: 'Canceled' }), _enumOption.default.create({ id: 'Empty' }), _enumOption.default.create({ id: 'DoNotReviewNow' })];

  /**
   * @class Vdc/ArticleReviewModel
   * @extends DS.Model
   */
  var ArticleReviewModel = Model.extend({
    /**
     * @memberof Vdc/ArticleReviewModel
     * @instance
     *
     * @type {Number}
     */
    version: attr('number'),

    /**
     * @memberof Vdc/ArticleReviewModel
     * @instance
     *
     * @type {Number}
     */
    project_id: attr('id'),

    /**
     * @memberof Vdc/ArticleReviewModel
     * @instance
     *
     * @type {Number}
     */
    country_id: attr('id'),

    /**
     * @memberof Vdc/ArticleReviewModel
     * @instance
     *
     * @type {Number}
     */
    language_id: attr('id'),

    /**
     * @memberof Vdc/ArticleReviewModel
     * @instance
     *
     * @type {Number}
     */
    article_id: attr('id'),

    /**
     * @memberof Vdc/ArticleReviewModel
     * @instance
     *
     * @type {Number}
     */
    picture_id: attr('id'),

    /**
     * @memberof Vdc/ArticleReviewModel
     * @instance
     *
     * @type {Boolean}
     */
    approved_by_market: attr('boolean', { defaultValue: function defaultValue() {
        return false;
      } }),

    /**
     * @memberof Vdc/ArticleReviewModel
     * @instance
     *
     * @type {Boolean}
     */
    approved_by_hq: attr('boolean', { defaultValue: function defaultValue() {
        return false;
      } }),

    /**
     * @memberof Vdc/ArticleReviewModel
     * @instance
     *
     * @type {Boolean}
     */
    active: attr('boolean', { defaultValue: function defaultValue() {
        return true;
      } }),

    /**
     * @memberof Vdc/ArticleReviewModel
     * @instance
     *
     * @type {Boolean}
     */
    outdated: attr('boolean', { defaultValue: function defaultValue() {
        return false;
      } }),

    /**
     * @memberof Vdc/ArticleReviewModel
     * @instance
     *
     * @type {String}
     */
    approval_date: attr('date-string'),

    /**
     * @memberof Vdc/ArticleReviewModel
     * @instance
     *
     * @type {String}
     */
    status: attr('enum-string', { options: statusOptions }),

    /**
     * @memberof Vdc/ArticleReviewModel
     * @instance
     *
     * @type {Vdc/TextModuleTranslation[]}
     */
    translations: hasMany('vdc/text-module-translation', { readOnly: true, async: false }),

    /**
     * @memberof Vdc/ArticleReviewModel
     * @instance
     *
     * @type {Vdc/Comment[]}
     */
    comments: hasMany('vdc/comment', { readOnly: true, async: false }),

    /**
     * @memberof Vdc/ArticleReviewModel
     * @instance
     *
     * @type {Vdc/Language}
     */
    language: belongsTo('vdc/language', { readOnly: true, async: false }),

    /**
     * @memberof Vdc/ArticleReviewModel
     * @instance
     *
     * @type {Vdc/Article}
     */
    article: belongsTo('vdc/article', { readOnly: true, async: false }),

    /**
     * @memberof Vdc/ArticleReviewModel
     * @instance
     *
     * @type {Vdc/Revision}
     */
    preview_picture: belongsTo('vdc/revision', { readOnly: true, async: false }),

    /**
     * @memberof Vdc/ArticleReviewModel
     * @instance
     *
     * @type {Vdc/Revision[]}
     */
    pictures: hasMany('vdc/revision', { readOnly: true, async: false }),

    /**
     * @memberof Vdc/ArticleReviewModel
     * @instance
     *
     * @type {Vdc/Article}
     */
    country: belongsTo('vdc/country', { readOnly: true, async: false })

  });

  ArticleReviewModel.reopenClass({
    /**
     * @memberof Vdc/ArticleReviewModel
     * @static
     *
     * @type {EnumOption[]}
     */
    statusOptions: statusOptions

  });

  exports.default = ArticleReviewModel;
});