define('ln-ember-data-models/models/vdc/article-price', ['exports', 'ember-data', 'ember-data-model-fragments/attributes'], function (exports, _emberData, _attributes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr,
      Model = _emberData.default.Model;
  exports.default = Model.extend({

    /**
     * @memberof Vdc/ArticlePriceModel
     * @instance
     *
     * @type {Number}
     */
    version: attr('number'),

    /**
     * @memberof Vdc/ArticlePriceModel
     * @instance
     *
     * @type {Number}
     */
    project_id: attr('id'),

    /**
     * @memberof Vdc/ArticlePriceModel
     * @instance
     *
     * @type {Number}
     */
    article_id: attr('id'),

    /**
     * @memberof Vdc/ArticlePriceModel
     * @instance
     *
     * @type {String}
     */
    calculation_type: attr('nullable-string'),

    /**
     * @memberof Vdc/ArticlePriceModel
     * @instance
     *
     * @type {Number}
     */
    margin_percent: attr('number'),

    /**
     * @memberof Vdc/ArticlePriceModel
     * @instance
     *
     * @type {Number}
     */
    currency_id: attr('id'),

    /**
     * @memberof Vdc/ArticlePriceModel
     * @instance
     *
     * @type {Number}
     */
    sales_price: attr('number'),

    /**
     * @memberof Vdc/ArticlePriceModel
     * @instance
     *
     * @type {Number}
     */
    purchase_costs: attr('number'),

    /**
     * @memberof Vdc/ArticlePriceModel
     * @instance
     *
     * @type {Number}
     */
    sales_price_per_unit: attr('number'),

    /**
     * @memberof Vdc/ArticlePriceModel
     * @instance
     *
     * @type {Number}
     */
    purchase_costs_per_unit: attr('number'),

    /**
     * @memberof Vdc/ArticlePriceModel
     * @instance
     *
     * @type {Number}
     */
    vendor_id: attr('id'),

    /**
     * @memberof Vdc/ArticlePriceModel
     * @instance
     *
     * @type {Boolean}
     */
    estimated: attr('boolean', { defaultValue: false }),

    /**
     * @memberof Vdc/ArticlePriceModel
     * @instance
     *
     * @type {Vdc/fragments/AttributeValueFragment[]}
     */
    attribute_values: (0, _attributes.fragmentArray)('vdc/fragments/attribute-value'),

    /**
     * @memberof Vdc/ArticlePriceModel
     * @instance
     *
     * @type {Boolean}
     */
    isManual: Ember.computed.equal('calculation_type', 'Manual'),

    /**
     * @memberof Vdc/ArticlePriceModel
     * @instance
     *
     * @type {Boolean}
     */
    isSum: Ember.computed.equal('calculation_type', 'Sum'),

    /**
     * @memberof Vdc/ArticlePriceModel
     * @instance
     *
     * @type {Boolean}
     */
    isUnit: Ember.computed.equal('calculation_type', 'Unit')
  });
});