define('ln-ember-data-models/adapters/vdc/contextual-role-assignment', ['exports', 'ln-ember-data-models/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    urlForDeleteRecord: function urlForDeleteRecord(id, modelName, snapshot) {
      return this.buildDeleteUrl(modelName, id, snapshot);
    },
    buildDeleteUrl: function buildDeleteUrl(modelName, id, snapshot) {
      if (modelName) {

        var path = this.pathForType(modelName);

        if (path) {
          var role = snapshot.record.get('role_id');
          var context = snapshot.record.get('context_id');
          var context_type = snapshot.record.get('role_type');
          var client = snapshot.record.get('client_id');

          path += '?role=' + role + '&context=' + context + '&context_type=' + context_type + '&client=' + client;

          return this.buildURLFor(path, null, modelName, null);
        }
      }
    }
  });
});