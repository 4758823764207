define('ln-ember-data-models/models/vdc/window', ['exports', 'ember-data', 'ember-data-model-fragments/attributes'], function (exports, _emberData, _attributes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany,
      Model = _emberData.default.Model;
  exports.default = Model.extend({

    /**
     * @memberof Vdc/WindowModel
     * @instance
     *
     * @type {Number}
     */
    version: attr('number'),

    /**
     * @memberof Vdc/WindowModel
     * @instance
     *
     * @type {String}
     */
    name: attr('nullable-string'),

    /**
     * @memberof Vdc/WindowModel
     * @instance
     *
     * @type {String}
     */
    number: attr('nullable-string'),

    /**
     * @memberof Vdc/WindowModel
     * @instance
     *
     * @type {Number}
     */
    store_id: attr('id'),

    /**
     * @memberof Vdc/WindowModel
     * @instance
     *
     * @type {String}
     */
    store_name: attr('nullable-string'),

    /**
     * @memberof Vdc/WindowModel
     * @instance
     *
     * @type {String}
     */
    store_number: attr('nullable-string'),

    /**
     * @memberof Vdc/WindowModel
     * @instance
     *
     * @type {String}
     */
    country_name: attr('nullable-string'),

    /**
     * @memberof Vdc/WindowModel
     * @instance
     *
     * @type {Number}
     */
    country_id: attr('id'),

    /**
     * @memberof Vdc/WindowModel
     * @instance
     *
     * @type {Number}
     */
    facade_id: attr('id'),

    /**
     * @memberof Vdc/WindowModel
     * @instance
     *
     * @type {Number}
     */
    facade_picture_id: attr('id'),

    /**
     * @memberof Vdc/WindowModel
     * @instance
     *
     * @type {Vdc/AttributeSetModel}
     */
    attribute_set: belongsTo('vdc/attribute-set'),

    /**
     * @memberof Vdc/WindowModel
     * @instance
     *
     * @type {Vdc/PictureModel[]}
     */
    pictures: hasMany('vdc/picture'),

    /**
     * @memberof Vdc/WindowModel
     * @instance
     *
     * @type {Vdc/FixtureModel[]}
     */
    fixtures: hasMany('vdc/fixture'),

    /**
     * @memberof Vdc/WindowModel
     * @instance
     *
     * @type {Vdc/AssemblyModel}
     */
    assembly: belongsTo('vdc/assembly'),

    /**
     * @memberof Vdc/WindowModel
     * @instance
     *
     * @type {Vdc/fragments/AttributeValueFragment[]}
     */
    attribute_values: (0, _attributes.fragmentArray)('vdc/fragments/attribute-value'),

    /**
     * @memberof Vdc/WindowModel
     * @instance
     *
     * @type {Vdc/fragments/ObjectAreaFragment}
     */
    facade_area: (0, _attributes.fragment)('vdc/fragments/object-area')
  });
});