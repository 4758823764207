define("ln-ember-toolkit/utils/media-matcher", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class MediaMatcher
   */
  var _default = Ember.Object.extend({
    /**
     * @memberof MediaMatcher
     * @instance
     *
     * @method
     * @param {String} mediaQuery
     * @return {Boolean}
     */
    query: function query(mediaQuery) {
      return window.matchMedia(mediaQuery).matches;
    },

    /**
     * @memberof MediaMatcher
     * @instance
     *
     * @method
     * @return {Boolean}
     */
    isSmall: function isSmall() {
      return this.query('only screen and (max-width: 736px)');
    },

    /**
     * @memberof MediaMatcher
     * @instance
     *
     * @method
     * @return {String}
     */
    getVersion: function getVersion() {
      return this.isSmall() ? 'Mobile' : 'Desktop';
    }
  }).create();

  _exports.default = _default;
});