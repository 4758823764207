define("ln-ember-form-elements/templates/components/form-elements/dropdown-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YzTdlWCP",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[28,\"hash\",null,[[\"button\",\"dropdown\"],[[28,\"component\",[\"form-elements/dropdown-button/button\"],[[\"toggleDropdown\",\"isOpen\",\"loading\"],[[28,\"action\",[[23,0,[]],\"toggleDropdown\"],null],[24,[\"dropdownOpen\"]],[24,[\"loading\"]]]]],[28,\"component\",[\"form-elements/dropdown-button/dropdown\"],[[\"isOpen\"],[[24,[\"dropdownOpen\"]]]]]]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ln-ember-form-elements/templates/components/form-elements/dropdown-button.hbs"
    }
  });

  _exports.default = _default;
});