define("ln-ember-form-elements/utils/enforce-array", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = enforceArray;

  /**
   * Unify the value from the select as an array for less checks on the value
   *
   * @param {any} value
   * @returns {array} cleanValues as array with no null or undefined
   **/
  function enforceArray(value) {
    if (Ember.isNone(value)) {
      return [];
    }

    return Ember.isArray(value) ? Ember.A(value).compact() : [value];
  }
});