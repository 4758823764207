define('ln-ember-data-models/models/vdc/application-setting', ['exports', 'ember-data', 'ln-ember-data-models/utils/enum-option'], function (exports, _emberData, _enumOption) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.flowOptions = undefined;
  var attr = _emberData.default.attr,
      Model = _emberData.default.Model;


  /**
   * @memberof Vdc/ApplicationSettingModel/flowOptions
   * @type {EnumOption[]}
   */
  var flowOptions = exports.flowOptions = [_enumOption.default.create({ id: 'Assembly' }), _enumOption.default.create({ id: 'Order' })];

  /**
   * @class Vdc/ApplicationSettingModel
   * @extends DS.Model
   */
  var ApplicationSettingsModel = Model.extend({

    /**
     * @memberof Vdc/ApplicationSettingModel
     * @instance
     *
     * @type {Number}
     */
    version: attr('number'),

    /**
     * @memberof Vdc/ApplicationSettingModel
     * @instance
     *
     * @type {Number}
     */
    application_id: attr('id'),

    /**
     * @memberof Vdc/ApplicationSettingModel
     * @instance
     *
     * @type {String}
     */
    context_type: attr('nullable-string'),

    /**
     * @memberof Vdc/ApplicationSettingModel
     * @instance
     *
     * @type {Number}
     */
    context_id: attr('id'),

    /**
     * @memberof Vdc/ApplicationSettingModel
     * @instance
     *
     * @type {Number[]}
     */
    context_attribute_ids: attr('number-array'),

    /**
     * @memberof Vdc/ApplicationSettingModel
     * @instance
     *
     * @type {Boolean}
     */
    show_review: attr('boolean', { defaultValue: false }),

    /**
     * @memberof Vdc/ApplicationSettingModel
     * @instance
     *
     * @type {String}
     */
    shop_flow: attr('enum-string', { options: flowOptions, defaultValue: 'Order' })

  });

  exports.default = ApplicationSettingsModel.reopenClass({

    /**
     * @memberof Vdc/ApplicationSettingModel
     * @static
     *
     * @type {EnumOption[]}
     */
    flowOptions: flowOptions

  });
});