define('ln-ember-data-models/models/myliga/pin', ['exports', 'ember-data', 'ember-data-model-fragments/attributes', 'ln-ember-data-models/utils/enum-option'], function (exports, _emberData, _attributes, _enumOption) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.blockTypeOptions = exports.blockSizeOptions = undefined;
  var attr = _emberData.default.attr,
      hasMany = _emberData.default.hasMany,
      Model = _emberData.default.Model;


  /**
   * @name Myliga/PinModel/blockSizeOptions
   * @type {EnumOption[]}
   */
  var blockSizeOptions = exports.blockSizeOptions = [_enumOption.default.create({ id: 'Small' }), _enumOption.default.create({ id: 'Medium' }), _enumOption.default.create({ id: 'Large' })];

  /**
   * @name Myliga/PinModel/blockTypeOptions
   * @type {EnumOption[]}
   */
  var blockTypeOptions = exports.blockTypeOptions = [_enumOption.default.create({ id: 'PinText', name: Ember.String.loc('Text') }), _enumOption.default.create({ id: 'PinImage', name: Ember.String.loc('Image'), needsRight: true }), _enumOption.default.create({ id: 'PinVideo', name: Ember.String.loc('Video') }), _enumOption.default.create({ id: 'PinEvent', name: Ember.String.loc('Event'), needsRight: true }), _enumOption.default.create({ id: 'PinLink', name: Ember.String.loc('Link') }), _enumOption.default.create({ id: 'PinFile', name: Ember.String.loc('File'), needsRight: true }), _enumOption.default.create({ id: 'PinMultimedia', name: Ember.String.loc('Media'), needsRight: true })];

  /**
   * @class Myliga/PinModel
   * @extends DS.Model
   */
  var PinModel = Model.extend({

    /**
     * @memberof Myliga/PinModel
     * @instance
     *
     * @type {Number}
     */
    version: attr('number'),

    /**
     * @memberof Myliga/PinModel
     * @instance
     *
     * @type {Number}
     */
    author_id: attr('id', { defaultValue: null }),

    /**
     * @memberof Myliga/PinModel
     * @instance
     *
     * @type {String}
     */
    author_name: attr('nullable-string'),

    /**
     * @memberof Myliga/PinModel
     * @instance
     *
     * @type {Number}
     */
    block_category_id: attr('id'),

    /**
     * @memberof Myliga/PinModel
     * @instance
     *
     * @type {Number}
     */
    block_id: attr('id'),

    /**
     * @memberof Myliga/PinModel
     * @instance
     *
     * @type {String}
     */
    block_size: attr('nullable-string', { defaultValue: 'Large' }),

    /**
     * @memberof Myliga/PinModel
     * @instance
     *
     * @type {String}
     */
    block_type: attr('nullable-string'),

    /**
     * @memberof Myliga/PinModel
     * @instance
     *
     * @type {Boolean}
     */
    comments_allowed: attr('boolean', { defaultValue: true }),

    /**
     * @memberof Myliga/PinModel
     * @instance
     *
     * @TYPe {Number}
     */
    comment_count: attr('number', { defaultValue: 0 }),

    /**
     * @memberof Myliga/PinModel
     * @instance
     *
     * @type {String}
     */
    date_changed: attr('date-time-string'),

    /**
     * @memberof Myliga/PinModel
     * @instance
     *
     * @type {String}
     */
    date_created: attr('date-time-string'),

    /**
     * @memberof Myliga/PinModel
     * @instance
     *
     * @type {Number}
     */
    event_id: attr('id'),

    /**
     * @memberof Myliga/PinModel
     * @instance
     *
     * @type {Number}
     */
    like_count: attr('number'),

    /**
     * @memberof Myliga/PinModel
     * @instance
     *
     * @type {Boolean}
     */
    liked: attr('boolean'),

    /**
     * @memberof Myliga/PinModel
     * @instance
     *
     * @type {String}
     */
    posting_date: attr('date-string'),

    /**
     * @memberof Myliga/PinModel
     * @instance
     *
     * @type {String}
     */
    preview_url: attr('nullable-string'),

    /**
     * @memberof Myliga/PinModel
     * @instance
     *
     * @type {String}
     */
    text: attr('nullable-string'),

    /**
     * @memberof Myliga/PinModel
     * @instance
     *
     * @type {String}
     */
    title: attr('nullable-string'),

    /**
     * @memberof Myliga/PinModel
     * @instance
     *
     * @type {String}
     */
    url: attr('nullable-string'),

    /**
     * @memberof Myliga/PinModel
     * @instance
     *
     * @type {CommentModel[]}
     */
    comments: hasMany('myliga/comment', { async: true, contextType: 'Pin' }),

    /**
     * @memberof Myliga/PinModel
     * @instance
     *
     * @type {LikeModel[]}
     */
    likes: hasMany('myliga/likes', { async: true }),

    /**
     * @memberof Myliga/PinModel
     * @instance
     *
     * @type {FileModel[]}
     */
    files: hasMany('myliga/file', { async: true }),

    /**
     * @memberof Myliga/PinModel
     * @instance
     *
     * @type {MyLiga/fragments/PictureMeta}
     */
    author_picture: (0, _attributes.fragment)('myliga/fragments/picture'),

    /**
     * @memberof Myliga/PinModel
     * @instance
     *
     * @type {Boolean}
     */
    isTextPin: Ember.computed.equal('block_type', 'PinText'),

    /**
     * @memberof Myliga/PinModel
     * @instance
     *
     * @type {Boolean}
     */
    isImagePin: Ember.computed.equal('block_type', 'PinImage'),

    /**
     * @memberof Myliga/PinModel
     * @instance
     *
     * @type {Boolean}
     */
    isFilePin: Ember.computed.equal('block_type', 'PinFile'),

    /**
     * @memberof Myliga/PinModel
     * @instance
     *
     * @type {Boolean}
     */
    isMultimediaPin: Ember.computed.equal('block_type', 'PinMultimedia'),

    /**
     * @memberof Myliga/PinModel
     * @instance
     *
     * @type {Boolean}
     */
    isVideoPin: Ember.computed.equal('block_type', 'PinVideo'),

    /**
     * @memberof Myliga/PinModel
     * @instance
     *
     * @type {Boolean}
     */
    isLinkPin: Ember.computed.equal('block_type', 'PinLink'),

    /**
     * @memberof Myliga/PinModel
     * @instance
     *
     * @type {Boolean}
     */
    isEventPin: Ember.computed.equal('block_type', 'PinEvent'),

    /**
     * @memberof Myliga/PinModel
     * @instance
     *
     * @type {Boolean}
     */
    wasEdited: Ember.computed('date_created', 'date_changed', function () {
      return this.get('date_created') !== this.get('date_changed');
    })
  });

  PinModel.reopenClass({

    /**
     * @memberof Myliga/PinModel
     * @static
     *
     * @type {EnumOption[]}
     */
    blockSizeOptions: blockSizeOptions,

    /**
     * @memberof Myliga/PinModel
     * @static
     *
     * @type {EnumOption[]}
     */
    blockTypeOptions: blockTypeOptions

  });

  exports.default = PinModel;
});