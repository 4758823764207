define("ln-ember-form-elements/components/form-elements/date-time-range-input", ["exports", "moment", "ln-ember-form-elements/components/form-elements/date-range-input-base", "ln-ember-form-elements/templates/components/form-elements/date-time-range-input", "ln-ember-form-elements/utils/date-helper"], function (_exports, _moment, _dateRangeInputBase, _dateTimeRangeInput, _dateHelper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  /**
   *
   * ### Value
   *
   * The value is a object holding `start` and `end` in the iso format or `null`.
   *
   * ### Examples
   *
   * #### Basic usage:
   *
   * For the basic usage the only required attributes are the current `value` and
   * handler for `onChange` events.
   *
   * ```Handlebars
   * {{form-elements/date-time-range-input
   *   value=dateValue
   *   onChange=(action "onDateChange")
   * }}
   * ```
   *
   * ```JavaScript
   * Component.extend({
   *   actions: {
   *     onDateChange(value) {
   *       this.set('dateValue', value)
   *     }
   *   }
   * })
   * ```
   *
   * ### Demo
   *
   * <iframe src="showcase/index.html#/demo/date-time-range-input" style="width: 360px;border: 1px solid black;height:450px;padding: 20px;"></iframe>
   *
   * @class formElements/DateTimeRangeInputComponent
   * @extends formElements/DateInputRangeBaseComponent */
  var _default = _dateRangeInputBase.default.extend({
    /**
     * @memberof formElements/DateInputRangeBaseComponent
     * @instance
     *
     * @type {object}
     * @property {string} start -  ISO date format
     * @property {string} end -  ISO date format
     * @default null
     */
    value: null,
    classNames: ['form-elements--date-time-range-input-component'],
    layout: _dateTimeRangeInput.default,
    timeValue: Ember.computed('value.{start,end}', {
      get: function get() {
        var _ref = this.get('value') || {},
            start = _ref.start,
            end = _ref.end;

        return {
          start: (0, _dateHelper.formatDate)(start, 'HH:mm'),
          end: (0, _dateHelper.formatDate)(end, 'HH:mm')
        };
      },
      set: function set(key, value) {
        return value;
      }
    }),
    formatDate: function formatDate(date) {
      return (0, _dateHelper.formatDate)(date, this.get('formElements.defaults.formatDateTime'));
    },
    getVisibleDate: function getVisibleDate() {
      return (0, _moment.default)(this.get('value.start') || undefined, _moment.default.ISO_8601).format('YYYY-MM-DD');
    },
    isValidInput: function isValidInput(value) {
      var _ref2 = value || {},
          start = _ref2.start,
          end = _ref2.end;

      return (0, _moment.default)(start, _moment.default.ISO_8601, true).isValid() && (Ember.isNone(end) || (0, _moment.default)(end, _moment.default.ISO_8601, true).isValid());
    },
    isValid: function isValid(value) {
      var _ref3 = value || {},
          start = _ref3.start,
          end = _ref3.end;

      return (0, _moment.default)(start, _moment.default.ISO_8601, true).isValid() && (0, _moment.default)(end, _moment.default.ISO_8601, true).isValid();
    },
    triggerChange: function triggerChange(range) {
      var _ref4 = range || {},
          start = _ref4.start,
          end = _ref4.end;

      this.get('onChange')(range && {
        start: start && (0, _moment.default)(start).toISOString(),
        end: end && (0, _moment.default)(end).toISOString()
      });
    },
    actions: {
      onCalendarChange: function onCalendarChange(value) {
        var _ref5 = this.get('value') || {},
            start = _ref5.start,
            end = _ref5.end;

        var startTime = start ? (0, _moment.default)(start).format('HH:mm') : '00:00';
        var endTime = end ? (0, _moment.default)(end).format('HH:mm') : '00:00';

        if (start && end && start !== end || !start) {
          start = value;
          end = value;
          endTime = startTime;
        } else {
          end = value;
        }

        start = this.applyTime(start, startTime);
        end = this.applyTime(end, endTime);

        var _sort = [start, end].sort(function (a, b) {
          return (0, _moment.default)(a).isAfter(b) ? 1 : -1;
        });

        var _sort2 = _slicedToArray(_sort, 2);

        start = _sort2[0];
        end = _sort2[1];
        this.triggerChange({
          start: start,
          end: end
        });
      },
      onInputChange: function onInputChange(type, value) {
        if (!value) {
          return this.triggerChange(null);
        }

        var dateFormat = this.get('formElements.defaults.formatDateTime');
        var date = (0, _moment.default)("".concat(value, " +0200"), "".concat(dateFormat, " ZZ"), true);

        if (!date.isValid()) {
          return;
        }

        var range = Object.assign({}, this.get('range'), _defineProperty({}, type, date.toISOString()));

        if (this.isValid(range)) {
          this.triggerChange(range);
        }
      },
      onTimeInputChange: function onTimeInputChange(type, time) {
        if (!/^\d\d:\d\d$/.test(time)) {
          return;
        }

        var range = Object.assign({}, this.get('range'), _defineProperty({}, type, this.applyTime(this.get("range.".concat(type)), time)));

        if (this.isValid(range)) {
          this.triggerChange(range);
        }
      }
    }
  });

  _exports.default = _default;
});