define('ln-ember-data-models/models/vdc/training-subtype', ['exports', 'ember-data', 'ln-ember-data-models/utils/enum-option'], function (exports, _emberData, _enumOption) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.classificationOptions = exports.categoryOptions = undefined;
  var attr = _emberData.default.attr,
      Model = _emberData.default.Model;


  /**
   * @name Vdc/TrainingSubtypeModel/categoryOptions
   * @type {EnumOption[]}
   */
  var categoryOptions = exports.categoryOptions = [_enumOption.default.create({ id: 'HQ' }), _enumOption.default.create({ id: 'MPC' }), _enumOption.default.create({ id: 'Other' })];

  /**
   * @name Vdc/TrainingSubtypeModel/classificationOptions
   * @type {EnumOption[]}
   */
  var classificationOptions = exports.classificationOptions = [_enumOption.default.create({ id: 'CET' }), _enumOption.default.create({ id: 'PET' }), _enumOption.default.create({ id: 'BET' })];

  /**
   * @class Vdc/TrainingSubtypeModel
   * @extends DS.Model
   */
  var TrainingSubtypeModel = Model.extend({

    /**
     * @memberof Vdc/TrainingSubtypeModel
     * @instance
     *
     * @type {Number}
     */
    version: attr('number'),

    /**
     * @memberof Vdc/TrainingSubtypeModel
     * @instance
     *
     * @type {String}
     */
    name: attr('nullable-string'),

    /**
     * @memberof Vdc/TrainingSubtypeModel
     * @instance
     *
     * @type {Number}
     */
    position: attr('Number'),

    /**
     * @memberof Vdc/TrainingSubtypeModel
     * @instance
     *
     * @type {Number}
     */
    client_id: attr('id'),

    /**
     * @memberof Vdc/TrainingSubtypeModel
     * @instance
     *
     * @type {String}
     */
    category: attr('enum-string', {
      options: categoryOptions,
      defaultValue: 'HQ'
    }),

    /**
     * @memberof Vdc/TrainingSubtypeModel
     * @instance
     *
     * @type {String}
     */
    classification: attr('enum-string', { options: classificationOptions }),

    /**
     * @memberof Vdc/TrainingSubtypeModel
     * @instance
     *
     * @type {Boolean}
     */
    mandatory_start_end_date: attr('boolean', { defaultValue: function defaultValue() {
        return false;
      } }), // eslint-disable-line ligadigital/ember-model-custom-types

    /**
     * @memberof Vdc/TrainingSubtypeModel
     * @instance
     *
     * @type {Boolean}
     */
    show_in_calendar: attr('boolean', { defaultValue: function defaultValue() {
        return false;
      } })
  });

  TrainingSubtypeModel.reopenClass({

    /**
     * @memberof Vdc/TrainingSubtypeModel
     * @static
     *
     * @type {EnumOption[]}
     */
    categoryOptions: categoryOptions,

    /**
     * @memberof Vdc/TrainingSubtypeModel
     * @static
     *
     * @type {EnumOption[]}
     */
    classificationOptions: classificationOptions
  });

  exports.default = TrainingSubtypeModel;
});