define("ln-ember-contact-menu/templates/components/fake-contact-menu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EhENRisR",
    "block": "{\"symbols\":[],\"statements\":[[7,\"ul\",true],[8],[0,\"\\n  \"],[1,[28,\"micro-menu-item\",null,[[\"isFake\"],[true]]],false],[0,\"\\n\\n  \"],[1,[28,\"micro-menu-item\",null,[[\"isFake\"],[true]]],false],[0,\"\\n\\n  \"],[7,\"ul\",true],[10,\"class\",\"micro-menu--group\"],[8],[0,\"\\n    \"],[1,[28,\"micro-menu-item\",null,[[\"isFake\",\"description\"],[true,true]]],false],[0,\"\\n\\n    \"],[1,[28,\"micro-menu-item\",null,[[\"isFake\",\"description\",\"hasIcon\"],[true,true,false]]],false],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[1,[28,\"micro-menu-item\",null,[[\"isFake\",\"description\"],[true,true]]],false],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ln-ember-contact-menu/templates/components/fake-contact-menu.hbs"
    }
  });

  _exports.default = _default;
});