define('ln-ember-api-service/services/api-events', ['exports', 'ln-ember-api-service/utils/api-error'], function (exports, _apiError) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend(Ember.Evented, {

    /**
     * @memberof ApiEvents
     * @instance
     *
     * @private
     * @property {Object} config
     */
    config: null,

    /**
     * @memberof ApiEvents
     * @instance
     *
     * @private
     * @property {Object} session
     */
    session: null,

    /**
     * @memberof ApiEvents
     * @instance
     *
     * @private
     * @property {String} language
     */
    language: null,

    /**
     * @memberof ApiEvents
     * @instance
     *
     * @method triggerErrorResponse
     * @param  {Object} response
     */
    triggerErrorResponse: function triggerErrorResponse(response) {
      var status = response.status,
          status_code = response.status_code,
          message = response.message;

      var error = new _apiError.default(message, status || status_code || 500);

      this.trigger('error-response', error);
    },


    /**
     * @memberof ApiEvents
     * @instance
     *
     * @method triggerConfigChange
     * @param  {Object} config
     */
    triggerConfigChange: function triggerConfigChange(config) {

      this.set('config', config);
      this.trigger('config-change', config);
    },


    /**
     * @memberof ApiEvents
     * @instance
     *
     * @method triggerSessionChange
     * @param  {Object} session
     */
    triggerSessionChange: function triggerSessionChange(session) {

      this.set('session', session);
      this.trigger('session-change', session);
    },


    /**
     * @memberof ApiEvents
     * @instance
     *
     * @method triggerLanguageChange
     * @param  {String} language
     */
    triggerLanguageChange: function triggerLanguageChange(language) {

      this.set('language', language);
      this.trigger('language-change', language);
    },


    /**
     * @memberof ApiEvents
     * @instance
     *
     * @method getConfig
     * @returns  {Object} config
     */
    getConfig: function getConfig() {
      return this.get('config') || {};
    },


    /**
     * @memberof ApiEvents
     * @instance
     *
     * @method getSession
     * @returns  {Object} session
     */
    getSession: function getSession() {
      return this.get('session') || null;
    },


    /**
     * @memberof ApiEvents
     * @instance
     *
     * @method getLanguage
     * @returns  {String} language
     */
    getLanguage: function getLanguage() {
      return this.get('language') || 'en';
    }
  });
});