define('ln-ember-data-models/adapters/myliga/objective-component', ['exports', 'ln-ember-data-models/adapters/abstract/myliga/sub-resource'], function (exports, _subResource) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _subResource.default.extend({
    parentResource: 'objective',
    resourcePath: 'component'
  });
});