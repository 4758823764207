define('ln-ember-data-models/models/myliga/block', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr;
  exports.default = Model.extend({
    /**
     * @memberof Myliga/BlockModel
     * @instance
     *
     * @type {String}
     */
    title: attr('nullable-string'),
    /**
     * @memberof Myliga/BlockModel
     * @instance
     *
     * @type {String}
     */
    type: attr('nullable-string'),
    /**
     * @memberof Myliga/BlockModel
     * @instance
     *
     * @type {String}
     */
    size: attr('nullable-string'),
    /**
     * @memberof Myliga/BlockModel
     * @instance
     *
     * @type {Object}
     */
    content: attr(),
    /**
     * @memberof Myliga/BlockModel
     * @instance
     *
     * @type {String}
     */
    author_name: attr('nullable-string'),
    /**
     * @memberof Myliga/BlockModel
     * @instance
     *
     * @type {String}
     */
    created_at: attr('date')
  });
});