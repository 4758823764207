define('ln-ember-data-models/models/myliga/fragments/user-meta', ['exports', 'ember-data', 'ember-data-model-fragments/fragment', 'ember-data-model-fragments/attributes'], function (exports, _emberData, _fragment, _attributes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr;
  exports.default = _fragment.default.extend({

    /**
     * 💩 This is NOT the users `username`, but its full name: `<lastname>, <forename>`.
     *
     * It should not be used at all user `fullName`!
     *
     * @memberof Myliga/fragments/UserMetaFragment
     * @instance
     *
     * @type {string}
     */
    username: attr('nullable-string'),

    /**
     * @memberof Myliga/fragments/UserMetaFragment
     * @instance
     *
     * @type {string}
     */
    name: attr('nullable-string'),

    /**
     * @memberof Myliga/fragments/UserMetaFragment
     * @instance
     *
     * @type {string}
     */
    forename: attr('nullable-string'),

    /**
     * @memberof Myliga/fragments/UserMetaFragment
     * @instance
     *
     * @type {string}
     */
    position: attr('nullable-string'),

    /**
     * @memberof Myliga/fragments/UserMetaFragment
     * @instance
     *
     * @type {string}
     */
    picture: (0, _attributes.fragment)('myliga/fragments/picture'),

    /**
     * Computed property: `<name>, <forename>`
     *
     * @memberof Myliga/fragments/UserMetaFragment
     * @instance
     *
     * @type {string}
     */
    fullName: Ember.computed('name', 'forename', function () {
      return this.get('name') + ', ' + this.get('forename');
    }).readOnly()
  });
});