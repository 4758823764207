define("ln-ember-form-elements/templates/components/flash-messages/flash-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cts7QLNp",
    "block": "{\"symbols\":[],\"statements\":[[7,\"p\",true],[8],[1,[24,[\"message\",\"text\"]],false],[9],[0,\"\\n\\n\"],[4,\"form-elements/styled-button\",null,[[\"class\",\"icon\",\"click\"],[\"close-message\",\"close\",[28,\"action\",[[23,0,[]],\"removeMessage\"],null]]],{\"statements\":[[0,\"  \"],[1,[28,\"svg-icon\",[\"exit\"],[[\"class\"],[\"Button-icon\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ln-ember-form-elements/templates/components/flash-messages/flash-message.hbs"
    }
  });

  _exports.default = _default;
});