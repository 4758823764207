define('ln-ember-data-models/utils/enum-option', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Object.extend({
    name: Ember.computed('id', '_name', {
      get: function get() {
        var name = String(this.get('id') || '').replace(/([a-z])([A-Z])/g, '$1 $2');
        return Ember.String.loc(name);
      },
      set: function set(key, value) {
        this.set('_name', value);
        return value;
      }
    }),

    dasherizedId: Ember.computed('name', function () {
      return String(this.get('id')).dasherize();
    }),

    id: null,

    _name: null
  });
});