define('ln-ember-data-models/models/vdc/event', ['exports', 'ember-data', 'ember-data-model-fragments/attributes', 'ln-ember-data-models/utils/enum-option'], function (exports, _emberData, _attributes, _enumOption) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.statusOptions = undefined;
  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany,
      Model = _emberData.default.Model;


  /**
   * @memberof Vdc/EventModel/statusOptions
   * @type {EnumOption[]}
   */
  var statusOptions = exports.statusOptions = [_enumOption.default.create({ id: 'NotStarted' }), _enumOption.default.create({ id: 'Ongoing' }), _enumOption.default.create({ id: 'Completed' }), _enumOption.default.create({ id: 'OnHold' }), _enumOption.default.create({ id: 'Canceled' })];

  /**
   * @class Vdc/EventModel
   * @extends DS.Model
   */
  var EventModel = Model.extend({

    /**
     * @memberof Vdc/EventModel
     * @instance
     *
     * @type {Number}
     */
    version: attr('number'),

    /**
     * @memberof Vdc/EventModel
     * @instance
     *
     * @type {String}
     */
    name: attr('nullable-string'),

    /**
     * @memberof Vdc/EventModel
     * @instance
     *
     * @type {Number}
     */
    client_id: attr('id'),

    /**
     * @memberof Vdc/EventModel
     * @instance
     *
     * @type {Number}
     */
    plan_id: attr('id'),

    /**
     * @memberof Vdc/EventModel
     * @instance
     *
     * @type {Number}
     */
    event_type_id: attr('id'),

    /**
     * @memberof Vdc/EventModel
     * @instance
     *
     * @type {String}
     */
    event_type_name: attr('nullable-string'),

    /**
     * @memberof Vdc/EventModel
     * @instance
     *
     * @type {String}
     */
    start_date: attr('date-string'),

    /**
     * @memberof Vdc/EventModel
     * @instance
     *
     * @type {String}
     */
    end_date: attr('date-string'),

    /**
     * @memberof Vdc/EventModel
     * @instance
     *
     * @type {String}
     */
    status: attr('enum-string', { options: statusOptions }),

    /**
     * @memberof Vdc/EventModel
     * @instance
     *
     * @type {Number[]}
     */
    market_ids: attr('number-array', { defaultValue: function defaultValue() {
        return [];
      } }),

    /**
     * @memberof Vdc/EventModel
     * @instance
     *
     * @type {Number}
     */
    markets_count: attr('number'),

    /**
     * @memberof Vdc/EventModel
     * @instance
     *
     * @type {Number[]}
     */
    country_ids: attr('number-array', { defaultValue: function defaultValue() {
        return [];
      } }),

    /**
     * @memberof Vdc/EventModel
     * @instance
     *
     * @type {Number}
     */
    countries_count: attr('number'),

    /**
     * @memberof Vdc/EventModel
     * @instance
     *
     * @type {Array}
     */
    store_ids: attr('number-array', { defaultValue: function defaultValue() {
        return [];
      } }),

    /**
     * @memberof Vdc/EventModel
     * @instance
     *
     * @type {Number}
     */
    stores_count: attr('number'),

    /**
     * @memberof Vdc/EventModel
     * @instance
     *
     * @type {Number}
     */
    service_agreement_attachment: attr('id'),

    /**
     * @memberof Vdc/EventModel
     * @instance
     *
     * @type {Number}
     */
    planned_total_guests: attr('number'),

    /**
     * @memberof Vdc/EventModel
     * @instance
     *
     * @type {Number}
     */
    planned_leads: attr('number'),

    /**
     * @memberof Vdc/EventModel
     * @instance
     *
     * @type {Number}
     */
    planned_client_guests: attr('number'),

    /**
     * @memberof Vdc/EventModel
     * @instance
     *
     * @type {Number}
     */
    planned_conversion_rate: attr('number'),

    /**
     * @memberof Vdc/EventModel
     * @instance
     *
     * @type {Number}
     */
    planned_mb_guests: attr('number'),

    /**
     * @memberof Vdc/EventModel
     * @instance
     *
     * @type {Number}
     */
    planned_new_customer_guests: attr('number'),

    /**
     * @memberof Vdc/EventModel
     * @instance
     *
     * @type {Number}
     */
    planned_total_costs: attr('number'),

    /**
     * @memberof Vdc/EventModel
     * @instance
     *
     * @type {Number}
     */
    planned_participant_costs: attr('number'),

    /**
     * @memberof Vdc/EventModel
     * @instance
     *
     * @type {Number}
     */
    planned_sale_costs: attr('number'),

    /**
     * @memberof Vdc/EventModel
     * @instance
     *
     * @type {Number}
     */
    planned_sales_target: attr('number'),

    /**
     * @memberof Vdc/EventModel
     * @instance
     *
     * @type {Number}
     */
    planned_test_drives: attr('number'),

    /**
     * @memberof Vdc/EventModel
     * @instance
     *
     * @type {Number}
     */
    actual_total_guests: attr('number'),

    /**
     * @memberof Vdc/EventModel
     * @instance
     *
     * @type {Number}
     */
    actual_client_guests: attr('number'),

    /**
     * @memberof Vdc/EventModel
     * @instance
     *
     * @type {Number}
     */
    actual_conversion_rate: attr('number'),

    /**
     * @memberof Vdc/EventModel
     * @instance
     *
     * @type {Number}
     */
    actual_mb_guests: attr('number'),

    /**
     * @memberof Vdc/EventModel
     * @instance
     *
     * @type {Number}
     */
    actual_new_customer_guests: attr('number'),

    /**
     * @memberof Vdc/EventModel
     * @instance
     *
     * @type {Number}
     */
    actual_total_costs: attr('number'),

    /**
     * @memberof Vdc/EventModel
     * @instance
     *
     * @type {Number}
     */
    actual_leads: attr('number'),

    /**
     * @memberof Vdc/EventModel
     * @instance
     *
     * @type {Number}
     */
    actual_participant_costs: attr('number'),

    /**
     * @memberof Vdc/EventModel
     * @instance
     *
     * @type {Number}
     */
    actual_sale_costs: attr('number'),

    /**
     * @memberof Vdc/EventModel
     * @instance
     *
     * @type {Number}
     */
    actual_sales_target: attr('number'),

    /**
     * @memberof Vdc/EventModel
     * @instance
     *
     * @type {Number}
     */
    actual_test_drives: attr('number'),

    /**
     * @memberof Vdc/EventModel
     * @instance
     *
     * @type {Number}
     */
    event_category_id: attr('id'),

    /**
     * @memberof Vdc/EventModel
     * @instance
     *
     * @type {String}
     */
    event_category_name: attr('nullable-string'),

    /**
     * @memberof Vdc/EventModel
     * @instance
     *
     * @type {Vdc/AttributeSetModel}
     */
    event_type_attribute_set: belongsTo('vdc/attribute-set'),

    /**
     * @memberof Vdc/EventModel
     * @instance
     *
     * @type {Vdc/AttributeSetModel}
     */
    event_category_attribute_set: belongsTo('vdc/attribute-set'),

    /**
     * @memberof Vdc/EventModel
     * @instance
     *
     * @type {Vdc/Picture[]}
     */
    pictures: hasMany('vdc/picture', { async: false }),

    /**
     * @memberof Vdc/EventModel
     * @instance
     *
     * @type {Vdc/Market[]}
     */
    markets: hasMany('vdc/market', { async: true }),

    /**
     * @memberof Vdc/EventModel
     * @instance
     *
     * @type {Vdc/Store[]}
     */
    stores: hasMany('vdc/store', { async: true }),

    /**
     * @memberof Vdc/EventModel
     * @instance
     *
     * @type {Vdc/PictureModel}
     */
    preview_picture: belongsTo('vdc/picture'),

    /**
     * @memberof Vdc/EventModel
     * @instance
     *
     * @type {Vdc/fragments/AttributeValueFragment[]}
     */
    event_type_attribute_values: (0, _attributes.fragmentArray)('vdc/fragments/attribute-value'),

    /**
     * @memberof Vdc/EventModel
     * @instance
     *
     * @type {Vdc/fragments/AttributeValueFragment[]}
     */
    event_category_attribute_values: (0, _attributes.fragmentArray)('vdc/fragments/attribute-value'),

    /**
     * @memberof Vdc/EventModel
     * @instance
     *
     * @type {Boolean}
     */
    isStarted: Ember.computed('status', function () {
      return ['Ongoing', 'Completed', 'OnHold'].includes(this.get('status'));
    })

  });

  EventModel.reopenClass({

    /**
     * @memberof Vdc/EventModel
     * @static
     *
     * @type {EnumOption[]}
     */
    statusOptions: statusOptions

  });

  exports.default = EventModel;
});