define('ln-ember-data-models/transforms/enum-string', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Transform = _emberData.default.Transform;


  function validateEnum(value, options) {
    if (Ember.isNone(value)) {
      return null;
    }

    var values = Ember.isArray(options) ? options.map(function (option) {
      return typeof option === 'string' ? option : option.id;
    }) : Object.values(options);
    if (!values.includes(value)) {
      console.error('[EnumStringTransform] ' + value + ' not in [' + values.join(', ') + ']');
      return null;
    }

    return value;
  }

  exports.default = Transform.extend({
    deserialize: function deserialize(serialized) {
      return serialized;
    },
    serialize: function serialize(deserialized, _ref) {
      var options = _ref.options;

      return validateEnum(deserialized, options);
    }
  });
});