define('ln-ember-data-models/models/vdc/training-type', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr,
      hasMany = _emberData.default.hasMany,
      Model = _emberData.default.Model;
  exports.default = Model.extend({

    /**
     * @memberof Vdc/TrainingTypeModel
     * @instance
     *
     * @type {Number}
     */
    version: attr('number'),

    /**
     * @memberof Vdc/TrainingTypeModel
     * @instance
     *
     * @type {String}
     */
    name: attr('nullable-string'),

    /**
     @memberof Vdc/TrainingTypeModel
     * @instance
     *
     * @type {Number}
     */
    position: attr('Number'),

    /**
     * @memberof Vdc/TrainingTypeModel
     * @instance
     *
     * @type {Number}
     */
    client_id: attr('id'),

    /**
     * @memberof Vdc/TrainingTypeModel
     * @instance
     *
     * @type {Number}
     */
    attribute_set_id: attr('id'),

    /**
     * @memberof Vdc/TrainingTypeModel
     * @instance
     *
     * @type {Array}
     */
    attribute_ids: attr('number-array', { defaultValue: function defaultValue() {
        return [];
      } }),

    /**
     * @memberof Vdc/TrainingTypeModel
     * @instance
     *
     * @type {Boolean}
     */
    static_and_dynamic_modes_active: attr('boolean', { defaultValue: function defaultValue() {
        return true;
      } }),

    /**
     * @memberof Vdc/TrainingTypeModel
     * @instance
     *
     * @type {Array}
     */
    training_subtype_ids: attr('number-array', { defaultValue: function defaultValue() {
        return [];
      } }),

    /**
     * @memberof Vdc/TrainingTypeModel
     * @instance
     *
     * @type {Boolean}
     */
    has_max_participants: attr('boolean', { defaultValue: function defaultValue() {
        return false;
      } }),

    /**
     * @memberof Vdc/TrainingTypeModel
     * @instance
     *
     * @type {Vdc/TrainingSubtypeModel[]}
     */

    training_subtypes: hasMany('vdc/training-subtype'),

    /**
     * @memberof Vdc/TrainingTypeModel
     * @instance
     *
     * @type {Vdc/PictureModel[]}
     */
    pictures: hasMany('vdc/picture')
  });
});