define('ln-ember-data-models/models/vdc/fragments/attribute-value', ['exports', 'ember-data', 'ember-data-model-fragments/fragment'], function (exports, _emberData, _fragment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr;
  exports.default = _fragment.default.extend({

    attribute_id: attr('id'),

    value: attr()

  });
});